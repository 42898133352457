import React from "react";
import { FieldArray, Formik } from "formik/dist";
import { useAppSelector } from "../../../redux/hooks/hooks";
import { fieldNames } from "../../requestaquote/components/application-information";
import { setDirectorData } from "./review-business-information";
import {
  directorFieldNames,
  formatNumberInput,
  numberRegex,
  removeComma,
  residentialStatusList,
} from "../../Constants";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MaskedInput from "react-maskedinput";

export default function DirectorInfo({
  values,
  errors,
  setFieldValue,
  handleChange,
  initialDirectorObject,
  touched,
  item,
  index,
  stateList,
  stateListLoading,
}) {
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  const Accordion = ({ title, children, isPrimary, id }) => {
    const [isOpen, setOpen] = React.useState(false);

    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title  ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          style={{ justifyContent: "flex-start" }}
        >
          <div className="px-2"> {title}</div>
          {isPrimary && (
            <button
              className="btn btn-success btn-sm mr-2"
              style={{ backgroundColor: "#198754", pointerEvents: "none" }}
              type="button"
            >
              Primary
            </button>
          )}
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content" id={id}>
            {children}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="director-field">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="checkbox"
              className="primary-checkbox"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ISPRIMARY}`}
              onChange={(e) => {
                values.directorInfo.forEach((item, i) => {
                  if (index == i) {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ISPRIMARY}`,
                      e.target.checked
                    );
                  } else {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${i}.${directorFieldNames.ISPRIMARY}`,
                      false
                    );
                  }
                });
              }}
              checked={item[directorFieldNames.ISPRIMARY]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            />
            <label className="set-primary">Set as Primary</label>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.FIRSTNAME}`}
              onChange={handleChange}
              value={item[directorFieldNames.FIRSTNAME]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            />
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LASTNAME}`}
              onChange={handleChange}
              value={item[directorFieldNames.LASTNAME]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            />
          </div>
        </div>
        {currentCountry !== "GB" && (
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>Job Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Job Title"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.JOBTITLE}`}
                onChange={handleChange}
                value={item[directorFieldNames.JOBTITLE]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
        )}

        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>Nature Of Control</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nature Of Control"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.NATUREOFCONTROL}`}
              onChange={handleChange}
              value={item[directorFieldNames.NATUREOFCONTROL]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            />
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>% of Total Share Count</label>
            <input
              type="text"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`}
              onChange={(e) => {
                if (e.target.value) {
                  if (numberRegex.test(removeComma(e.target.value))) {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`,
                      e.target.value
                    );
                  } else {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`,
                      item[directorFieldNames.KINDOFSHAREHOLDER]
                    );
                  }
                } else {
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`,
                    ""
                  );
                }
              }}
              value={formatNumberInput(
                item[directorFieldNames.KINDOFSHAREHOLDER]
              )}
              className="form-control"
              placeholder="% of Total Share Count"
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            />
          </div>
        </div>

        <input
          type="text"
          name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE2}`}
          onChange={handleChange}
          hidden
          value={item[directorFieldNames.ADDRESSLINE2]}
          className="form-control"
          placeholder="% of Total Share Count"
          onBlur={() => {
            setDirectorData(values["directorInfo"]);
          }}
        />
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Email Address"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.EMAIL}`}
              onChange={handleChange}
              value={item[directorFieldNames.EMAIL]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            />
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>Phone</label>

            <PhoneInput
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PHONENUMBER}`}
              country={currentCountry.toLowerCase()}
              value={item[directorFieldNames.PHONENUMBER]}
              inputStyle={
                touched[directorFieldNames.PHONENUMBER] &&
                errors[directorFieldNames.PHONENUMBER] && {
                  borderColor: "red",
                }
              }
              onChange={(phone) => {
                setFieldValue(
                  `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PHONENUMBER}`,
                  phone
                );
              }}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
              inputClass={"w-100"}
              containerClass={`${index}-phone-input`}
              placeholder="Enter Phone Number"
            />
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
          <div className="form-group">
            <label>Date of Birth</label>
            <DatePicker
              selected={
                moment(
                  item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT]
                ).isValid()
                  ? new Date(item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT])
                  : null
              }
              customInput={
                <MaskedInput
                  mask="11/11/1111"
                  placeholder={
                    currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                  }
                />
              }
              className="form-control"
              dateFormat={currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
              placeholderText={
                currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
              }
              onCalendarClose={() => {
                setDirectorData(values["directorInfo"]);
              }}
              onChange={(value) => {
                if (value !== null) {
                  let fieldValue = moment(value).isValid()
                    ? new Date(value)
                    : null;
                  // this.setState({ value: fieldValue });
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.SHAREHOLDERDOBFULLFORMAT}`,
                    fieldValue
                  );
                }
                // setFieldValue(
                //   `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.SHAREHOLDERDOBFULLFORMAT}`,
                //   date !== null ? moment(date).utc().format("YYYY-MM-DD") : null
                // );
              }}
            />
            {/* <input
              type="date"
              className="form-control"
              placeholder="04/11/2022"
              max={moment().format("YYYY-MM-DD")}
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.SHAREHOLDERDOBFULLFORMAT}`}
              onChange={handleChange}
              value={item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            /> */}
          </div>
        </div>
        {currentCountry == "GB" && (
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>Post Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Post Code"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.POSTALCODE}`}
                onChange={handleChange}
                value={item[directorFieldNames.POSTALCODE]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
        )}

        <div
          className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12"
          hidden
        >
          <div className="form-group">
            <label>Choose Address</label>

            <select
              className="form-select form-control"
              aria-label="Default select example"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.CHOOSEADDRESS}`}
              onChange={handleChange}
              value={item[directorFieldNames.CHOOSEADDRESS]}
              onBlur={() => {
                setDirectorData(values["directorInfo"]);
              }}
            >
              <option selected disabled>
                Choose Address
              </option>
            </select>
          </div>
        </div>
      </div>
      {currentCountry === "GB" ? (
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>House Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="House Number"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.HOUSE_NUMBER}`}
                onChange={handleChange}
                value={item[directorFieldNames.HOUSE_NUMBER]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>House Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="House Name"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.HOUSE_NAME}`}
                onChange={handleChange}
                value={item[directorFieldNames.HOUSE_NAME]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Street</label>
              <input
                type="text"
                className="form-control"
                placeholder="Street"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.STREET}`}
                onChange={handleChange}
                value={item[directorFieldNames.STREET]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>County</label>
              <input
                type="text"
                className="form-control"
                placeholder="County"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.COUNTY}`}
                onChange={handleChange}
                value={item[directorFieldNames.COUNTY]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Town</label>
              <input
                type="text"
                className="form-control"
                placeholder="Town"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.TOWN}`}
                onChange={handleChange}
                value={item[directorFieldNames.TOWN]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Residential Status</label>
              <Select
                // menuIsOpen={true}
                closeMenuOnSelect={true}
                onChange={(selectedOption) =>
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`,
                    selectedOption
                  )
                }
                isSearchable={false}
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`}
                options={residentialStatusList}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                // menuPortalTarget={document.getElementById(
                //   `accordian${index}`
                // )}
                placeholder="Select Residential Status"
                styles={{
                  control: (styles, state) => {
                    const borderColor =
                      !state.hasValue &&
                      touched[directorFieldNames.RESIDENTIALSTATUS] &&
                      errors[directorFieldNames.RESIDENTIALSTATUS]
                        ? "red"
                        : "#ced4da";

                    return {
                      ...styles,
                      borderColor,
                    };
                  },
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={item[directorFieldNames.RESIDENTIALSTATUS]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Living Since</label>
              <DatePicker
                selected={
                  moment(item[directorFieldNames.LIVINGSINCE]).isValid()
                    ? new Date(item[directorFieldNames.LIVINGSINCE])
                    : null
                }
                customInput={
                  <MaskedInput
                    mask="11/11/1111"
                    placeholder={
                      currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                    }
                  />
                }
                className="form-control"
                dateFormat={
                  currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                }
                placeholderText={
                  currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                }
                onCalendarClose={() => {
                  setDirectorData(values["directorInfo"]);
                }}
                onChange={(value) => {
                  if (value !== null) {
                    let fieldValue = moment(value).isValid()
                      ? new Date(value)
                      : value;
                    // this.setState({ value: fieldValue });
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                      fieldValue
                    );
                  }
                  // setFieldValue(
                  //   `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                  //   date !== null
                  //     ? moment(date).utc().format("YYYY-MM-DD")
                  //     : null
                  // );
                }}
              />
              {/* <input
                type="date"
                className="form-control"
                placeholder="Living Since"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`}
                onChange={handleChange}
                value={item[directorFieldNames.LIVINGSINCE]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>Address Line 1</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address Line 1"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE1}`}
                onChange={handleChange}
                value={item[directorFieldNames.ADDRESSLINE1]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>Address Line 2</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address Line 2"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE2}`}
                onChange={handleChange}
                value={item[directorFieldNames.ADDRESSLINE2]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                placeholder="City"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.CITY}`}
                onChange={handleChange}
                value={item[directorFieldNames.CITY]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>
                {currentCountry == "GB"
                  ? "Business Region"
                  : currentCountry == "US"
                  ? "State"
                  : "Province"}
              </label>
              <Select
                closeMenuOnSelect={true}
                onChange={(selectedOption) => {
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.STATE}`,
                    selectedOption
                  );
                  setDirectorData(values["directorInfo"]);
                }}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                isLoading={stateListLoading}
                isSearchable={false}
                name={fieldNames.STATE}
                options={stateList}
                placeholder={
                  currentCountry == "GB"
                    ? "Business Region"
                    : currentCountry == "US"
                    ? "State"
                    : "Province"
                }
                styles={{
                  control: (styles, state) => {
                    const borderColor =
                      !state.hasValue &&
                      touched[fieldNames.STATE] &&
                      errors[fieldNames.STATE]
                        ? "red"
                        : "#ced4da";

                    return { ...styles, borderColor };
                  },
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={item[fieldNames.STATE]}
              />
              {/* <label>State/Province</label>
              <RegionDropdown
                country={currentCountry}
                countryValueType="short"
                placeholder="Select State/Province"
                classes="form-control"
                value={item[fieldNames.STATE]}
                onChange={(val) =>
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.STATE}`,
                    val
                  )
                }
              /> */}
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>
                {currentCountry === "GB"
                  ? "Post Code"
                  : currentCountry === "CA"
                  ? "Postal Code"
                  : "Zip Code"}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={
                  currentCountry === "GB"
                    ? "Post Code"
                    : currentCountry === "CA"
                    ? "Postal Code"
                    : "Zip Code"
                }
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.POSTALCODE}`}
                onChange={handleChange}
                value={item[directorFieldNames.POSTALCODE]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>

          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>Residential Status</label>
              <Select
                closeMenuOnSelect={true}
                onChange={(selectedOption) =>
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`,
                    selectedOption
                  )
                }
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`}
                options={residentialStatusList}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                placeholder="Select Residential Status"
                styles={{
                  control: (styles, state) => {
                    const borderColor =
                      !state.hasValue &&
                      touched[directorFieldNames.RESIDENTIALSTATUS] &&
                      errors[directorFieldNames.RESIDENTIALSTATUS]
                        ? "red"
                        : "#ced4da";

                    return {
                      ...styles,
                      borderColor,
                    };
                  },
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={item[directorFieldNames.RESIDENTIALSTATUS]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              />
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
            <div className="form-group">
              <label>Living Since</label>
              <DatePicker
                selected={
                  item[directorFieldNames.LIVINGSINCE]
                    ? new Date(item[directorFieldNames.LIVINGSINCE])
                    : null
                }
                customInput={
                  <MaskedInput
                    mask="11/11/1111"
                    placeholder={
                      currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                    }
                  />
                }
                className="form-control"
                dateFormat={
                  currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                }
                placeholderText={
                  currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                }
                onCalendarClose={() => {
                  setDirectorData(values["directorInfo"]);
                }}
                onChange={(value) => {
                  if (value !== null) {
                    let fieldValue = moment(value).isValid()
                      ? moment(value).format(
                          currentCountry !== "GB" ? "MM/DD/YYYY" : "DD/MM/YYYY"
                        )
                      : value;
                    // this.setState({ value: fieldValue });
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                      fieldValue
                    );
                  }
                  // setFieldValue(
                  //   `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                  //   date !== null ? moment(date).format("YYYY-MM-DD") : null
                  // );
                }}
              />
              {/* <input
                type="date"
                className="form-control"
                placeholder="Living Since"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`}
                onChange={handleChange}
                value={item[directorFieldNames.LIVINGSINCE]}
                onBlur={() => {
                  setDirectorData(values["directorInfo"]);
                }}
              /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
