import { type } from "@testing-library/user-event/dist/type";
import clsx from "clsx";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks/hooks";
import {
  API_URL,
  deleteDocuments,
  downloadBusinessAccountScore,
  getBusinessAccountScore,
  getCreditSafeReport,
  getDocuments,
  uploadDocuments,
} from "../../../request";
import Loaderspinner from "../../loader";
import { getUserDetails } from "../../login/loginpage";
import { ToastMessage } from "../../ToastMessage";
import { useDispatch } from "react-redux";
import { TRIGGER_CREDIT_SCORE } from "../../../redux/actions/actionTypes";

export default function BusinessCreditScore() {
  const [checkBusinessCredit, setCheckBusinessCreditScore] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isIdentityProof, setIsIdentityProof] = useState(false);
  const [isAddressProof, setIsAddressProof] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [businessCreditScore, setBusinessCreditScore] = useState(creditScore);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [loadingCreditScore, setLoadingCreditScore] = useState(true);
  const [error, setError] = useState(false);
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const { leadDetails } = useAppSelector((state) => state.leadDetailsReducer);
  const { creditScore } = useAppSelector((state) => state.creditScoreReducer);
  const dispatch = useDispatch();

  const disclaimerMsg = {
    text:
      currentCountry === "GB"
        ? "Disclaimer : In-order to view your business credit score, we will need to verify your identity. You can upload your documents below or through “Upload Files”."
        : "“Disclaimer : In-order to view your business credit score, we will need to verify your identity. Please complete the below:”",
    type: "disclaimer",
  };

  const submitMsg = {
    text: "Thank you for sending over the documents. We will be in-touch via email after we have verified your details. After successful verification, you will be able to view your company business credit score along with a detailed report.",
    type: "msg",
  };
  const userDetails = getUserDetails();
  const [headingText, setHeadingText] = useState(disclaimerMsg);

  useEffect(() => {
    getFiles();
  }, [leadDetails]);
  useEffect(() => {
    setBusinessCreditScore(creditScore);
  }, [creditScore]);

  const getFiles = () => {
    setLoadingFiles(true);
    if (userDetails && userDetails["lead_id"]) {
      getDocuments(userDetails["lead_id"])
        .then((resp) => {
          setLoadingFiles(false);
          if (
            resp &&
            resp.records &&
            resp.records.length > 0 &&
            resp["record_count"] !== 0
          ) {
            let countAddressProofApproved = 0;
            let countIdentityProofApproved = 0;
            setHeadingText(submitMsg);
            let list = [];
            setLoadingCreditScore(false);
            resp.records.forEach((item) => {
              if (
                item["la_status"] == 1 &&
                item["la_doc_type"] == "Proof of Identity"
              ) {
                countIdentityProofApproved = countIdentityProofApproved + 1;
              } else if (
                item["la_status"] == 1 &&
                item["la_doc_type"] == "Proof of Address"
              ) {
                countAddressProofApproved = countAddressProofApproved + 1;
              }

              if (
                countAddressProofApproved > 0 &&
                countIdentityProofApproved > 0
              ) {
                if (leadDetails["ApptxtCustomerConsentReceived"] === "Yes") {
                  setIsApproved(true);
                } else {
                  setLoadingCreditScore(false);
                }
              }

              list.push({
                file: { name: item["la_file_description"] },
                type: item["la_doc_type"],
                id: item["la_id"],
              });
            });
            setFileList(list);
          } else {
            setLoadingCreditScore(false);
            setLoadingFiles(false);
          }
        })
        .catch((err) => {
          setLoadingCreditScore(false);
          setLoadingFiles(false);
        });
    }
  };

  useEffect(() => {
    if (isApproved && !creditScore) {
      setLoadingCreditScore(true);
      getBusinessAccountScore(userDetails["lead_id"], currentCountry)
        .then((resp) => {
          setBusinessCreditScore(resp.data);
          dispatch({
            type: TRIGGER_CREDIT_SCORE,
            creditScore: resp.data,
          });

          setLoadingCreditScore(false);
        })
        .catch((err) => {
          setLoadingCreditScore(false);
          setLoadingFiles(false);
        });
      // getCreditSafeReport(userDetails["lead_id"])
      //   .then((resp) => {
      //     setBusinessCreditScore(resp.data.data);
      //     setLoadingCreditScore(false);
      //   })
      //   .catch((err) => {
      //     setLoadingCreditScore(false);
      //     setLoadingFiles(false);
      //   });
    }
  }, [isApproved]);

  // const recheckCreditScore = () => {
  //   setLoadingCreditScore(true);

  //   getBusinessAccountScore(userDetails["lead_id"], currentCountry)
  //     .then((resp) => {
  //       setBusinessCreditScore(resp.data);
  //       setLoadingCreditScore(false);
  //     })
  //     .catch((err) => {
  //       setLoadingCreditScore(false);
  //       setLoadingFiles(false);
  //     });
  // };

  useEffect(() => {}, [leadDetails]);
  const hiddenFileAddressProofInput = useRef(null);
  const hiddenFileIndentityProofInput = useRef(null);

  const deleteFile = (item, i) => {
    if (window.confirm("Are you sure remove the file!")) {
      let list = [...fileList];

      if (item["id"]) {
        deleteDocuments(item["id"]).then((resp) => {
          if (resp.status == "success") {
            ToastMessage(resp.records, "success");
            list.splice(i, 1);
            setFileList(list);
            if (!list.length) {
              setHeadingText(disclaimerMsg);
            }
          }
        });
      } else {
        list.splice(i, 1);
        setFileList(list);
        if (!list.length) {
          setHeadingText(disclaimerMsg);
        }
        ToastMessage("Lead Attachments Deleted Successfully.", "success");
      }
    }
  };

  function handleChange(event, type) {
    if (event.target.files[0]) {
      let list = [...fileList];
      let totalSizeMB = event.target.files[0]["size"] / Math.pow(1024, 2);
      if (totalSizeMB < 5) {
        list.push({ file: event.target.files[0], type: type });
        setFileList(list);
      } else {
        ToastMessage("File size needs to be less than 5 MB", "error");
      }
    }
  }

  function handleAddressFileChange(event, type) {
    if (event.target.files[0]) {
      let list = [...fileList];
      let totalSizeMB = event.target.files[0]["size"] / Math.pow(1024, 2);
      if (totalSizeMB < 5) {
        list.push({ file: event.target.files[0], type: type });
        setFileList(list);
      } else {
        ToastMessage("File size needs to be less than 5 MB", "error");
      }
    }
  }

  const submitDocuments = () => {
    if (!checkBusinessCredit) {
      ToastMessage("Please select the checkbox", "error");
      setError(true);
    } else {
      let identityProofDocs = fileList
        .filter((file) => file.type == "Identity Proof")
        .map((item) => {
          if (!item.id) {
            return item.file;
          } else {
            return;
          }
        });
      let addressProofDocs = fileList
        .filter((file) => {
          if (file.type == "Address Proof") {
            return file.file;
          }
        })
        .map((item) => {
          if (!item.id) {
            return item.file;
          } else {
            return;
          }
        });

      if (!addressProofDocs.length && identityProofDocs.length) {
        ToastMessage("You must upload both the documents!", "error");
      } else {
        uploadDocuments(
          {
            fullname: `${userDetails["first_name"]} ${userDetails["last_name"]}`,
            address_proof: addressProofDocs.length ? addressProofDocs : [],
            identity_proof: identityProofDocs.length ? identityProofDocs : [],
          },
          userDetails["lead_id"]
        )
          .then((resp) => {
            if (resp.isSuccess == 1) {
              ToastMessage("Attachments submitted successfully!", "success");
              setHeadingText(disclaimerMsg);
              getFiles();
            } else if (resp.status == "error") {
              ToastMessage("Something went wrong!", "error");
            }
          })
          .catch((err) => {
            ToastMessage("Something went wrong!", "error");
          });
      }
    }
  };

  const downaloadCreditScore = async () => {
    setLoadingDownload(true);
    let response = await downloadBusinessAccountScore(
      userDetails["lead_id"],
      currentCountry
    )
      .then((data) => {
        let url = JSON.parse(data.response).Url;
        if (url) {
          let alink = document.createElement("a");
          alink.href = `${API_URL}${url}`;
          alink.download = "SamplePDF.pdf";
          alink.target = "_blank";
          alink.click();
          setLoadingDownload(false);
        }
      })
      .catch((err) => {
        setLoadingDownload(false);

        alert(err);
      });
  };

  return (
    <section>
      <div className="business-panel">
        {loadingCreditScore ? (
          <Loaderspinner size="45px" />
        ) : (
          <>
            {isApproved && (
              <div className="">
                {
                  <div className="row">
                    {loadingDownload && <Loaderspinner size="45px" />}
                    {/* <div className="col-12 mb-2">
                      <div className="text-right">
                        <button
                          className="btn btn-secondary "
                          onClick={recheckCreditScore}
                        >
                          <i className="bi bi-info-circle-fill pr-2"></i>
                          Recheck
                        </button>
                      </div>
                    </div> */}

                    <div className="col-12">
                      <button
                        className="btn btn-primary download-score-btn"
                        onClick={downaloadCreditScore}
                      >
                        <i className="fa fa-cloud-arrow-down"></i> Download Your
                        Business Credit Score
                      </button>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                      <table
                        className="border credit-score-table mb-3"
                        style={{ width: "100%" }}
                        cellspacing="0"
                        border="0"
                      >
                        <tbody>
                          {/* <th>hello</th> */}
                          <tr className="text-center credit-score-table-head">
                            <th colspan="3">Company Summary</th>
                          </tr>
                          <tr>
                            <td style={{ width: "40%" }}>
                              <strong>Business name</strong>
                            </td>
                            <td>
                              <strong>
                                {businessCreditScore &&
                                businessCreditScore["report"][
                                  "companySummary"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "businessName"
                                ]
                                  ? businessCreditScore["report"][
                                      "companySummary"
                                    ]["businessName"]
                                  : "-"}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country</strong>
                            </td>
                            <td>
                              <strong>
                                {" "}
                                {businessCreditScore &&
                                businessCreditScore["report"][
                                  "companySummary"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "country"
                                ]
                                  ? businessCreditScore["report"][
                                      "companySummary"
                                    ]["country"]
                                  : "-"}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Company Number</strong>
                            </td>
                            <td>
                              {" "}
                              <strong>
                                {businessCreditScore &&
                                businessCreditScore["report"][
                                  "companySummary"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "companyNumber"
                                ]
                                  ? businessCreditScore["report"][
                                      "companySummary"
                                    ]["companyNumber"]
                                  : "-"}
                              </strong>
                            </td>
                          </tr>
                          {currentCountry !== "CA" && (
                            <tr>
                              <td>
                                <strong>Company Registration Number</strong>
                              </td>
                              <td>
                                <strong>
                                  {businessCreditScore &&
                                  businessCreditScore["report"][
                                    "companySummary"
                                  ] &&
                                  businessCreditScore["report"][
                                    "companySummary"
                                  ]["companyRegistrationNumber"]
                                    ? businessCreditScore["report"][
                                        "companySummary"
                                      ]["companyRegistrationNumber"]
                                    : "-"}
                                </strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {currentCountry === "GB" ? (
                        <table
                          className="border credit-score-table mb-3"
                          style={{ width: "100%" }}
                          cellspacing="0"
                          border="0"
                        >
                          <tbody>
                            {/* <th>hello</th> */}
                            <tr className="text-center credit-score-table-head">
                              <th colspan="3">
                                Latest Shareholders Equity Figure{" "}
                              </th>
                            </tr>
                            <tr>
                              <td style={{ width: "40%" }}>
                                <strong>Currency</strong>
                              </td>
                              <td>
                                <strong>
                                  {businessCreditScore &&
                                  businessCreditScore["report"][
                                    "shareCapitalStructure"
                                  ] &&
                                  businessCreditScore["report"][
                                    "shareCapitalStructure"
                                  ]["issuedShareCapital"]
                                    ? businessCreditScore["report"][
                                        "shareCapitalStructure"
                                      ]["issuedShareCapital"]["currency"]
                                    : "-"}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Value</strong>
                              </td>
                              <td>
                                <strong>
                                  {" "}
                                  {businessCreditScore &&
                                  businessCreditScore["report"][
                                    "shareCapitalStructure"
                                  ] &&
                                  businessCreditScore["report"][
                                    "shareCapitalStructure"
                                  ]["issuedShareCapital"]
                                    ? businessCreditScore["report"][
                                        "shareCapitalStructure"
                                      ]["issuedShareCapital"]["value"]
                                    : "-"}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table
                          className="border credit-score-table mb-3"
                          style={{ width: "100%" }}
                        >
                          <tr className="text-center credit-score-table-head">
                            <th colspan="3">Credit Rating</th>
                          </tr>
                          <tr>
                            <td>
                              <strong>Common Value</strong>
                            </td>
                            <td>
                              <strong>
                                {" "}
                                {businessCreditScore &&
                                businessCreditScore["report"][
                                  "companySummary"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "creditRating"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "creditRating"
                                ]["commonValue"]
                                  ? businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["commonValue"]
                                  : "-"}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Common Description</strong>
                            </td>
                            <td>
                              <strong>
                                {" "}
                                {businessCreditScore &&
                                businessCreditScore["report"][
                                  "companySummary"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "creditRating"
                                ] &&
                                businessCreditScore["report"]["companySummary"][
                                  "creditRating"
                                ]["commonDescription"]
                                  ? businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["commonDescription"]
                                  : "-"}
                              </strong>
                            </td>
                          </tr>
                        </table>
                      )}
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          {currentCountry === "GB" ? (
                            <table
                              className="border credit-score-table mb-3"
                              style={{ width: "100%" }}
                            >
                              <tr className="text-center credit-score-table-head">
                                <th colspan="3">Credit Rating</th>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Common Value</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {businessCreditScore &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ] &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["commonValue"]
                                      ? businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["commonValue"]
                                      : "-"}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Common Description</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {businessCreditScore &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ] &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["commonDescription"]
                                      ? businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["commonDescription"]
                                      : "-"}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          ) : (
                            <>
                              <table
                                className="border credit-score-table"
                                style={{ width: "100%" }}
                              >
                                <tr className="text-center credit-score-table-head">
                                  <th colspan="3">Provider Value</th>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Max Value</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {" "}
                                      {businessCreditScore &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ] &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ]["creditRating"]["providerValue"][
                                        "maxValue"
                                      ]
                                        ? businessCreditScore["report"][
                                            "companySummary"
                                          ]["creditRating"]["providerValue"][
                                            "maxValue"
                                          ]
                                        : "-"}
                                    </strong>
                                  </td>{" "}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Min Value</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {(businessCreditScore &&
                                        businessCreditScore["report"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["providerValue"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["providerValue"][
                                          "minValue"
                                        ]) ||
                                        "-"}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Value</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {(businessCreditScore &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["providerValue"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["providerValue"][
                                          "value"
                                        ]) ||
                                        "-"}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Provider Description</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {" "}
                                      {(businessCreditScore &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["providerValue"] &&
                                        businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"][
                                          "providerDescription"
                                        ]) ||
                                        "-"}
                                    </strong>
                                  </td>
                                </tr>
                              </table>

                              <table
                                className="border credit-score-table mt-3"
                                style={{ width: "100%" }}
                              >
                                <tr className="text-center credit-score-table-head">
                                  <th colspan="3">Credit Limit</th>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Currency</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {" "}
                                      {businessCreditScore &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ] &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ]["creditRating"]["creditLimit"][
                                        "currency"
                                      ]
                                        ? businessCreditScore["report"][
                                            "companySummary"
                                          ]["creditRating"]["creditLimit"][
                                            "currency"
                                          ]
                                        : "-"}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Value</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {" "}
                                      {businessCreditScore &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ] &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ]["creditRating"]["creditLimit"]["value"]
                                        ? businessCreditScore["report"][
                                            "companySummary"
                                          ]["creditRating"]["creditLimit"][
                                            "value"
                                          ]
                                        : "-"}
                                    </strong>
                                  </td>
                                </tr>
                              </table>
                            </>
                          )}
                        </div>
                        {currentCountry == "GB" && (
                          <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <table
                              className="border credit-score-table "
                              style={{ width: "100%" }}
                            >
                              <tr className="text-center credit-score-table-head">
                                <th colspan="3">Credit Limit</th>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Currency</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {businessCreditScore &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ] &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["creditLimit"]["currency"]
                                      ? businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["creditLimit"][
                                          "currency"
                                        ]
                                      : "-"}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Value</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {businessCreditScore &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ] &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["creditLimit"]["value"]
                                      ? businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["creditLimit"][
                                          "value"
                                        ]
                                      : "-"}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          </div>
                        )}

                        {currentCountry === "GB" && (
                          <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <table
                              className="border credit-score-table mt-3"
                              style={{ width: "100%" }}
                            >
                              <tr className="text-center credit-score-table-head">
                                <th colspan="3">Provider Value</th>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Max Value</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {businessCreditScore &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ] &&
                                    businessCreditScore["report"][
                                      "companySummary"
                                    ]["creditRating"]["providerValue"][
                                      "maxValue"
                                    ]
                                      ? businessCreditScore["report"][
                                          "companySummary"
                                        ]["creditRating"]["providerValue"][
                                          "maxValue"
                                        ]
                                      : "-"}
                                  </strong>
                                </td>{" "}
                              </tr>
                              <tr>
                                <td>
                                  <strong>Min Value</strong>
                                </td>
                                <td>
                                  <strong>
                                    {(businessCreditScore &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ]["creditRating"]["providerValue"][
                                        "minValue"
                                      ]) ||
                                      "-"}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Value</strong>
                                </td>
                                <td>
                                  <strong>
                                    {(businessCreditScore &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ]["creditRating"]["providerValue"][
                                        "value"
                                      ]) ||
                                      "-"}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Provider Description</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {(businessCreditScore &&
                                      businessCreditScore["report"][
                                        "companySummary"
                                      ]["creditRating"][
                                        "providerDescription"
                                      ]) ||
                                      "-"}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          </div>
                        )}

                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          {currentCountry == "GB" && (
                            <table
                              className="border credit-score-table mt-3"
                              style={{ width: "100%" }}
                            >
                              <tr className="text-center credit-score-table-head">
                                <th colspan="3">Ccj Summary</th>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Exact Registered</strong>
                                </td>
                                <td>
                                  <strong>
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["exactRegistered"]) ||
                                      0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Possible Registered</strong>
                                </td>
                                <td>
                                  <strong>
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["possibleRegistered"]) ||
                                      0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Number Of Exact</strong>
                                </td>
                                <td>
                                  <strong>
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["numberOfExact"]) ||
                                      0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Number Of Possible</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["numberOfPossible"]) ||
                                      0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Number Of Satisfied</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["numberOfSatisfied"]) ||
                                      0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Number Of Writs</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["numberOfWrits"]) ||
                                      0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Currency</strong>
                                </td>
                                <td>
                                  <strong>
                                    {" "}
                                    {(businessCreditScore &&
                                      businessCreditScore["report"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"] &&
                                      businessCreditScore["report"][
                                        "negativeInformation"
                                      ]["ccjSummary"]["currency"]) ||
                                      "-"}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          )}
                          {(currentCountry === "US" ||
                            currentCountry === "CA") && (
                            <table
                              className="border credit-score-table"
                              style={{ width: "100%" }}
                            >
                              <tr className="text-center credit-score-table-head">
                                <th colspan="3">Negative Information</th>
                              </tr>
                              {currentCountry === "CA" && (
                                <>
                                  <tr>
                                    <td>
                                      <strong>Returned Cheques</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][0] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][0][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Collections</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][1] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][1][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Legal Suits</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][2] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][2][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Judgements</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][3] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][3][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Other Legal</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][4] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][4][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <strong>Liens</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][5] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][5][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <strong>Bankruptcies</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][6] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][6][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>
                                        Total Derogatory Legal Value
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][7] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["derogatorySummaries"][7][
                                            "numberOnFile"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                </>
                              )}

                              {currentCountry === "US" && (
                                <>
                                  <tr>
                                    <td>
                                      <strong>Bankruptcies</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"][
                                            "bankruptcy"
                                          ]) ||
                                          "-"}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Tax lien filings</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"][
                                            "taxLienFilings"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Judgements</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"][
                                            "judgmentFilings"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Lawsuits</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"]["suits"]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Sum</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        Currency -{" "}
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"]["sum"][
                                            "currency"
                                          ]) ||
                                          0}
                                        <br />
                                        Value -{" "}
                                        {(businessCreditScore &&
                                          businessCreditScore["report"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"] &&
                                          businessCreditScore["report"][
                                            "negativeInformation"
                                          ]["legalFilingSummary"]["sum"][
                                            "value"
                                          ]) ||
                                          0}
                                      </strong>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )}

            {!isApproved && !businessCreditScore && !loadingCreditScore && (
              <>
                {" "}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div
                        className="mb-3 p-3 disclaimer"
                        style={{
                          borderRadius: "5px",
                          border:
                            headingText.type == "disclaimer"
                              ? "1px solid #dc3545"
                              : "1px solid #006090",
                          width:
                            headingText.type == "disclaimer"
                              ? "max-content"
                              : "",
                          backgroundColor: "#e8f2f7",
                        }}
                      >
                        <h6
                          style={{ fontSize: "13px" }}
                          className={clsx("mb-0 ", {
                            "text-danger font-italic":
                              headingText.type == "disclaimer",
                            "color-primary": headingText.type == "msg",
                          })}
                        >
                          {" "}
                          {headingText.text}
                        </h6>
                      </div>

                      <input
                        type="checkbox"
                        onClick={(e) =>
                          setCheckBusinessCreditScore(e.target.checked)
                        }
                        name="Upload Bank Statement Copies Instead"
                        className={clsx("upload-checkbox ", {
                          "is-invalid": error && !checkBusinessCredit,
                        })}
                      />
                      <label>
                        I confirm that I am an authorised personal (Director or
                        UBO) and consent to a soft business credit check.
                      </label>
                      {error && !checkBusinessCredit && (
                        <div className="text-danger ml-2">
                          Please select the checkbox!
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="upload-doc-panel upload-doc-panel-merchant"
                      id="divcheck"
                    >
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
                          <div className="upload-area">
                            <p>
                              <strong>
                                Please upload the following document :
                              </strong>
                            </p>
                            <ul>
                              <li>
                                <b>Proof of ID.</b>
                                <ul>
                                  <li>-Drivers License</li>
                                  <li className="pl-1"> OR</li>
                                  <li>-Passport Copy</li>
                                </ul>
                              </li>

                              <li>
                                <b>Proof of Address.</b>
                                <ul>
                                  <li>
                                    -Bank Statement (Dated within the last 90
                                    days)
                                  </li>
                                  <li className="pl-1"> OR</li>
                                  <li>
                                    -Utility Bill (Dated within the last 90
                                    days)
                                  </li>
                                  <li className="pl-1"> OR</li>
                                  <li>-Drivers License</li>
                                </ul>
                              </li>
                            </ul>

                            {fileList.length > 0 && (
                              <div className="uploaded-file">
                                <p>
                                  <strong>File Uploaded:</strong>
                                </p>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">File Name</th>
                                        <th scope="col">Document Category</th>
                                        <th scope="col">Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {fileList.map((item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="file-name">
                                              {item.file ? item.file.name : ""}
                                            </td>
                                            <td className="text-center">
                                              {item.type}
                                            </td>
                                            <td className="text-center">
                                              {" "}
                                              <i
                                                className="fa fa-trash cursor-pointer"
                                                style={{ float: "unset" }}
                                                onClick={() =>
                                                  deleteFile(item, i)
                                                }
                                              ></i>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <p>
                            <strong>Select Document Category</strong>
                          </p>
                          <div className="d-flex justify-space-evenly my-3">
                            <button
                              className="btn btn-primary proof-doc-btns mr-2"
                              type="button"
                              onClick={() => {
                                setIsIdentityProof(true);
                              }}
                            >
                              Proof of Identity
                            </button>
                            <button
                              className="btn btn-primary proof-doc-btns"
                              type="button"
                              onClick={() => {
                                setIsAddressProof(true);
                              }}
                            >
                              Proof of Address
                            </button>
                          </div>

                          {isIdentityProof && (
                            <>
                              <label className="form-label">
                                Upload Identity Proof
                              </label>
                              <div className="upload-box">
                                <input
                                  type="file"
                                  id="Identity-Proof"
                                  name="file"
                                  className="upload-doc"
                                  accept="image/png,image/jpeg,.pdf"
                                  ref={hiddenFileIndentityProofInput}
                                  hidden
                                  onChange={(e) => {
                                    setSelectedFileType("Identity Proof");
                                    handleChange(e, "Identity Proof");
                                  }}
                                />

                                <img
                                  src={require("../../../images/file-pdf.png")}
                                  alt=""
                                  className="upload-icon"
                                />
                                <label
                                  for="upload-file"
                                  className="btn btn-primary upload-btn"
                                  onClick={() =>
                                    hiddenFileIndentityProofInput.current.click()
                                  }
                                >
                                  {" "}
                                  Upload
                                </label>

                                <p>Max file size: 5MB each</p>
                                <p>
                                  Supported file types: PDF, JPG, PNG Bitmap
                                  etc.
                                </p>
                              </div>
                            </>
                          )}

                          {isAddressProof && (
                            <>
                              {" "}
                              <label className="form-label">
                                Upload Address Proof
                              </label>
                              <div className="upload-box">
                                <input
                                  type="file"
                                  id="Address-Proof"
                                  name="file"
                                  className="upload-doc"
                                  accept="image/png,image/jpeg,.pdf"
                                  hidden
                                  ref={hiddenFileAddressProofInput}
                                  onChange={(e) => {
                                    setSelectedFileType("Address Proof");
                                    handleAddressFileChange(e, "Address Proof");
                                  }}
                                />

                                <img
                                  src={require("../../../images/file-pdf.png")}
                                  alt=""
                                  className="upload-icon"
                                />
                                <label
                                  for="upload-file"
                                  className="btn btn-primary upload-btn"
                                  onClick={() =>
                                    hiddenFileAddressProofInput.current.click()
                                  }
                                >
                                  {" "}
                                  Upload
                                </label>

                                <p>Max file size: 5MB each</p>
                                <p>
                                  Supported file types: PDF, JPG, PNG Bitmap
                                  etc.
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn btn-primary save-btn next-btn"
                        type="submit"
                        onClick={() => {
                          submitDocuments();
                        }}
                      >
                        Save <i className="fa fa-file-image-o"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
}
