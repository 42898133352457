import { TRIGGER_CURRENT_COUNTRY } from "../actions/actionTypes";

const initialState = {
  currentCountry: "",
};

const countryReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_CURRENT_COUNTRY:
      return {
        ...state,
        currentCountry: action.currentCountry,
      };
    default:
      return state;
  }
};

export default countryReducer;
