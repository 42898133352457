import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Datatables from "./Datatables";
export default function TaxRates(props) {

    const columns = [
      {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2">{row.name}</Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                ref={ref}
                {...triggerHandler}
                className="ml-2 cursor-pointer ellipsis-text"
              >
                {row.name}{" "}
              </div>
            )}
          </OverlayTrigger>
        ),
        sortable: true,
      },
      {
        name: "Code",
        selector: (row) => row.code,
        sortable: true,
      },
      {
        name: "Effective Tax Rate",
        selector: (row) => row.effectiveTaxRate,
        sortable: true,
      },
      {
        name: "Total Tax Rate",
        selector: (row) => row.totalTaxRate,
        sortable: true,
      },
    ];

        const finalUrl = `${props.endUrl}/CODAT/Tax_Rates/${props.leadId}`;

      return (
        <Datatables title={props.title} apiUrl={finalUrl} apiColumn={columns} />
    );
}