import { FieldArray, Formik } from "formik/dist";
import React from "react";
import { fieldNames } from "../../requestaquote/components/application-information";
import MaskedInput from "react-maskedinput";
import Select from "react-select";
import clsx from "clsx";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { setDashboardStepNo } from "../dashboard";
import { useEffect } from "react";
import {
  getBusinessSectorList,
  getBusinessStateList,
  getDirectorList,
} from "../../../request";
import { useState } from "react";
import {
  directorFieldNames,
  formatNumberInput,
  my_date,
  numberRegex,
  prefixPlusIcon,
  removeComma,
  residentialStatusList,
} from "../../Constants";
import { ToastMessage } from "../../ToastMessage";
import moment from "moment";
import { useAppSelector } from "../../../redux/hooks/hooks";
import CurrencyIcon from "../../CurrencyIcon";
import DirectorInfo from "./directorInfo";
import DatePicker from "react-datepicker";

export const setReviewBusinessData = (data) => {
  localStorage.setItem("reviewBusinessInfo", JSON.stringify(data));
};

export const getReviewBusinessData = () => {
  return JSON.parse(localStorage.getItem("reviewBusinessInfo"));
};

export const setDirectorData = (data) => {
  localStorage.setItem("directorData", JSON.stringify(data));
};

export const getDirectorData = () => {
  return JSON.parse(localStorage.getItem("directorData"));
};

export const initialDirectorObject = {
  [directorFieldNames.NATUREOFCONTROL]: "",
  [directorFieldNames.KINDOFSHAREHOLDER]: "",
  [directorFieldNames.SHAREHOLDERDOBFULLFORMAT]: null,
  [directorFieldNames.POSTALCODE]: "",
  [directorFieldNames.ADDRESS]: "",
  [directorFieldNames.HOUSE_NUMBER]: "",
  [directorFieldNames.HOUSE_NAME]: "",
  [directorFieldNames.STREET]: "",
  [directorFieldNames.COUNTY]: "",
  [directorFieldNames.TOWN]: "",
  [directorFieldNames.RESIDENTIALSTATUS]: "",
  [directorFieldNames.LIVINGSINCE]: null,
  [directorFieldNames.FIRSTNAME]: "",
  [directorFieldNames.LASTNAME]: "",
  [directorFieldNames.PHONENUMBER]: "",
  [directorFieldNames.EMAIL]: "",
  [directorFieldNames.ISPRIMARY]: "",
  [directorFieldNames.CHOOSEADDRESS]: "",
  [directorFieldNames.ADDRESSLINE1]: "",
  [directorFieldNames.ADDRESSLINE2]: "",
  [directorFieldNames.STATE]: "",
  [directorFieldNames.PREVIOUSADDRESS]: [],
};

const Accordion = ({ title, children, isPrimary, id }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title  ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
        style={{ justifyContent: "flex-start" }}
      >
        <div className="px-2"> {title}</div>
        {isPrimary && (
          <button
            className="btn btn-success btn-sm mr-2"
            style={{ backgroundColor: "#198754", pointerEvents: "none" }}
            type="button"
          >
            Primary
          </button>
        )}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content" id={id}>
          {children}
        </div>
      </div>
    </div>
  );
};

function ReviewBusinessInformation({ data, setActiveStep, activeStep }) {
  const storedData = getReviewBusinessData();
  const { leadDetails } = useAppSelector((state) => state.leadDetailsReducer);
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  const [directorList, setDirectorList] = useState([]);

  useEffect(() => {
    if (data && data["lmc_bi_business_number"] && !data["ShareHolderList"]) {
      getDirectorList(data["lmc_bi_business_number"], currentCountry).then(
        (resp) => {
          setDirectorList(
            currentCountry === "GB"
              ? resp.items
              : [resp["documents"][0]["primary_contact"]]
          );
          if (!getDirectorData()) {
            generateDirectorArray(
              currentCountry === "GB"
                ? resp.items
                : resp["documents"][0]["primary_contact"]
                ? [resp["documents"][0]["primary_contact"]]
                : []
            );
          }
        }
      );
    } else if (data["ShareHolderList"]) {
      setDirectorList(data["ShareHolderList"]);
      if (!getDirectorData()) {
        generateDirectorArray(data["ShareHolderList"]);
      }
    }
  }, []);

  const today = new Date();

  const validationSchemaUS = Yup.object().shape({
    [fieldNames.CARDPAYMENTAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSTARTDATE]: Yup.string().required(),
    [fieldNames.SUPPLIERDUEAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSECTOR]: Yup.string().required(),
    [fieldNames.BUSINESSLEGALNUMBER]: Yup.string(),
    [fieldNames.DIRECTORINFO]: Yup.array()
      .of(
        Yup.object().shape({
          [directorFieldNames.NATUREOFCONTROL]: Yup.string().nullable(true),
          [directorFieldNames.KINDOFSHAREHOLDER]: Yup.string().nullable(true),
          [directorFieldNames.SHAREHOLDERDOBFULLFORMAT]:
            Yup.string().nullable(true),
          [directorFieldNames.POSTALCODE]: Yup.string().nullable(true),
          [directorFieldNames.ADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.HOUSE_NUMBER]: Yup.string().nullable(true),
          [directorFieldNames.HOUSE_NAME]: Yup.string().nullable(true),
          [directorFieldNames.STREET]: Yup.string().nullable(true),
          [directorFieldNames.COUNTY]: Yup.string().nullable(true),
          [directorFieldNames.TOWN]: Yup.string().nullable(true),
          [directorFieldNames.RESIDENTIALSTATUS]: Yup.string().nullable(true),
          [directorFieldNames.LIVINGSINCE]: Yup.string().nullable(true),
          [directorFieldNames.FIRSTNAME]: Yup.string().nullable(true),
          [directorFieldNames.LASTNAME]: Yup.string().nullable(true),
          [directorFieldNames.PHONENUMBER]: Yup.string().nullable(true),
          [directorFieldNames.EMAIL]: Yup.string().nullable(true),
          [directorFieldNames.ISPRIMARY]: Yup.string().nullable(true),
          [directorFieldNames.CHOOSEADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.ADDRESSLINE1]: Yup.string().nullable(true),
          [directorFieldNames.ADDRESSLINE2]: Yup.string().nullable(true),
          [directorFieldNames.PREVIOUSADDRESS]: Yup.array()
            .of(
              Yup.object().shape({
                [directorFieldNames.ADDRESSLINE1]: Yup.string().nullable(true),
                [directorFieldNames.ADDRESSLINE2]: Yup.string().nullable(true),
                [directorFieldNames.COUNTY]: Yup.string().nullable(true),
                [directorFieldNames.POSTALCODE]: Yup.string().nullable(true),
                [directorFieldNames.HOUSE_NUMBER]: Yup.string().nullable(true),
                [directorFieldNames.HOUSE_NAME]: Yup.string().nullable(true),
                [directorFieldNames.WHENTOMOVETOADDRESS]:
                  Yup.string().nullable(true),
                id: Yup.string().nullable(true),
              })
            )
            .nullable(true),
        })
      )
      .nullable(true),
  });

  const validationSchema = Yup.object().shape({
    [fieldNames.CARDPAYMENTAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSTARTDATE]: Yup.string().required(),
    [fieldNames.SUPPLIERDUEAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSECTOR]: Yup.string().required(),
    [fieldNames.BUSINESSLEGALNUMBER]: Yup.string()
      .required("Business Legal Number is required")
      .min(2, "Must be atlease 2 digit number"),

    [fieldNames.DIRECTORINFO]: Yup.array()
      .of(
        Yup.object().shape({
          [directorFieldNames.NATUREOFCONTROL]: Yup.string().nullable(true),
          [directorFieldNames.KINDOFSHAREHOLDER]: Yup.string().nullable(true),
          [directorFieldNames.SHAREHOLDERDOBFULLFORMAT]:
            Yup.string().nullable(true),
          [directorFieldNames.POSTALCODE]: Yup.string().nullable(true),
          [directorFieldNames.ADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.HOUSE_NUMBER]: Yup.string().nullable(true),
          [directorFieldNames.HOUSE_NAME]: Yup.string().nullable(true),
          [directorFieldNames.STREET]: Yup.string().nullable(true),
          [directorFieldNames.COUNTY]: Yup.string().nullable(true),
          [directorFieldNames.TOWN]: Yup.string().nullable(true),
          [directorFieldNames.RESIDENTIALSTATUS]: Yup.string().nullable(true),
          [directorFieldNames.LIVINGSINCE]: Yup.string().nullable(true),
          [directorFieldNames.FIRSTNAME]: Yup.string().nullable(true),
          [directorFieldNames.LASTNAME]: Yup.string().nullable(true),
          [directorFieldNames.PHONENUMBER]: Yup.string().nullable(true),
          [directorFieldNames.EMAIL]: Yup.string().nullable(true),
          [directorFieldNames.ISPRIMARY]: Yup.string().nullable(true),
          [directorFieldNames.CHOOSEADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.ADDRESSLINE1]: Yup.string().nullable(true),
          [directorFieldNames.ADDRESSLINE2]: Yup.string().nullable(true),
          [directorFieldNames.PREVIOUSADDRESS]: Yup.array()
            .of(
              Yup.object().shape({
                [directorFieldNames.ADDRESSLINE1]: Yup.string().nullable(true),
                [directorFieldNames.ADDRESSLINE2]: Yup.string().nullable(true),
                [directorFieldNames.COUNTY]: Yup.string().nullable(true),
                [directorFieldNames.POSTALCODE]: Yup.string().nullable(true),
                [directorFieldNames.HOUSE_NUMBER]: Yup.string().nullable(true),
                [directorFieldNames.HOUSE_NAME]: Yup.string().nullable(true),
                [directorFieldNames.WHENTOMOVETOADDRESS]:
                  Yup.string().nullable(true),
                id: Yup.string().nullable(true),
              })
            )
            .nullable(true),
        })
      )
      .nullable(true),
  });

  const generateDirectorArray = (data) => {
    if (data) {
      let list = [...data];

      if (list.length) {
        let updatedList = [];
        list.forEach((item) => {
          if (
            item["resigned_on"] &&
            new Date(item["resigned_on"]) < new Date()
          ) {
            return;
          } else {
            updatedList.push(item);
          }
        });

        if (currentCountry == "GB") {
          updatedList.length > 0 &&
            updatedList.map((item) => {
              let name = [];
              if (item.name) {
                name = item.name && item.name.split(",");
              }
              if (item.fullName) {
                name = item.fullName && item.fullName.split(",");
              }
              if (name.length > 1) {
                item[directorFieldNames.FIRSTNAME] = name[1] || "";
                item[directorFieldNames.LASTNAME] = name[0] || "";
              } else if (name.length == 1) {
                item[directorFieldNames.FIRSTNAME] = name[0];
                item[directorFieldNames.LASTNAME] = "";
              } else {
                item[directorFieldNames.FIRSTNAME] =
                  item[directorFieldNames.FIRSTNAME];
                item[directorFieldNames.LASTNAME] =
                  item[directorFieldNames.LASTNAME];
              }
              if (item["address"]) {
                item[directorFieldNames.POSTALCODE] =
                  item["address"]["postal_code"] || "";
                item[directorFieldNames.STREET] =
                  item["address"]["address_line_1"] ||
                  item[directorFieldNames.STREET];
                item[directorFieldNames.COUNTY] = item["address"]["locality"]
                  ? item["address"]["locality"]
                  : "";
                item[directorFieldNames.HOUSE_NAME] = "";
                item[directorFieldNames.HOUSE_NUMBER] =
                  item["address"]["premises"] || "";
                item[directorFieldNames.TOWN] = item[directorFieldNames.TOWN]
                  ? item[directorFieldNames.TOWN]
                  : "";
                item[directorFieldNames.RESIDENTIALSTATUS] = "";
                if (item["address"][directorFieldNames.ADDRESSLINE1]) {
                  item[directorFieldNames.ADDRESSLINE1] =
                    item["address"][directorFieldNames.ADDRESSLINE1];
                }
                if (item["address"][directorFieldNames.ADDRESSLINE2]) {
                  item[directorFieldNames.ADDRESSLINE2] =
                    item["address"][directorFieldNames.ADDRESSLINE2];
                }
              }

              item[directorFieldNames.LIVINGSINCE] = "";
              item[directorFieldNames.NATUREOFCONTROL] = "";
              item[directorFieldNames.EMAIL] = item["email"]
                ? item["email"]
                : "";
              item[directorFieldNames.PHONENUMBER] = item["phone"]
                ? item["phone"]
                : item[directorFieldNames.PHONENUMBER];
              item[directorFieldNames.KINDOFSHAREHOLDER] = item["share_count"]
                ? item["share_count"]
                : "";

              item[directorFieldNames.CHOOSEADDRESS] = "";
              if (updatedList.length > 1) {
                if (item[directorFieldNames.ISPRIMARY] == 1) {
                  item[directorFieldNames.ISPRIMARY] = true;
                } else {
                  item[directorFieldNames.ISPRIMARY] = false;
                }
              } else {
                item[directorFieldNames.ISPRIMARY] = true;
              }

              item[directorFieldNames.RESIDENTIALSTATUS] =
                item[directorFieldNames.RESIDENTIALSTATUS];

              if (item["date_of_birth"]) {
                item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT] = `${
                  item["date_of_birth"]["year"]
                }-${item["date_of_birth"]["month"]}-${
                  item["date_of_birth"]["day"]
                    ? item["date_of_birth"]["day"]
                    : 1
                }`;
              }
              delete item["address"];
              delete item["appointed_on"];
              delete item["links"];
              delete item["date_of_birth"];
              delete item["occupation"];
              delete item["nationality"];
              delete item["resigned_on"];
              delete item["officer_role"];
              delete item["country_of_residence"];
              delete item["identification"];

              item["HiddenShareHolderId"] = "";
              item["notified_on"] = "";
              item["is_active"] = 1;

              return item;
            });
        } else {
          updatedList.length > 0 &&
            updatedList.map((item) => {
              item[directorFieldNames.FIRSTNAME] = item["first_name"];
              item[directorFieldNames.LASTNAME] = item["last_name"];
              item[directorFieldNames.JOBTITLE] = item["job_titles"][0];
              item[directorFieldNames.KINDOFSHAREHOLDER] = item["share_count"]
                ? item["share_count"]
                : "";
              item[directorFieldNames.EMAIL] = item[directorFieldNames.EMAIL]
                ? item[directorFieldNames.EMAIL]
                : "";
              item[directorFieldNames.ADDRESSLINE1] = item[
                directorFieldNames.STREET
              ]
                ? item[directorFieldNames.STREET]
                : "";
              item[directorFieldNames.POSTALCODE] = item[
                directorFieldNames.POSTALCODE
              ]
                ? item[directorFieldNames.POSTALCODE]
                : "";
              if (stateList.length) {
                item[directorFieldNames.STATE] =
                  stateList[
                    stateList.findIndex((item1) => item["state"] == item1.value)
                  ];
              }
              delete item["gender"];
              delete item["job_titles"];
              delete item["created_at"];
              delete item["management_level"];
              delete item["title_codes"];
              delete item["job_function_id"];
              delete item["first_name"];
              delete item["last_name"];

              return item;
            });
        }

        let newList = [...updatedList];

        newList.sort((item) => {
          if (item["is_primary"]) {
            return item["is_primary"] ? -1 : 1;
          } else {
            return item;
          }
        });
        setDirectorData(newList);
      }
    }
  };
  const patchDirectorData = (data) => {
    return getDirectorData();
  };

  useEffect(() => {
    if (!storedData) {
      setReviewBusinessData(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    setReviewBusinessData(initialValues);
  }, [initialValues && businessSectorList]);
  const [businessSectorList, selectBusinessSectorList] = useState([]);

  const [loadingBusinessSectorList, setLoadingBusinessSectorList] =
    useState(false);

  useEffect(() => {
    setLoadingBusinessSectorList(true);
    getBusinessSectorList(currentCountry)
      .then((resp) => {
        setLoadingBusinessSectorList(false);

        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });
        let obj = { ...initialValues };
        obj[fieldNames.BUSINESSSECTOR] =
          array[
            array.findIndex((item) => data["lf_business_sector"] == item.value)
          ];

        // businessSectorList[
        //   businessSectorList.findIndex(
        //     (item) => data["lf_business_sector"] == item.value
        //     )
        //   ];
        selectBusinessSectorList(array);
        setReviewBusinessData(obj);
      })
      .catch((err) => {
        setLoadingBusinessSectorList(false);
      });
  }, [currentCountry]);

  const initialValues = {
    [fieldNames.BUSINESSSECTOR]:
      storedData && storedData[fieldNames.BUSINESSSECTOR]
        ? storedData[fieldNames.BUSINESSSECTOR]
        : businessSectorList[
            businessSectorList.findIndex(
              (item) => data["lf_business_sector"] == item.value
            )
          ],
    [fieldNames.BUSINESSSTARTDATE]: storedData
      ? storedData[fieldNames.BUSINESSSTARTDATE]
      : data["AppBusinessStartDate"],

    [fieldNames.CARDPAYMENTAMOUNT]: storedData
      ? storedData[fieldNames.CARDPAYMENTAMOUNT]
      : data["lf_monthly_credit_card_volume"],
    [fieldNames.SUPPLIERDUEAMOUNT]: storedData
      ? storedData[fieldNames.SUPPLIERDUEAMOUNT]
      : data["AppCurrentValueOverdueInvoices"],
    [fieldNames.BUSINESSLEGALNUMBER]: storedData
      ? storedData["businessLegalNumber"]
      : data["lmc_bi_business_number"],
    [fieldNames.DIRECTORINFO]: patchDirectorData(data),
  };


  const [stateListLoading, setStateListLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    setStateListLoading(true);
    getBusinessStateList(currentCountry)
      .then((resp) => {
        setStateListLoading(false);

        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });

        setStateList(array);
      })
      .catch((err) => {
        setStateListLoading(false);
      });
  }, [currentCountry]);

  return (
    <div className="dashboard-box position-relative card dashboard-card">
      <Formik
        initialValues={initialValues}
        enableReinitialize={currentCountry || businessSectorList}
        validationSchema={
          currentCountry == "GB" ? validationSchema : validationSchemaUS
        }
        onSubmit={(values, { setSubmitting }) => {
          if (values["directorInfo"] && values["directorInfo"].length) {
            let index = values["directorInfo"].findIndex(
              (item) => item["is_primary"] || item["is_primary"] == 1
            );

            if (values["directorInfo"].length) {
              values["directorInfo"].forEach((ele, i) => {
                let countryCode = document
                  .getElementsByClassName(`${i}-phone-input`)[0]
                  .getElementsByClassName("flag-dropdown")[0]
                  .getElementsByClassName("selected-flag")[0]
                  .getAttribute("title")
                  .split("+")[1];
                if (ele["phonnumber"]) {
                  ele["phonnumber"] =
                    prefixPlusIcon(
                      ele[directorFieldNames.PHONENUMBER],
                      countryCode
                    ) || "";
                }
              });
            }

            setDirectorData(values["directorInfo"]);

            if (index === -1) {
              ToastMessage(
                "It is mandatrory to mark at least one director as primary!",
                "error"
              );
            } else {
              setActiveStep(activeStep + 1);
              setDashboardStepNo(activeStep + 1);
            }
          } else {
            setActiveStep(activeStep + 1);
            setDashboardStepNo(activeStep + 1);
          }

          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="review-application">
              <h3>Review Business Information</h3>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Business Sector</label>
                    <Select
                      closeMenuOnSelect={true}
                      onBlur={() => {
                        setReviewBusinessData(values);
                      }}
                      isLoading={loadingBusinessSectorList}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          fieldNames.BUSINESSSECTOR,
                          selectedOption
                        );
                      }}
                      name={fieldNames.BUSINESSSECTOR}
                      options={businessSectorList}
                      placeholder="Enter business sector"
                      styles={{
                        control: (styles, state) => {
                          const borderColor =
                            !state.hasValue &&
                            touched[fieldNames.BUSINESSSECTOR] &&
                            errors[fieldNames.BUSINESSSECTOR]
                              ? "red"
                              : "#ced4da";

                          return { ...styles, borderColor };
                        },
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={values[fieldNames.BUSINESSSECTOR]}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group business-date">
                    <label>Business Start Date</label>

                    <DatePicker
                      selected={
                        values[fieldNames.BUSINESSSTARTDATE] &&
                        moment(values[fieldNames.BUSINESSSTARTDATE]).isValid()
                          ? new Date(values[fieldNames.BUSINESSSTARTDATE])
                          : null
                      }
                      customInput={
                        <MaskedInput
                          mask="11/11/1111"
                          placeholder={
                            currentCountry === "GB"
                              ? "DD/MM/YYYY"
                              : "MM/DD/YYYY"
                          }
                        />
                      }
                      className={clsx(
                        "form-control ",
                        {
                          "is-invalid":
                            touched[fieldNames.BUSINESSSTARTDATE] &&
                            errors[fieldNames.BUSINESSSTARTDATE],
                        },
                        {
                          "is-valid":
                            touched[fieldNames.BUSINESSSTARTDATE] &&
                            !errors[fieldNames.BUSINESSSTARTDATE],
                        }
                      )}
                      dateFormat={
                        currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                      }
                      placeholderText={
                        currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                      }
                      onCalendarClose={() => {
                        setReviewBusinessData(values);
                      }}
                      onChange={(value) => {
                        if (value !== null) {
                          let fieldValue = moment(value).isValid()
                            ? moment(value).format(
                                currentCountry !== "GB"
                                  ? "MM/DD/YYYY"
                                  : "DD/MM/YYYY"
                              )
                            : value;
                          setFieldValue(
                            fieldNames.BUSINESSSTARTDATE,
                            fieldValue
                          );
                        }
                        // setFieldValue(
                        //   fieldNames.BUSINESSSTARTDATE,
                        //   date !== null
                        //     ? moment(date).utc().format("YYYY-MM-DD")
                        //     : null
                        // );
                      }}
                    />
                    {/* <input
                      type="date"
                      placeholder="Enter business start date"
                      name={fieldNames.BUSINESSSTARTDATE}
                      className={clsx(
                        "form-control ",
                        {
                          "is-invalid":
                            touched[fieldNames.BUSINESSSTARTDATE] &&
                            errors[fieldNames.BUSINESSSTARTDATE],
                        },
                        {
                          "is-valid":
                            touched[fieldNames.BUSINESSSTARTDATE] &&
                            !errors[fieldNames.BUSINESSSTARTDATE],
                        }
                      )}
                      onChange={handleChange}
                      onBlur={() => {
                        setReviewBusinessData(values);
                      }}
                      value={values[fieldNames.BUSINESSSTARTDATE]}
                    /> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Monthly Card Takings</label>

                    <div className="input-group mb-3">
                      <CurrencyIcon />
                      <input
                        type="text"
                        placeholder="90,000"
                        name={fieldNames.CARDPAYMENTAMOUNT}
                        className={clsx(
                          "form-control ",
                          {
                            "is-invalid":
                              touched[fieldNames.CARDPAYMENTAMOUNT] &&
                              errors[fieldNames.CARDPAYMENTAMOUNT],
                          },
                          {
                            "is-valid":
                              touched[fieldNames.CARDPAYMENTAMOUNT] &&
                              !errors[fieldNames.CARDPAYMENTAMOUNT],
                          }
                        )}
                        onChange={(e) => {
                          if (e.target.value) {
                            if (numberRegex.test(removeComma(e.target.value))) {
                              setFieldValue(
                                fieldNames.CARDPAYMENTAMOUNT,
                                e.target.value
                              );
                            } else {
                              setFieldValue(
                                fieldNames.CARDPAYMENTAMOUNT,
                                values[fieldNames.CARDPAYMENTAMOUNT]
                              );
                            }
                          } else {
                            setFieldValue(fieldNames.CARDPAYMENTAMOUNT, "");
                          }
                        }}
                        value={formatNumberInput(
                          values[fieldNames.CARDPAYMENTAMOUNT]
                        )}
                        onBlur={() => {
                          setReviewBusinessData(values);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Business Invoiced</label>
                    <div className="input-group mb-3">
                      <CurrencyIcon />
                      <input
                        type="text"
                        placeholder="Business Invoiced"
                        name={fieldNames.SUPPLIERDUEAMOUNT}
                        className={clsx(
                          "form-control ",
                          {
                            "is-invalid":
                              touched[fieldNames.SUPPLIERDUEAMOUNT] &&
                              errors[fieldNames.SUPPLIERDUEAMOUNT],
                          },
                          {
                            "is-valid":
                              touched[fieldNames.SUPPLIERDUEAMOUNT] &&
                              !errors[fieldNames.SUPPLIERDUEAMOUNT],
                          }
                        )}
                        min="0"
                        onChange={(e) => {
                          if (e.target.value) {
                            if (numberRegex.test(removeComma(e.target.value))) {
                              setFieldValue(
                                fieldNames.SUPPLIERDUEAMOUNT,
                                e.target.value
                              );
                            } else {
                              setFieldValue(
                                fieldNames.SUPPLIERDUEAMOUNT,
                                values[fieldNames.SUPPLIERDUEAMOUNT]
                              );
                            }
                          } else {
                            setFieldValue(fieldNames.SUPPLIERDUEAMOUNT, "");
                          }
                        }}
                        value={formatNumberInput(
                          values[fieldNames.SUPPLIERDUEAMOUNT]
                        )}
                        onBlur={() => {
                          setReviewBusinessData(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {currentCountry === "GB" && (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Business Legal Number</label>

                      <input
                        type="text"
                        placeholder="Business Legal Number"
                        name={fieldNames.BUSINESSLEGALNUMBER}
                        className={clsx(
                          "form-control ",
                          {
                            "is-invalid":
                              touched[fieldNames.BUSINESSLEGALNUMBER] &&
                              errors[fieldNames.BUSINESSLEGALNUMBER],
                          },
                          {
                            "is-valid":
                              touched[fieldNames.BUSINESSLEGALNUMBER] &&
                              !errors[fieldNames.BUSINESSLEGALNUMBER],
                          }
                        )}
                        min="0"
                        onChange={(e) => {
                          if (e.target.value) {
                            if (numberRegex.test(e.target.value)) {
                              setFieldValue(
                                fieldNames.BUSINESSLEGALNUMBER,
                                e.target.value
                              );
                            } else {
                              setFieldValue(
                                fieldNames.BUSINESSLEGALNUMBER,
                                values[fieldNames.BUSINESSLEGALNUMBER]
                              );
                            }
                          } else {
                            setFieldValue(fieldNames.BUSINESSLEGALNUMBER, "");
                          }
                        }}
                        onBlur={() => {
                          setReviewBusinessData(values);
                        }}
                        value={values[fieldNames.BUSINESSLEGALNUMBER]}
                      />
                      {errors[fieldNames.BUSINESSLEGALNUMBER] &&
                      touched[fieldNames.BUSINESSLEGALNUMBER] ? (
                        <div className="invalid-feedback">
                          {errors[fieldNames.BUSINESSLEGALNUMBER]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>

              {
                <div className="row">
                  <div className="col-md-12">
                    <div className="director-panel">
                      {values.directorInfo &&
                        values.directorInfo.length > 0 && (
                          <h4>
                            Directors of{" "}
                            {leadDetails && leadDetails["lf_business_name"]}
                          </h4>
                        )}

                      {
                        <FieldArray
                          name={fieldNames.DIRECTORINFO}
                          render={(arrayHelpers) => (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary bg-app-primary"
                                onClick={() => {
                                  if (
                                    values.directorInfo &&
                                    values.directorInfo.length > 0
                                  ) {
                                    arrayHelpers.insert(
                                      values.directorInfo.length + 1,
                                      initialDirectorObject
                                    );
                                  } else {
                                    initialDirectorObject[
                                      directorFieldNames.ISPRIMARY
                                    ] = true;
                                    arrayHelpers.insert(
                                      0,
                                      initialDirectorObject
                                    );
                                  }
                                  setDirectorData(values["directorInfo"]);
                                }}
                              >
                                Add Director
                              </button>
                              {values.directorInfo &&
                                values.directorInfo.length > 0 &&
                                values.directorInfo.map((item, index) => (
                                  <Accordion
                                    title={
                                      item[directorFieldNames.FIRSTNAME] +
                                      " " +
                                      (item[directorFieldNames.LASTNAME]
                                        ? item[directorFieldNames.LASTNAME]
                                        : "")
                                    }
                                    key={index}
                                    isPrimary={
                                      item[directorFieldNames.ISPRIMARY]
                                    }
                                    id={`accordian${index}`}
                                  >
                                    <DirectorInfo
                                      key={values.directorInfo}
                                      stateList={stateList}
                                      stateListLoading={stateListLoading}
                                      values={values}
                                      handleChange={handleChange}
                                      errors={errors}
                                      initialDirectorObject={
                                        initialDirectorObject
                                      }
                                      setFieldValue={setFieldValue}
                                      touched={touched}
                                      index={index}
                                      item={item}
                                    />
                                  </Accordion>
                                ))}
                            </>
                          )}
                        />
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn"
                onClick={() => {
                  setActiveStep(activeStep - 1);
                  setDashboardStepNo(activeStep - 1);
                }}
                style={{ backgroundColor: "#E2E2E2" }}
                type="button"
              >
                {" "}
                <i className="bi bi-chevron-left"></i>Back
              </button>
              <button
                className="btn btn-primary"
                style={{ backgroundColor: "#006090" }}
                type="submit"
              >
                Next <i className="bi bi-chevron-right"></i>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ReviewBusinessInformation;
