import React from "react";
import Datatables from "./Datatables";
import Parser from "html-react-parser";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function BillCreditNotes(props) {
  const columns = [
    {
      name: "Supplier",
      selector: (row) => Parser(row.supplierRef),
      sortable: true,
    },
    {
      name: "Number",
      selector: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="button-tooltip-2">{row.billCreditNoteNumber}</Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              ref={ref}
              {...triggerHandler}
              className="ml-2 cursor-pointer ellipsis-text"
            >
              {row.billCreditNoteNumber}{" "}
            </div>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      name: "Issue Date",
      selector: (row) => row.issueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
    },
    {
      name: "Remaining Credit",
      selector: (row) => row.remainingCredit,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => Parser(row.status.replace('class="', 'className="')),
      sortable: true,
    },
  ];

  const finalUrl = `${props.endUrl}/CODAT/Bill_Credit_Notes/${props.leadId}`;

  return (
    <Datatables apiUrl={finalUrl} apiColumn={columns} title={props.title} />
  );
}
