import { combineReducers } from "redux";
import userDetailsReducer from "./userDetailsReducer";
import leadDetailsReducer from "./leadDetailsReducer";
import reviewApplicationDetailsReducer from "./reviewApplicationDetailsReducer";
import countryReducer from "./countryReducer";
import creditScoreReducer from "./creditScoreReducer";

export const rootReducer = combineReducers({
  userDetailsReducer,
  leadDetailsReducer,
  reviewApplicationDetailsReducer,
  countryReducer,
  creditScoreReducer,
});
