import { TRIGGER_CREDIT_SCORE } from "../actions/actionTypes";

const initialState = {
  creditScore: "",
};

const creditScoreReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_CREDIT_SCORE:
      return {
        ...state,
        creditScore: action.creditScore,
      };
    default:
      return state;
  }
};

export default creditScoreReducer;
