import { Formik } from "formik";
import React, { useState } from "react";
import clsx from "clsx";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { RegionDropdown } from "react-country-region-selector";

import {
  businessEntityList,
  checkCompanyType,
  fieldNames,
  loadOptions,
  loadPurposeList,
  selectBusinessEntityList,
  validationSchema,
} from "../../requestaquote/components/application-information";
import { getBusinessStateList, SEARCH_COMPANY_URL } from "../../../request";
import axios from "axios";
import * as Yup from "yup";
import { useEffect } from "react";
import {
  getReviewAppData,
  setDashboardStepNo,
  setReviewAppData,
} from "../dashboard";
import { formatNumberInput, numberRegex, removeComma } from "../../Constants";
import CurrencyIcon from "../../CurrencyIcon";
import { useAppSelector } from "../../../redux/hooks/hooks";

function ReviewApplicationInformation({ data, setActiveStep, activeStep }) {
  const storedData = getReviewAppData();
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  const validationSchema = Yup.object().shape({
    [fieldNames.AMOUNT]: Yup.string().required(),
    [fieldNames.REQUIREDFUND]: Yup.string().required(),
    [fieldNames.LOANPURPOSE]: Yup.string().required(),
    [fieldNames.BUSINESSENTITY]: Yup.string().required(),
    [fieldNames.BUSINESSNAME]: Yup.string().required(),
    country: Yup.string(),
    [fieldNames.STATE]: Yup.string().when("country", {
      is: (value) => {
        return value == "GB" ? false : true;
      },
      then: Yup.string().required(),
    }),
  });
  const initialValues = {
    [fieldNames.AMOUNT]: storedData
      ? storedData["amount"]
      : data["lf_amount_required"],
    [fieldNames.REQUIREDFUND]: storedData
      ? storedData["requiredFund"]
      : data["lm_Funds"],
    [fieldNames.LOANPURPOSE]: storedData
      ? storedData["loanPurpose"]
      : loadPurposeList[
          loadPurposeList.findIndex(
            (item) => data.ApptxtLoanPurpose == item.value
          )
        ],
    [fieldNames.BUSINESSENTITY]:
      storedData && storedData["businessEntity"]
        ? storedData["businessEntity"]
        : selectBusinessEntityList(currentCountry)[
            selectBusinessEntityList(currentCountry).findIndex(
              (item) => data["lf_business_activity"] == item.value
            )
          ],
    [fieldNames.BUSINESSNAME]: storedData
      ? storedData["businessName"]
      : data["lf_business_name"],
    [fieldNames.STATE]: storedData
      ? storedData[fieldNames.STATE]
      : data["lf_business_entity"],
    country: currentCountry,
  };

  const [stateListLoading, setStateListLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    setStateListLoading(true);
    getBusinessStateList(currentCountry)
      .then((resp) => {
        setStateListLoading(false);
        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });

        setStateList(array);

        initialValues[fieldNames.STATE] =
          array[
            array.findIndex((item) => data["lf_business_entity"] == item.value)
          ];

        setReviewAppData(initialValues);
      })
      .catch((err) => {
        setStateListLoading(false);
      });
  }, [currentCountry]);

  useEffect(() => {
    if (!storedData) {
      setReviewAppData(initialValues);
    }
  }, []);

  return (
    <div className="dashboard-box position-relative card dashboard-card">
      <div className="review-application">
        <h3>Review Application information</h3>
        <Formik
          initialValues={initialValues || storedData}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setActiveStep(activeStep + 1);
            setDashboardStepNo(activeStep + 1);

            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,

            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Amount Required</label>
                    <div className="input-group mb-3">
                      <CurrencyIcon />
                      <input
                        type="text"
                        placeholder="90,000"
                        className={clsx(
                          "form-control ",
                          {
                            "is-invalid":
                              touched[fieldNames.AMOUNT] &&
                              errors[fieldNames.AMOUNT],
                          },
                          {
                            "is-valid":
                              touched[fieldNames.AMOUNT] &&
                              !errors[fieldNames.AMOUNT],
                          }
                        )}
                        name={fieldNames.AMOUNT}
                        onChange={(e) => {
                          if (e.target.value) {
                            if (numberRegex.test(removeComma(e.target.value))) {
                              setFieldValue(fieldNames.AMOUNT, e.target.value);
                            } else {
                              setFieldValue(
                                fieldNames.AMOUNT,
                                values[fieldNames.AMOUNT]
                              );
                            }
                          } else {
                            setFieldValue(fieldNames.AMOUNT, "");
                          }
                        }}
                        value={formatNumberInput(values[fieldNames.AMOUNT])}
                        onBlur={(e) => {
                          setReviewAppData(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Purpose of Loan</label>
                    <Select
                      closeMenuOnSelect={true}
                      onChange={(selectedOption) => {
                        setFieldValue(fieldNames.LOANPURPOSE, selectedOption);
                      }}
                      onBlur={(selectedOption) => {
                        setReviewAppData(values);
                      }}
                      options={loadPurposeList}
                      name={fieldNames.LOANPURPOSE}
                      placeholder="Enter purpose of loan"
                      styles={{
                        control: (styles, state) => {
                          const borderColor =
                            !state.hasValue &&
                            touched[fieldNames.LOANPURPOSE] &&
                            errors[fieldNames.LOANPURPOSE]
                              ? "red"
                              : "#ced4da";

                          return { ...styles, borderColor };
                        },
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={values[fieldNames.LOANPURPOSE]}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Term of Funds Required (Months)</label>
                    <input
                      type="text"
                      placeholder="12"
                      name={fieldNames.REQUIREDFUND}
                      onChange={(e) => {
                        if (e.target.value) {
                          if (numberRegex.test(removeComma(e.target.value))) {
                            setFieldValue(
                              fieldNames.REQUIREDFUND,
                              e.target.value
                            );
                          } else {
                            setFieldValue(
                              fieldNames.REQUIREDFUND,
                              values[fieldNames.REQUIREDFUND]
                            );
                          }
                        } else {
                          setFieldValue(fieldNames.REQUIREDFUND, "");
                        }
                      }}
                      value={formatNumberInput(values[fieldNames.REQUIREDFUND])}
                      onBlur={(e) => {
                        setReviewAppData(values);
                      }}
                      className={clsx(
                        "form-control ",
                        {
                          "is-invalid":
                            touched[fieldNames.REQUIREDFUND] &&
                            errors[fieldNames.REQUIREDFUND],
                        },
                        {
                          "is-valid":
                            touched[fieldNames.REQUIREDFUND] &&
                            !errors[fieldNames.REQUIREDFUND],
                        }
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group business-entity">
                    <label>Business Entity</label>
                    <Select
                      classNamePrefix={clsx(
                        "",
                        {
                          "is-invalid":
                            touched[fieldNames.BUSINESSENTITY] &&
                            errors[fieldNames.BUSINESSENTITY],
                        },
                        {
                          "is-valid":
                            touched[fieldNames.BUSINESSENTITY] &&
                            !errors[fieldNames.BUSINESSENTITY],
                        }
                      )}
                      closeMenuOnSelect={true}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          fieldNames.BUSINESSENTITY,
                          selectedOption
                        );
                      }}
                      onBlur={(selectedOption) => {
                        setReviewAppData(values);
                      }}
                      options={selectBusinessEntityList(currentCountry)}
                      name={fieldNames.BUSINESSENTITY}
                      placeholder="Select Business Entity"
                      styles={{
                        control: (styles, state) => {
                          const borderColor =
                            !state.hasValue &&
                            touched[fieldNames.BUSINESSENTITY] &&
                            errors[fieldNames.BUSINESSENTITY]
                              ? "red"
                              : "#ced4da";

                          return { ...styles, borderColor };
                        },
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={values[fieldNames.BUSINESSENTITY]}
                    />
                  </div>
                </div>
                {currentCountry != "GB" && (
                  <div className="col-md-4">
                    <div className="form-group business-entity">
                      <label>
                        {currentCountry == "GB"
                          ? "Business Region"
                          : currentCountry == "US"
                          ? "State"
                          : "Province"}
                      </label>
                      <Select
                        closeMenuOnSelect={true}
                        onBlur={() => {
                          setReviewAppData(values);
                        }}
                        onChange={(selectedOption) =>
                          setFieldValue(fieldNames.STATE, selectedOption)
                        }
                        isLoading={stateListLoading}
                        name={fieldNames.STATE}
                        options={stateList}
                        placeholder={
                          currentCountry == "GB"
                            ? "Business Region"
                            : currentCountry == "US"
                            ? "State"
                            : "Province"
                        }
                        styles={{
                          control: (styles, state) => {
                            const borderColor =
                              !state.hasValue &&
                              touched[fieldNames.STATE] &&
                              errors[fieldNames.STATE]
                                ? "red"
                                : "#ced4da";

                            return { ...styles, borderColor };
                          },
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={values[fieldNames.STATE]}
                      />
                      {/* <RegionDropdown
                        country={currentCountry}
                        countryValueType="short"
                        classes="form-control"
                        style={{
                          border:
                            touched[fieldNames.STATE] &&
                            errors[fieldNames.STATE]
                              ? "1px solid red"
                              : "",
                        }}
                        value={values[fieldNames.STATE]}
                        onChange={(val) => setFieldValue(fieldNames.STATE, val)}
                      /> */}
                    </div>
                  </div>
                )}

                <div className="col-md-4">
                  <div className="form-group business-entity">
                    <label>Business Name</label>

                    {checkCompanyType(
                      values[fieldNames.BUSINESSENTITY],
                      currentCountry
                    ) ? (
                      <AsyncSelect
                        closeMenuOnSelect={true}
                        value={{ label: values[fieldNames.BUSINESSNAME] }}
                        name={fieldNames.BUSINESSNAME}
                        loadOptions={loadOptions}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            fieldNames.BUSINESSNAME,
                            selectedOption.value
                          );
                          // setBusinessInfo(selectedOption);
                          setReviewAppData(values);
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                        // onInputChange={handleInputChange}
                        onBlur={(selectedOption) => {
                          setReviewAppData(values);
                        }}
                        placeholder="Select Business Name"
                        styles={{
                          control: (styles, state) => {
                            const borderColor =
                              !state.hasValue &&
                              touched[fieldNames.BUSINESSNAME] &&
                              errors[fieldNames.BUSINESSNAME]
                                ? "red"
                                : "#ced4da";

                            return { ...styles, borderColor };
                          },
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Enter Business Name"
                        name={fieldNames.BUSINESSNAME}
                        onChange={handleChange}
                        onBlur={(selectedOption) => {
                          setReviewAppData(values);
                        }}
                        value={values[fieldNames.BUSINESSNAME]}
                        className={clsx(
                          "form-control ",
                          {
                            "is-invalid":
                              touched[fieldNames.BUSINESSNAME] &&
                              errors[fieldNames.BUSINESSNAME],
                          },
                          {
                            "is-valid":
                              touched[fieldNames.BUSINESSNAME] &&
                              !errors[fieldNames.BUSINESSNAME],
                          }
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#006090" }}
                  type="submit"
                >
                  Next <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ReviewApplicationInformation;
