import clsx from "clsx";
import React, { useEffect } from "react";
import { useState } from "react";

import {
  Collapse,
  Dropdown,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch } from "react-redux/es";
import { useLocation, useNavigate } from "react-router-dom/dist";
import {
  TRIGGER_CREDIT_SCORE,
  TRIGGER_CURRENT_COUNTRY,
  TRIGGER_LEAD_DETAILS,
  TRIGGER_USER_DETAILS,
} from "../../redux/actions/actionTypes";
import { useAppSelector } from "../../redux/hooks/hooks";
import { API_URL, getDashboardData, logout } from "../../request";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useAppSelector((state) => state.userDetailsReducer);
  const { leadDetails } = useAppSelector((state) => state.leadDetailsReducer);
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const [dasboardData, setDashboardData] = useState();
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(userDetails);

  const dispatch = useDispatch();
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  useEffect(() => {
    setDetails(userDetails);
  }, [userDetails]);

  const logoutUser = () => {
    logout();
    dispatch({
      type: TRIGGER_LEAD_DETAILS,
      leadDetails: {},
    });

    dispatch({
      type: TRIGGER_USER_DETAILS,
      userDetails: {},
    });

    dispatch({
      type: TRIGGER_CURRENT_COUNTRY,
      currentCountry: "",
    });

    dispatch({
      type: TRIGGER_CREDIT_SCORE,
      creditScore: "",
    });
    navigate("/login");
  };

  const getData = () => {
    if (userDetails && userDetails.lead_id) {
      getDashboardData(userDetails.lead_id, currentCountry)
        .then((resp) => {
          setDashboardData(resp.records[0]);
        })
        .catch((err) => {
          setDashboardData({});
        });
    }
  };

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const dropdownMenu = () => {
    return (
      <Dropdown className="user-dropdown">
        <Dropdown.Toggle variant="link" id="dropdown-basic-user" align="start">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip-2">
                {details["first_name"] + " " + details["last_name"]}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className="cursor-pointer company-name"
                onClick={() => {
                  if (location.pathname != "/dashboard") {
                    navigate("/merchant-health");
                  }
                }}
                ref={ref}
                {...triggerHandler}
              >
                {details["profile_pic"] ? (
                  <img
                    src={`${API_URL}${details["profile_pic"]}`}
                    alt=""
                    className="user-img"
                    style={{
                      marginRight:
                        windowDimenion.winWidth < "576" ? "unset" : "",
                    }}
                  />
                ) : (
                  <div className="initial-avatar mt-2">
                    <div className="initial-avatar-text">
                      {details && details["first_name"][0].toUpperCase()}
                      {details && details["last_name"][0].toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
            )}
          </OverlayTrigger>

          {windowDimenion.winWidth > "576" && details["first_name"]}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {location.pathname != "/dashboard" && (
            <Dropdown.Item eventKey="1">
              {" "}
              <a
                className="dropdown-item cursor-pointer"
                // href="#"
                onClick={() => {
                  navigate("/change-password");
                }}
              >
                Change Password
              </a>
            </Dropdown.Item>
          )}
          <Dropdown.Item eventKey="2">
            {" "}
            <a
              className="dropdown-item"
              // href="#"
              onClick={() => logoutUser()}
            >
              Logout
            </a>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  useEffect(() => {
    getData();

    return () => {};
  }, []);

  return (
    <header>
      <Navbar expand="sm" bg="dark" variant="dark" className="py-0">
        <div class="container-fluid">
          <Navbar.Brand className="d-flex align-items-center py-0 mr-0">
            {" "}
            <span className="logo-icon">
              {" "}
              <img
                src={require("../../images/logo-icon.png")}
                alt="logo"
                className="logo-dashboard center"
              />
            </span>
            {/* {windowDimenion.winWidth >= "400" && ( */}
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  {dasboardData && dasboardData["lf_business_name"]}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className="cursor-pointer company-name"
                  onClick={() => {
                    if (location.pathname != "/dashboard") {
                      navigate("/merchant-health");
                    }
                  }}
                  ref={ref}
                  {...triggerHandler}
                >
                  {leadDetails && leadDetails["lf_business_name"]}
                </div>
              )}
            </OverlayTrigger>
            {/* )} */}
          </Navbar.Brand>

          {windowDimenion.winWidth < "576" && dropdownMenu()}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse
            className={clsx("align-items-center", {
              "resp-nav": windowDimenion.winWidth < "576",
            })}
          >
            <Nav className="me-auto align-items-center responsive-navbar">
              {windowDimenion.winWidth >= "576" && (
                <>
                  {" "}
                  <Nav.Link>
                    <a className="nav-link border-divider mt-0"></a>
                  </Nav.Link>
                  <div
                    className="nav-item business-btn cursor-pointer"
                    onClick={() => navigate("/merchant-health")}
                  >
                    <a
                      className="nav-link cursor-pointer"
                      onClick={() => navigate("/merchant-health")}
                    >
                      My Business{" "}
                    </a>
                  </div>
                </>
              )}
              {/* </Nav.Link> */}
            </Nav>

            <Nav className="dropdown-nav ">
              {/* <Flag className="mt-1" code={currentCountry} height="25" /> */}

              {windowDimenion.winWidth >= "576" && dropdownMenu()}
            </Nav>

            <Nav
              className="me-auto px-3 my-2 my-lg-0 resp-nav"
              style={{ display: windowDimenion.winWidth > "576" ? "none" : "" }}
            >
              {windowDimenion.winWidth < "576" && (
                <>
                  <Nav.Link>
                    <a
                      className="nav-link cursor-pointer pl-2 my-business-label"
                      onClick={() => navigate("/merchant-health")}
                    >
                      My Business{" "}
                    </a>
                  </Nav.Link>

                  <Nav.Link
                    className={clsx("pl-2", {
                      "bg-active": location.pathname == "/merchant-health",
                    })}
                  >
                    <a
                      className="nav-link cursor-pointer d-flex align-items-center"
                      onClick={() => navigate("/merchant-health")}
                    >
                      <div className="icon-div">
                        <i className="fa fa-id-card" aria-hidden="true"></i>{" "}
                      </div>
                      Financial Health Insights{" "}
                    </a>
                  </Nav.Link>
                  <Nav.Link
                    className={clsx("pl-2", {
                      "bg-active":
                        location.pathname == "/application-information",
                    })}
                  >
                    <a
                      className="nav-link cursor-pointer d-flex align-items-center"
                      onClick={() => navigate("/application-information")}
                    >
                      <div className="icon-div">
                        <i className="fas fa-laptop" aria-hidden="true"></i>{" "}
                      </div>
                      Application Information
                    </a>
                  </Nav.Link>
                  <Nav.Link
                    className={clsx("pl-2", {
                      "bg-active": location.pathname == "/business-information",
                    })}
                  >
                    {" "}
                    <a
                      className="nav-link cursor-pointer d-flex align-items-center"
                      onClick={() => navigate("/business-information")}
                    >
                      <div className="icon-div">
                        <i className="fas fa-user-tie" aria-hidden="true"></i>
                      </div>
                      Business Information
                    </a>
                  </Nav.Link>
                  <Nav.Link
                    className={clsx("pl-2", {
                      "bg-active": location.pathname == "/personal-details",
                    })}
                  >
                    {" "}
                    <a
                      className="nav-link cursor-pointer d-flex align-items-center"
                      onClick={() => navigate("/personal-details")}
                    >
                      <div className="icon-div">
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </div>
                      Personal Details
                    </a>
                  </Nav.Link>

                  <Nav.Link
                    className={clsx("pl-2", {
                      "bg-active":
                        location.pathname == "/upload-files" ||
                        location.pathname == "/view-files",
                    })}
                  >
                    {" "}
                    <a
                      onClick={() => setOpen(!open)}
                      className={"nav-link cursor-pointer"}
                    >
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="icon-div">
                            <i className="fa fa-file" aria-hidden="true"></i>
                          </div>
                          Files
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center pr-1">
                            <i
                              className={clsx("fa fa-chevron-down transition", {
                                "rotate-arrow": open,
                              })}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Nav.Link>
                  <Collapse in={open}>
                    <div id="example-collapse-text" className="pl-4 mt-1">
                      <Nav.Link
                        className={clsx("pl-2 mt-1", {
                          "bg-active": location.pathname == "/upload-files",
                        })}
                      >
                        {" "}
                        <a
                          className="nav-link cursor-pointer"
                          onClick={() => navigate("/upload-files")}
                        >
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                          ></i>{" "}
                          Upload Files
                        </a>
                      </Nav.Link>
                      <Nav.Link
                        className={clsx("pl-2", {
                          "bg-active": location.pathname == "/view-files",
                        })}
                      >
                        {" "}
                        <a
                          className="nav-link cursor-pointer"
                          onClick={() => navigate("/view-files")}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i> View
                          Files
                        </a>
                      </Nav.Link>
                    </div>
                  </Collapse>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
}

export default Header;
