import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fieldNames } from "./application-information";
import clsx from "clsx";
import * as Yup from "yup";
import { setStepNo } from "./request-leftpanel";
import { formatNumberInput, numberRegex, removeComma } from "../../Constants";
import CurrencyIcon from "../../CurrencyIcon";
import { useAppSelector } from "../../../redux/hooks/hooks";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

import { getBusinessSectorList } from "../../../request";
import MaskedInput from "react-maskedinput";

const businessSectorListUsCanada = [
  { label: "Accounting/Bookkeeping", value: "49028" },
  { label: "Apparel", value: "49029" },
  { label: "Auto Repair", value: "49030" },
  { label: "Auto Sales", value: "49031" },
  { label: "Childcare", value: "49032" },
  { label: "Construction", value: "49033" },
  { label: "Delivery", value: "49034" },
  { label: "Direct Sales", value: "49035" },
  { label: "Dry Cleaning", value: "49036" },
  { label: "Electronic", value: "49037" },
  { label: "Financial Services", value: "49038" },
  { label: "Flea Market", value: "49039" },
  { label: "Flower", value: "49040" },
  { label: "Food Cater", value: "49041" },
  { label: "Food Mobile", value: "49042" },
  { label: "Food Restaurant", value: "49043" },
  { label: "Gas Station", value: "49044" },
  { label: "Grocery", value: "49045" },
  { label: "Hair Salon", value: "49046" },
  { label: "Healthcare", value: "49047" },
  { label: "Home Care/Senior", value: "49048" },
  { label: "Home Repair Services", value: "49049" },
  { label: "Janitorial", value: "49050" },
  { label: "Landscaper", value: "49051" },
  { label: "Liquor Store", value: "49052" },
  { label: "Mini Market", value: "49053" },
  { label: "Party & Events", value: "49054" },
  { label: "Pet", value: "49055" },
  { label: "Printing and Publishing", value: "49056" },
  { label: "Professional Services", value: "49057" },
  { label: "Real Estate", value: "49058" },
  { label: "Retail- Clothing", value: "49059" },
  { label: "Retail- Electronics", value: "49060" },
  { label: "Retail- Furniture", value: "49061" },
  { label: "Retail- Other", value: "49062" },
  { label: "Taxi Limo", value: "49063" },
  { label: "Towing", value: "49064" },
  { label: "Transportation- Other", value: "49065" },
  { label: "Trucking/Long Haul", value: "49066" },
  { label: "Web Info Service", value: "49067" },
  { label: "Other", value: "49068" },
];

// export const selectBusinessSectorList = (currentCountry) => {
//   let sectorList = [];
//   switch (currentCountry) {
//     case "US":
//       sectorList = businessSectorListUsCanada;
//       break;
//     case "CA":
//       sectorList = businessSectorListUsCanada;
//       break;
//     case "GB":
//       sectorList = businessSectorList;
//       break;
//   }
//   return sectorList;
// };

export const setCompanyInfo = (data) => {
  localStorage.setItem("companyInfo", JSON.stringify(data));
};

export const getCompanyInfo = (data) => {
  return JSON.parse(localStorage.getItem("companyInfo"));
};

export const setBusinessInfo = (info) => {
  localStorage.setItem("businessInfo", JSON.stringify(info));
};

export const getBusinessInfo = () => {
  return JSON.parse(localStorage.getItem("businessInfo"));
};

function BusinessInformation({ setStep, showSelectedState }) {
  const storedData = getBusinessInfo();
  const businessInfo = getCompanyInfo();
  const [businessSectorList, selectBusinessSectorList] = useState([]);
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  const validationSchema = Yup.object().shape({
    [fieldNames.CARDPAYMENTAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSTARTDATE]: Yup.string().required(),
    [fieldNames.ISPAYMENTPENDING]: Yup.boolean().required(),
    [fieldNames.SUPPLIERDUEAMOUNT]: Yup.string().when(
      fieldNames.ISPAYMENTPENDING,
      {
        is: true,
        then: Yup.string().required(),
      }
    ),

    [fieldNames.CARDPAYMENTAMOUNT]: Yup.string().when(
      fieldNames.ISPAYMENTPROCESSED,
      {
        is: true,
        then: Yup.string().required(),
      }
    ),

    [fieldNames.ISPAYMENTPROCESSED]: Yup.boolean().required(),
    [fieldNames.BUSINESSSECTOR]: Yup.string().required(),
  });
  const initialValues = {
    [fieldNames.CARDPAYMENTAMOUNT]: storedData
      ? storedData[fieldNames.CARDPAYMENTAMOUNT]
      : "",
    [fieldNames.BUSINESSSTARTDATE]:
      storedData && !businessInfo
        ? storedData[fieldNames.BUSINESSSTARTDATE]
        : businessInfo
        ? currentCountry == "GB"
          ? businessInfo["date_of_creation"]
          : moment(businessInfo["created_at"]).format("YYYY-MM-DD")
        : "",
    [fieldNames.ISPAYMENTPENDING]: storedData
      ? storedData[fieldNames.ISPAYMENTPENDING]
      : false,
    [fieldNames.ISPAYMENTPROCESSED]: storedData
      ? storedData[fieldNames.ISPAYMENTPROCESSED]
      : false,
    [fieldNames.SUPPLIERDUEAMOUNT]: storedData
      ? storedData[fieldNames.SUPPLIERDUEAMOUNT]
      : "",
    [fieldNames.BUSINESSSECTOR]: storedData
      ? storedData[fieldNames.BUSINESSSECTOR]
      : "",
  };

  const [loadingBusinessSectorList, setLoadingBusinessSectorList] =
    useState(false);

  useEffect(() => {
    setLoadingBusinessSectorList(true);
    getBusinessSectorList(currentCountry)
      .then((resp) => {
        setLoadingBusinessSectorList(false);

        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });
        selectBusinessSectorList(array);
      })
      .catch((err) => {
        setLoadingBusinessSectorList(false);
      });
  }, [currentCountry]);

  const goBack = () => {
    setStep(1);
    showSelectedState(1);
    setStepNo(1);
  };

  return (
    <div className="right-panel">
      <h2>Business Information</h2>
      <h5>Please enter the details below</h5>
      <Formik
        initialValues={initialValues}
        enableReinitialize={
          initialValues || storedData || currentCountry || businessInfo
        }
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setStep(3);
          showSelectedState(3);
          setStepNo(3);
          setBusinessInfo(values);
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group purpose-loan">
              <label>Business Sector</label>

              <Select
                closeMenuOnSelect={true}
                onBlur={() => {
                  setBusinessInfo(values);
                }}
                onChange={(selectedOption) =>
                  setFieldValue(fieldNames.BUSINESSSECTOR, selectedOption)
                }
                isLoading={loadingBusinessSectorList}
                name={fieldNames.BUSINESSSECTOR}
                options={businessSectorList}
                placeholder="Enter business sector"
                styles={{
                  control: (styles, state) => {
                    const borderColor =
                      !state.hasValue &&
                      touched[fieldNames.BUSINESSSECTOR] &&
                      errors[fieldNames.BUSINESSSECTOR]
                        ? "red"
                        : "#ced4da";

                    return { ...styles, borderColor };
                  },
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={values[fieldNames.BUSINESSSECTOR]}
              />
            </div>
            <div className="form-group business-date">
              <label>Business Start Date</label>
              <DatePicker
                customInput={
                  <MaskedInput
                    mask="11/11/1111"
                    placeholder={
                      currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                    }
                  />
                }
                selected={
                  values[fieldNames.BUSINESSSTARTDATE] &&
                  moment(values[fieldNames.BUSINESSSTARTDATE]).isValid()
                    ? new Date(values[fieldNames.BUSINESSSTARTDATE])
                    : null
                }
                className="form-control"
                dateFormat={
                  currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                }
                placeholderText={
                  currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                }
                onCalendarClose={() => {
                  setBusinessInfo(values);
                }}
                onChange={(value) => {
                  if (value !== null) {
                    let fieldValue = moment(value).isValid()
                      ? new Date(value)
                      : value;
                    // this.setState({ value: fieldValue });
                    setFieldValue(fieldNames.BUSINESSSTARTDATE, fieldValue);
                  }
                }}
              />
              {/* <input
                type="date"
                name={fieldNames.BUSINESSSTARTDATE}
                placeholder="MM-DD-YYYY"
                className={clsx(
                  "form-control ",
                  {
                    "is-invalid":
                      touched[fieldNames.BUSINESSSTARTDATE] &&
                      errors[fieldNames.BUSINESSSTARTDATE],
                  },
                  {
                    "is-valid":
                      touched[fieldNames.BUSINESSSTARTDATE] &&
                      !errors[fieldNames.BUSINESSSTARTDATE],
                  }
                )}
                onChange={handleChange}
                onBlur={() => {
                  setBusinessInfo(values);
                }}
                value={values[fieldNames.BUSINESSSTARTDATE]}
              /> */}
            </div>

            <div className="form-group card-payment">
              <label>Any card payments processed?</label>
              <div className="form-check form-switch">
                <div className="yes-checkbox">
                  <input
                    type="checkbox"
                    id="switch"
                    name={fieldNames.ISPAYMENTPROCESSED}
                    onChange={handleChange}
                    onClick={(e) => {
                      setFieldValue(
                        fieldNames.ISPAYMENTPROCESSED,
                        e.target.checked
                      );
                      let obj = { ...values };
                      obj[fieldNames.ISPAYMENTPROCESSED] = e.target.checked;
                      setBusinessInfo(obj);
                    }}
                    checked={values[fieldNames.ISPAYMENTPROCESSED]}
                  />
                  <label
                    for="switch"
                    className={clsx(
                      "form-control ",
                      {
                        "is-invalid":
                          touched[fieldNames.ISPAYMENTPROCESSED] &&
                          errors[fieldNames.ISPAYMENTPROCESSED],
                      },
                      {
                        "is-valid":
                          touched[fieldNames.ISPAYMENTPROCESSED] &&
                          !errors[fieldNames.ISPAYMENTPROCESSED],
                      }
                    )}
                  ></label>
                </div>
              </div>
            </div>

            {values[fieldNames.ISPAYMENTPROCESSED] && (
              <div className="form-group">
                <label>Monthly Card Payments Amount</label>

                <div className="input-group mb-3">
                  <CurrencyIcon />
                  <input
                    type="text"
                    min={0}
                    placeholder="Monthly Card Payments Amount"
                    name={fieldNames.CARDPAYMENTAMOUNT}
                    className={clsx(
                      "form-control ",
                      {
                        "is-invalid":
                          touched[fieldNames.CARDPAYMENTAMOUNT] &&
                          errors[fieldNames.CARDPAYMENTAMOUNT],
                      },
                      {
                        "is-valid":
                          touched[fieldNames.CARDPAYMENTAMOUNT] &&
                          !errors[fieldNames.CARDPAYMENTAMOUNT],
                      }
                    )}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (numberRegex.test(removeComma(e.target.value))) {
                          setFieldValue(
                            fieldNames.CARDPAYMENTAMOUNT,
                            e.target.value
                          );
                        } else {
                          setFieldValue(
                            fieldNames.CARDPAYMENTAMOUNT,
                            values[fieldNames.CARDPAYMENTAMOUNT]
                          );
                        }
                      } else {
                        setFieldValue(fieldNames.CARDPAYMENTAMOUNT, "");
                      }
                    }}
                    onBlur={() => {
                      setBusinessInfo(values);
                    }}
                    value={formatNumberInput(
                      values[fieldNames.CARDPAYMENTAMOUNT]
                    )}
                  />
                </div>
              </div>
            )}

            <div className="form-group card-payment">
              <label>Any pending supplier payments due to you?</label>
              <div className="form-check form-switch">
                <div className="yes-checkbox">
                  <input
                    type="checkbox"
                    id="switch3"
                    name={fieldNames.ISPAYMENTPENDING}
                    onChange={handleChange}
                    checked={values[fieldNames.ISPAYMENTPENDING]}
                    onClick={(e) => {
                      let obj = { ...values };
                      obj[fieldNames.ISPAYMENTPENDING] = e.target.checked;
                      setBusinessInfo(obj);
                    }}
                  />
                  <label
                    for="switch3"
                    className={clsx(
                      "form-control ",
                      {
                        "is-invalid":
                          touched[fieldNames.ISPAYMENTPENDING] &&
                          errors[fieldNames.ISPAYMENTPENDING],
                      },
                      {
                        "is-valid":
                          touched[fieldNames.ISPAYMENTPENDING] &&
                          !errors[fieldNames.ISPAYMENTPENDING],
                      }
                    )}
                  ></label>
                </div>
              </div>
            </div>

            {values[fieldNames.ISPAYMENTPENDING] && (
              <div className="form-group">
                <label>Approx Amount due to you from supplier</label>
                <div className="input-group mb-3">
                  <CurrencyIcon />
                  <input
                    type="text"
                    min={0}
                    placeholder="Approx Amount due to you from supplier"
                    name={fieldNames.SUPPLIERDUEAMOUNT}
                    className={clsx(
                      "form-control ",
                      {
                        "is-invalid":
                          touched[fieldNames.SUPPLIERDUEAMOUNT] &&
                          errors[fieldNames.SUPPLIERDUEAMOUNT],
                      },
                      {
                        "is-valid":
                          touched[fieldNames.SUPPLIERDUEAMOUNT] &&
                          !errors[fieldNames.SUPPLIERDUEAMOUNT],
                      }
                    )}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (numberRegex.test(removeComma(e.target.value))) {
                          setFieldValue(
                            fieldNames.SUPPLIERDUEAMOUNT,
                            e.target.value
                          );
                        } else {
                          setFieldValue(
                            fieldNames.SUPPLIERDUEAMOUNT,
                            values[fieldNames.SUPPLIERDUEAMOUNT]
                          );
                        }
                      } else {
                        setFieldValue(fieldNames.SUPPLIERDUEAMOUNT, "");
                      }
                    }}
                    onBlur={() => {
                      setBusinessInfo(values);
                    }}
                    value={formatNumberInput(
                      values[fieldNames.SUPPLIERDUEAMOUNT]
                    )}
                  />
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between w-100">
              <button
                className="btn btn-primary back-btn"
                onClick={() => goBack()}
                type="button"
              >
                <i className="fa fa-chevron-left"></i> Back{" "}
              </button>
              <button className="btn btn-primary next-btn" type="submit">
                Next <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default BusinessInformation;
