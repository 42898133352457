import React from "react";
import {
  diff_years,
  directorFieldNames,
  formatNumberInput,
  limitLivingSince,
  numberRegex,
  removeComma,
  residentialStatusList,
} from "../Constants";
import { fieldNames } from "../requestaquote/components/application-information";
import clsx from "clsx";
import Select from "react-select";
import { RegionDropdown } from "react-country-region-selector";
import { useAppSelector } from "../../redux/hooks/hooks";
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";
import { FieldArray } from "formik";
import DatePicker from "react-datepicker";
import MaskedInput from "react-maskedinput";

export default function DirectorInfo({
  values,
  handleChange,
  item,
  index,
  setFieldValue,
  initialPreviousAddressObj,
  isTouched,
  isAddedPrevAddress,
  setIsAddedPrevAddress,
  errors,
  touched,
  generateDirectorListPayload,
  setAreTruthy,
  stateList,
  stateListLoading,
}) {
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  return (
    <div className="director-field">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="checkbox"
              className="primary-checkbox"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ISPRIMARY}`}
              onChange={(e) => {
                values.directorInfo.forEach((item, i) => {
                  if (index == i) {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ISPRIMARY}`,
                      e.target.checked
                    );
                  } else {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${i}.${directorFieldNames.ISPRIMARY}`,
                      false
                    );
                  }
                });
              }}
              checked={item[directorFieldNames.ISPRIMARY]}
            />
            <label className="set-primary">Set as Primary</label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.FIRSTNAME}`}
              onChange={handleChange}
              value={item[directorFieldNames.FIRSTNAME]}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LASTNAME}`}
              onChange={handleChange}
              value={item[directorFieldNames.LASTNAME]}
            />
          </div>
        </div>
        {currentCountry !== "GB" && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Job Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Job Title"
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.JOBTITLE}`}
                onChange={handleChange}
                value={item[directorFieldNames.JOBTITLE]}
              />
            </div>
          </div>
        )}

        <div className="col-md-3">
          <div className="form-group">
            <label>Nature of Control</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nature of Control"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.NATUREOFCONTROL}`}
              onChange={handleChange}
              value={item[directorFieldNames.NATUREOFCONTROL]}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>% of Total Share Count</label>
            <input
              type="text"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`}
              onChange={(e) => {
                if (e.target.value) {
                  if (numberRegex.test(removeComma(e.target.value))) {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`,
                      e.target.value
                    );
                  } else {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`,
                      item[directorFieldNames.KINDOFSHAREHOLDER]
                    );
                  }
                } else {
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.KINDOFSHAREHOLDER}`,
                    ""
                  );
                }
              }}
              value={formatNumberInput(
                item[directorFieldNames.KINDOFSHAREHOLDER]
              )}
              className="form-control"
              placeholder="% of Total Share Count"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Email Address"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.EMAILID}`}
              onChange={handleChange}
              value={item[directorFieldNames.EMAILID]}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group" >
            <label>Phone</label>

            <PhoneInput
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PHONENUMBER}`}
              country={currentCountry.toLowerCase()}
              value={item[directorFieldNames.PHONENUMBER]}
              inputStyle={
                touched[directorFieldNames.PHONENUMBER] &&
                errors[directorFieldNames.PHONENUMBER] && {
                  borderColor: "red",
                }
              }
              onChange={(phone) => {
                setFieldValue(
                  `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PHONENUMBER}`,
                  phone
                );
              }}
              inputClass={"w-100"}
              containerClass={`${index}-phone-input`}
              placeholder="Enter Phone Number"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Date of Birth</label>
            <DatePicker
              customInput={
                <MaskedInput
                  mask="11/11/1111"
                  placeholder={
                    currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                  }
                />
              }
              selected={
                moment(
                  item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT]
                ).isValid()
                  ? new Date(item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT])
                  : null
              }
              className="form-control"
              dateFormat={currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
              placeholderText={
                currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
              }
              onChange={(value) => {
                if (value !== null) {
                  let fieldValue = moment(value).isValid()
                    ? new Date(value)
                    : null;
                  // this.setState({ value: fieldValue });
                  setFieldValue(
                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.SHAREHOLDERDOBFULLFORMAT}`,
                    fieldValue
                  );
                }
                // setFieldValue(
                //   `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.SHAREHOLDERDOBFULLFORMAT}`,
                //   date !== null ? moment(date).utc().format("YYYY-MM-DD") : null
                // );
              }}
            />
            {/* <input
              type="date"
              className="form-control"
              placeholder="04/11/2022"
              max={moment().format("YYYY-MM-DD")}
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.SHAREHOLDERDOBFULLFORMAT}`}
              onChange={handleChange}
              value={item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT]}
            /> */}
          </div>
        </div>
        {currentCountry === "GB" && (
          <div className="col-md-3">
            <div className="form-group">
              <label>
                {currentCountry === "GB"
                  ? "Post Code"
                  : currentCountry === "CA"
                  ? "Postal Code"
                  : "Postal Code"}
              </label>
              <input
                type="number"
                className="form-control"
                placeholder={
                  currentCountry === "GB"
                    ? "Post Code"
                    : currentCountry === "CA"
                    ? "Postal Code"
                    : "Zip Code"
                }
                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.POSTALCODE}`}
                onChange={handleChange}
                value={item[directorFieldNames.POSTALCODE]}
              />
            </div>
          </div>
        )}

        <div className="col-md-3" hidden>
          <div className="form-group">
            <label>Choose Address</label>

            <select
              className="form-select form-control"
              aria-label="Default select example"
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.CHOOSEADDRESS}`}
              onChange={handleChange}
              value={item[directorFieldNames.CHOOSEADDRESS]}
            >
              <option selected disabled>
                Choose Address
              </option>
            </select>
          </div>
        </div>
      </div>
      <input
        hidden
        type="text"
        name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE1}`}
        onChange={handleChange}
        value={item[directorFieldNames.ADDRESSLINE1]}
        className="form-control"
        placeholder="% of Total Share Count"
      />
      <input
        type="text"
        name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE2}`}
        onChange={handleChange}
        hidden
        value={item[directorFieldNames.ADDRESSLINE2]}
        className="form-control"
        placeholder="% of Total Share Count"
      />
      <input
        type="text"
        name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.HIDDENSHAREHOLDERID}`}
        onChange={handleChange}
        hidden
        value={item[directorFieldNames.HIDDENSHAREHOLDERID]}
        className="form-control"
        placeholder="% of Total Share Count"
      />
      <div className="row">
        {currentCountry == "GB" ? (
          <>
            {" "}
            <div className="col-md-3">
              <div className="form-group">
                <label>House Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="House Number"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.HOUSE_NUMBER}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.HOUSE_NUMBER]}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>House Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="House Name"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.HOUSE_NAME}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.HOUSE_NAME]}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Street</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.STREET}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.STREET]}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>County</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="County"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.COUNTY}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.COUNTY]}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Town</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Town"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.TOWN}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.TOWN]}
                />
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className=" ">
                <label>Residential Status</label>

                <Select
                  closeMenuOnSelect={true}
                  classNamePrefix="mySelect"
                  onChange={(selectedOption) =>
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`,
                      selectedOption
                    )
                  }
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`}
                  options={residentialStatusList}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  placeholder="Select Residential Status"
                  styles={{
                    control: (styles, state) => {
                      const borderColor =
                        !state.hasValue &&
                        touched[directorFieldNames.RESIDENTIALSTATUS] &&
                        errors[directorFieldNames.RESIDENTIALSTATUS]
                          ? "red"
                          : "#ced4da";

                      return {
                        ...styles,
                        borderColor,
                      };
                    },
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                    }),
                    input: (provided, state) => ({
                      ...provided,
                    }),
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={item[directorFieldNames.RESIDENTIALSTATUS]}
                />
              </div>
            </div> */}
          </>
        ) : (
          <>
            <div className="col-md-3">
              <div className="form-group">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address line 1"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE1}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.ADDRESSLINE1]}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address line 2"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.ADDRESSLINE2}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.ADDRESSLINE2]}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.CITY}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.CITY]}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                {/* <label>State/Province</label> */}
                <label>
                  {currentCountry == "GB"
                    ? "Business Region"
                    : currentCountry == "US"
                    ? "State"
                    : "Province"}
                </label>
                <Select
                  closeMenuOnSelect={true}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(selectedOption) =>
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.STATE}`,
                      selectedOption
                    )
                  }
                  isLoading={stateListLoading}
                  isSearchable={false}
                  name={fieldNames.STATE}
                  options={stateList}
                  placeholder={
                    currentCountry == "GB"
                      ? "Business Region"
                      : currentCountry == "US"
                      ? "State"
                      : "Province"
                  }
                  styles={{
                    control: (styles, state) => {
                      const borderColor =
                        !state.hasValue &&
                        touched[fieldNames.STATE] &&
                        errors[fieldNames.STATE]
                          ? "red"
                          : "#ced4da";

                      return { ...styles, borderColor };
                    },
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={item[fieldNames.STATE]}
                />

                {/* <RegionDropdown
                  country={currentCountry}
                  countryValueType="short"
                  classes="form-control"
                  value={item[fieldNames.STATE]}
                  onChange={(val) => {
                    setFieldValue(
                      `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.STATE}`,
                      val
                    );
                  }}
                /> */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>
                  {currentCountry === "GB"
                    ? "Post Code"
                    : currentCountry === "CA"
                    ? "Postal Code"
                    : "Zip Code"}
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder={
                    currentCountry === "GB"
                      ? "Post Code"
                      : currentCountry === "CA"
                      ? "Postal Code"
                      : "Zip Code"
                  }
                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.POSTALCODE}`}
                  onChange={handleChange}
                  value={item[directorFieldNames.POSTALCODE]}
                />
              </div>
            </div>
          </>
        )}
        <div className="col-md-3">
          <div className=" ">
            <label>Residential Status</label>

            <Select
              closeMenuOnSelect={true}
              classNamePrefix="mySelect"
              onChange={(selectedOption) =>
                setFieldValue(
                  `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`,
                  selectedOption
                )
              }
              isSearchable={false}
              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.RESIDENTIALSTATUS}`}
              options={residentialStatusList}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              placeholder="Select Residential Status"
              styles={{
                control: (styles, state) => {
                  const borderColor =
                    !state.hasValue &&
                    touched[directorFieldNames.RESIDENTIALSTATUS] &&
                    errors[directorFieldNames.RESIDENTIALSTATUS]
                      ? "red"
                      : "#ced4da";

                  return {
                    ...styles,
                    borderColor,
                  };
                },
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                }),
                input: (provided, state) => ({
                  ...provided,
                }),
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={item[directorFieldNames.RESIDENTIALSTATUS]}
            />
          </div>
        </div>

        <FieldArray
          name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}`}
          render={(arrayHelpers) => {
            return (
              <>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Living Since</label>
                    <DatePicker
                      customInput={
                        <MaskedInput
                          mask="11/11/1111"
                          placeholder={
                            currentCountry === "GB"
                              ? "DD/MM/YYYY"
                              : "MM/DD/YYYY"
                          }
                        />
                      }
                      selected={
                        moment(item[directorFieldNames.LIVINGSINCE]).isValid()
                          ? new Date(item[directorFieldNames.LIVINGSINCE])
                          : null
                      }
                      className={clsx(
                        "form-control ",
                        {
                          "is-invalid":
                            touched[fieldNames.LIVINGSINCE] &&
                            errors[fieldNames.LIVINGSINCE],
                        },
                        {
                          "is-valid":
                            touched[fieldNames.LIVINGSINCE] &&
                            !errors[fieldNames.LIVINGSINCE],
                        }
                      )}
                      dateFormat={
                        currentCountry === "GB" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                      }
                      placeholderText={
                        currentCountry === "GB" ? "DD/MM/YYYY" : "MM/DD/YYYY"
                      }
                      onChange={(value) => {
                        if (value !== null) {
                          let fieldValue = moment(value).isValid()
                            ? new Date(value)
                            : value;
                          // this.setState({ value: fieldValue });
                          setFieldValue(
                            `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                            fieldValue
                          );
                        }
                        // setFieldValue(
                        //   `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                        //   date !== null
                        //     ? moment(date).format("YYYY-MM-DD")
                        //     : null
                        // );
                      }}
                      onCalendarClose={() => {
                        var a = moment().toDate();
                        var b = moment(
                          item[directorFieldNames.LIVINGSINCE]
                        ).toDate();

                        a.setHours(0);
                        a.setMinutes(0);
                        a.setSeconds(0);
                        a.setMilliseconds(0);
                        b.setHours(0);
                        b.setMinutes(0);
                        b.setSeconds(0);
                        b.setMilliseconds(0);

                        if (
                          Math.round(moment(b).diff(moment(a), "years", true)) <
                          3
                        ) {
                          if (
                            values.directorInfo.length &&
                            values.directorInfo[index][
                              directorFieldNames.PREVIOUSADDRESS
                            ].length == 0
                          ) {
                            if (item[directorFieldNames.ISPRIMARY]) {
                              initialPreviousAddressObj[
                                directorFieldNames.PREVIOUSADDSHAREHOLDERID
                              ] = index + 1;

                              arrayHelpers.insert(0, initialPreviousAddressObj);
                              setIsAddedPrevAddress(true);
                            }
                          }
                        } else {
                          setFieldValue(
                            `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}`,
                            []
                          );
                        }
                      }}
                    />
                    {/* <input
                      type="date"
                      className="form-control"
                      placeholder="Living Since"
                      max={moment().format("YYYY-MM-DD")}
                      name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`}
                      onBlur={(e) => {
                        if (
                          diff_years(new Date(), new Date(e.target.value)) < 3
                        ) {
                          if (
                            values.directorInfo.length &&
                            values.directorInfo[index][
                              directorFieldNames.PREVIOUSADDRESS
                            ].length == 0
                          ) {
                            if (item[directorFieldNames.ISPRIMARY]) {
                              initialPreviousAddressObj[
                                directorFieldNames.PREVIOUSADDSHAREHOLDERID
                              ] = index + 1;

                              arrayHelpers.insert(0, initialPreviousAddressObj);
                              setIsAddedPrevAddress(true);
                            }
                          }
                        } else {
                          setFieldValue(
                            `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}`,
                            []
                          );
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue(
                          `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.LIVINGSINCE}`,
                          e.target.value
                        );
                      }}
                      value={item[directorFieldNames.LIVINGSINCE]}
                    /> */}
                  </div>
                </div>
                {values.directorInfo &&
                  values.directorInfo.length > 0 &&
                  values.directorInfo[index][
                    directorFieldNames.PREVIOUSADDRESS
                  ] &&
                  values.directorInfo[index][directorFieldNames.PREVIOUSADDRESS]
                    .length > 0 &&
                  values.directorInfo[index][
                    directorFieldNames.PREVIOUSADDRESS
                  ].map((item1, i) => {
                    return (
                      <>
                        <div className="col-md-12" key={i}>
                          <hr />
                        </div>

                        <input
                          type="text"
                          name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.PREVIOUSADDSHAREHOLDERID}`}
                          onChange={handleChange}
                          hidden
                          value={
                            item1[directorFieldNames.PREVIOUSADDSHAREHOLDERID]
                          }
                          className="form-control"
                          placeholder="% of Total Share Count"
                        />

                        {currentCountry === "GB" && (
                          <>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>House Number</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.HOUSENUMBER] ==
                                        "",
                                  })}
                                  placeholder="House Number"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.HOUSENUMBER}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.HOUSENUMBER]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>House Name</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.HOUSENAME] == "",
                                  })}
                                  placeholder="House Name"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.HOUSENAME}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.HOUSENAME]}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Street</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.ADDRESS] == "",
                                  })}
                                  placeholder="Street"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.ADDRESS}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.ADDRESS]}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {currentCountry !== "GB" && (
                          <>
                            <div className="col-md-3" hidden>
                              <div className="form-group">
                                <label>House Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="House Number"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.HOUSE_NUMBER}`}
                                  onChange={handleChange}
                                  value={item[directorFieldNames.HOUSE_NUMBER]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3" hidden>
                              <div className="form-group">
                                <label>House Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="House Name"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.HOUSE_NAME}`}
                                  onChange={handleChange}
                                  value={item[directorFieldNames.HOUSE_NAME]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3" hidden>
                              <div className="form-group">
                                <label>Street</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Street"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.STREET}`}
                                  onChange={handleChange}
                                  value={item[directorFieldNames.STREET]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3" hidden>
                              <div className="form-group">
                                <label>County</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="County"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.COUNTY}`}
                                  onChange={handleChange}
                                  value={item[directorFieldNames.COUNTY]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3" hidden>
                              <div className="form-group">
                                <label>Town</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Town"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.TOWN}`}
                                  onChange={handleChange}
                                  value={item[directorFieldNames.TOWN]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Address Line 1</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.ADDRESSLINE1] ==
                                        "",
                                  })}
                                  placeholder="Address line 1"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.ADDRESSLINE1}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.ADDRESSLINE1]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Address Line 2</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.ADDRESSLINE2] ==
                                        "",
                                  })}
                                  placeholder="Address line 2"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.ADDRESSLINE2}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.ADDRESSLINE2]}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>City</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.CITY] == "",
                                  })}
                                  placeholder="City"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.CITY}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.CITY]}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-md-3 d-flex align-items-center">
                          <span
                            onClick={() => {
                              arrayHelpers.remove(i);
                              setAreTruthy(false);
                            }}
                            className="cursor-pointer text-danger"
                          >
                            Remove
                          </span>
                        </div>
                        {currentCountry !== "GB" && (
                          <div className="col-md-3">
                            <div className="form-group">
                              {/* <label>State/Province</label> */}
                              <label>
                                {currentCountry == "GB"
                                  ? "Business Region"
                                  : currentCountry == "US"
                                  ? "State"
                                  : "Province"}
                              </label>
                              <Select
                                closeMenuOnSelect={true}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.STATE}`,
                                    selectedOption
                                  )
                                }
                                isSearchable={false}
                                isLoading={stateListLoading}
                                name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${fieldNames.STATE}`}
                                options={stateList}
                                placeholder={
                                  currentCountry == "GB"
                                    ? "Business Region"
                                    : currentCountry == "US"
                                    ? "State"
                                    : "Province"
                                }
                                styles={{
                                  control: (styles, state) => {
                                    const borderColor =
                                      item1[fieldNames.STATE] == "" &&
                                      isAddedPrevAddress &&
                                      isTouched
                                        ? "red"
                                        : "#ced4da";

                                    return { ...styles, borderColor };
                                  },
                                }}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                value={item1[fieldNames.STATE]}
                              />
                            </div>
                          </div>
                        )}

                        {currentCountry == "GB" && (
                          <>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>County</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.COUNTY] == "",
                                  })}
                                  placeholder="County"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.COUNTY}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.COUNTY]}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Town</label>
                                <input
                                  type="text"
                                  className={clsx("form-control ", {
                                    "is-invalid":
                                      isAddedPrevAddress &&
                                      isTouched &&
                                      item1[directorFieldNames.TOWN] == "",
                                  })}
                                  placeholder="Town"
                                  name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.TOWN}`}
                                  onChange={handleChange}
                                  value={item1[directorFieldNames.TOWN]}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {currentCountry === "GB"
                                ? "Post Code"
                                : currentCountry === "CA"
                                ? "Postal Code"
                                : "Zip Code"}
                            </label>
                            <input
                              type="text"
                              className={clsx("form-control ", {
                                "is-invalid":
                                  isAddedPrevAddress &&
                                  isTouched &&
                                  item1[directorFieldNames.POSTCODE] == "",
                              })}
                              placeholder={
                                currentCountry === "GB"
                                  ? "Post Code"
                                  : currentCountry === "CA"
                                  ? "Postal Code"
                                  : "Zip Code"
                              }
                              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.POSTCODE}`}
                              onChange={handleChange}
                              onBlur={handleChange}
                              value={item1[directorFieldNames.POSTCODE]}
                            />
                          </div>
                        </div>

                        <button
                          type="button"
                          id="hidden-btn"
                          hidden
                          onClick={() => {
                            let payload = {
                              ...values,
                            };

                            let prevAddress = generateDirectorListPayload(
                              payload["directorInfo"]
                            ).prevAddress;

                            if (
                              prevAddress.length &&
                              diff_years(
                                new Date(),
                                new Date(
                                  prevAddress[prevAddress.length - 1][
                                    "livingSince"
                                  ]
                                )
                              ) < 3
                            ) {
                              initialPreviousAddressObj[
                                directorFieldNames.PREVIOUSADDSHAREHOLDERID
                              ] = index + 1;
                              arrayHelpers.insert(
                                prevAddress.length + 1,
                                initialPreviousAddressObj
                              );
                            }
                          }}
                        >
                          click
                        </button>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Previous Living Since
                              {i + 1}
                            </label>
                            <DatePicker
                              customInput={
                                <MaskedInput
                                  mask="11/11/1111"
                                  placeholder={
                                    currentCountry === "GB"
                                      ? "DD/MM/YYYY"
                                      : "MM/DD/YYYY"
                                  }
                                />
                              }
                              selected={
                                item1[directorFieldNames.WHENTOMOVETOADDRESS]
                                  ? new Date(
                                      item1[
                                        directorFieldNames.WHENTOMOVETOADDRESS
                                      ]
                                    )
                                  : null
                              }
                              className={clsx("form-control ", {
                                "is-invalid":
                                  isAddedPrevAddress &&
                                  isTouched &&
                                  item1[
                                    directorFieldNames.WHENTOMOVETOADDRESS
                                  ] == "",
                              })}
                              dateFormat={
                                currentCountry === "GB"
                                  ? "dd/MM/yyyy"
                                  : "MM/dd/yyyy"
                              }
                              placeholderText={
                                currentCountry === "GB"
                                  ? "DD/MM/YYYY"
                                  : "MM/DD/YYYY"
                              }
                              onChange={(value) => {
                                if (value !== null) {
                                  let fieldValue = moment(value).isValid()
                                    ? moment(value).format(
                                        currentCountry !== "GB"
                                          ? "MM/DD/YYYY"
                                          : "DD/MM/YYYY"
                                      )
                                    : value;
                                  setFieldValue(
                                    `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.WHENTOMOVETOADDRESS}`,
                                    fieldValue
                                  );
                                }
                                // setFieldValue(
                                //   `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.WHENTOMOVETOADDRESS}`,
                                //   date !== null
                                //     ? moment(date).format("YYYY-MM-DD")
                                //     : null
                                // );
                              }}
                              onCalendarClose={() => {
                                var a = moment().toDate();
                                var b = moment(
                                  item1[directorFieldNames.WHENTOMOVETOADDRESS]
                                ).toDate();

                                a.setHours(0);
                                a.setMinutes(0);
                                a.setSeconds(0);
                                a.setMilliseconds(0);
                                b.setHours(0);
                                b.setMinutes(0);
                                b.setSeconds(0);
                                b.setMilliseconds(0);

                                if (
                                  Math.round(
                                    moment(b).diff(moment(a), "years", true)
                                  ) < 3
                                ) {
                                  if (
                                    values.directorInfo[index][
                                      directorFieldNames.PREVIOUSADDRESS
                                    ].length != 0 &&
                                    values.directorInfo[index][
                                      directorFieldNames.PREVIOUSADDRESS
                                    ].length >= 1
                                  ) {
                                    if (
                                      item[directorFieldNames.ISPRIMARY] &&
                                      !values.directorInfo[index][
                                        directorFieldNames.PREVIOUSADDRESS
                                      ][i + 1]
                                    ) {
                                      arrayHelpers.remove(i + 1);
                                      initialPreviousAddressObj[
                                        directorFieldNames.PREVIOUSADDSHAREHOLDERID
                                      ] = index + 1;

                                      arrayHelpers.insert(
                                        i + 1,
                                        initialPreviousAddressObj
                                      );

                                      setIsAddedPrevAddress(true);
                                    }
                                  } else {
                                    arrayHelpers.remove(i + 1);
                                  }
                                } else {
                                  values.directorInfo[index][
                                    directorFieldNames.PREVIOUSADDRESS
                                  ].forEach((el, elIndex) => {
                                    if (i != elIndex && elIndex > i) {
                                      arrayHelpers.pop(elIndex);
                                    }
                                  });
                                }
                              }}
                              maxDate={
                                new Date(
                                  limitLivingSince(
                                    i,
                                    item[directorFieldNames.LIVINGSINCE],
                                    values.directorInfo[index][
                                      directorFieldNames.PREVIOUSADDRESS
                                    ]
                                  )
                                )
                              }
                            />
                            {/* <input
                              type="date"
                              className={clsx("form-control ", {
                                "is-invalid":
                                  isAddedPrevAddress &&
                                  isTouched &&
                                  item1[
                                    directorFieldNames.WHENTOMOVETOADDRESS
                                  ] == "",
                              })}
                              placeholder="Previous
                                                                  Living Since 1"
                              name={`${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.WHENTOMOVETOADDRESS}`}
                              onBlur={(e) => {
                                if (
                                  diff_years(
                                    new Date(),
                                    new Date(e.target.value)
                                  ) < 3
                                ) {
                                  if (
                                    values.directorInfo[index][
                                      directorFieldNames.PREVIOUSADDRESS
                                    ].length != 0 &&
                                    values.directorInfo[index][
                                      directorFieldNames.PREVIOUSADDRESS
                                    ].length >= 1
                                  ) {
                                    if (
                                      item[directorFieldNames.ISPRIMARY] &&
                                      !values.directorInfo[index][
                                        directorFieldNames.PREVIOUSADDRESS
                                      ][i + 1]
                                    ) {
                                      arrayHelpers.remove(i + 1);
                                      initialPreviousAddressObj[
                                        directorFieldNames.PREVIOUSADDSHAREHOLDERID
                                      ] = index + 1;

                                      arrayHelpers.insert(
                                        i + 1,
                                        initialPreviousAddressObj
                                      );

                                      setIsAddedPrevAddress(true);
                                    }
                                  } else {
                                    arrayHelpers.remove(i + 1);
                                  }
                                } else {
                                  values.directorInfo[index][
                                    directorFieldNames.PREVIOUSADDRESS
                                  ].forEach((el, elIndex) => {
                                    if (i != elIndex && elIndex > i) {
                                      arrayHelpers.pop(elIndex);
                                    }
                                  });
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  `${fieldNames.DIRECTORINFO}.${index}.${directorFieldNames.PREVIOUSADDRESS}.${i}.${directorFieldNames.WHENTOMOVETOADDRESS}`,
                                  e.target.value
                                );
                              }}
                              max={limitLivingSince(
                                i,
                                item[directorFieldNames.LIVINGSINCE],
                                values.directorInfo[index][
                                  directorFieldNames.PREVIOUSADDRESS
                                ]
                              )}
                              value={
                                item1[directorFieldNames.WHENTOMOVETOADDRESS]
                              }
                            /> */}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
