import React, { useState } from "react";
import Header from "../header/header";
import StickyBox from "react-sticky-box";
import SiderBarMenu from "../merchant-dashboard/component/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useAppSelector } from "../../redux/hooks/hooks";
import { sendInvite } from "../../request";
import { ToastMessage } from "../ToastMessage";
import { getUserDetails } from "../login/loginpage";
import { useLocation, useParams } from "react-router";

export default function InviteUser() {
  const [loading, setLoading] = useState(false);
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const location = useLocation();
  const { id } = useParams();
  const userDetails = getUserDetails();

  const validationSchema = Yup.object().shape({
    receiver_email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    role: Yup.string().required("Password is required"),
    invitation_message: Yup.string().required("Password is required"),
  });

  const initialValues = {
    sender_email: userDetails.email || location.state.id,
    role: "admin" || location.state.role,
    invitation_message: "" || location.state.invitation_message,
    receiver_email: "" || location.state.receiver_email,
    lead_id: userDetails.lead_id,
    status: "Pending" || location.state.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const payload = { ...values };
      if (id) {
        payload[id] = id;
      }

      sendInvite(payload, currentCountry)
        .then((resp) => {
          if (resp.data.status == "success") {
            setLoading(false);
            ToastMessage(
              !id
                ? "Invitation sent successsfully!"
                : "User details updated successsfully!",
              "success"
            );
            setLoading(false);
            formik.resetForm();
          } else {
            ToastMessage(resp.data.message_text, "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          ToastMessage("Something went wrong!", "error");
        });
    },
  });
  return (
    <div className="dashboard-panel">
      <Header />
      <div className="dashboard-body bg-change-color">
        <div className="container-fluid  merchant-body">
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <StickyBox>
              <SiderBarMenu />
            </StickyBox>
            <div className="right-panel-main">
              <h3>
                <i className="fa fa-envelope" aria-hidden="true"></i> Invite
                User
              </h3>
              <div className="dashboard-box position-relative card dashboard-card">
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group col-xl-5 col-md-6 col-lg-6 col-sm-8 col-12">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="Enter email address"
                        placeholder="Enter email address"
                        {...formik.getFieldProps("receiver_email")}
                        className={clsx(
                          "form-control form-control-solid",
                          {
                            "is-invalid":
                              formik.touched.receiver_email &&
                              formik.errors.receiver_email,
                          },
                          {
                            "is-valid":
                              formik.touched.receiver_email &&
                              !formik.errors.receiver_email,
                          }
                        )}
                      />
                    </div>
                    <div className="form-group col-xl-4 col-md-5 col-lg-4 col-sm-6 col-12">
                      <label>Access Type</label>
                      <select
                        {...formik.getFieldProps("role")}
                        className={clsx(
                          "form-control form-control-solid",
                          {
                            "is-invalid":
                              formik.touched.role && formik.errors.role,
                          },
                          {
                            "is-valid":
                              formik.touched.role && !formik.errors.role,
                          }
                        )}
                      >
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                      </select>
                    </div>
                    <div className="form-group col-xl-5 col-md-6 col-lg-6 col-sm-8 col-12">
                      <label>Message</label>
                      <textarea
                        {...formik.getFieldProps("invitation_message")}
                        rows={5}
                        className={clsx(
                          "form-control form-control-solid ",
                          {
                            "is-invalid":
                              formik.touched.invitation_message &&
                              formik.errors.invitation_message,
                          },
                          {
                            "is-valid":
                              formik.touched.invitation_message &&
                              !formik.errors.invitation_message,
                          }
                        )}
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                      ></textarea>
                    </div>

                    <div className="form-group col-xl-5 col-md-6 col-lg-6 col-sm-12 col-12">
                      <button
                        className="btn btn-primary login-btn mt-3"
                        type="submit"
                        disabled={loading}
                      >
                        Send Invite <i className="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
