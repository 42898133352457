import React, { useState, useEffect } from "react";
import "../../styles/master.css";
import Header from "../header/header";
import SiderBarMenu from "./component/sidebar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Codat from "../Codat";
import {
  checkAccountingStatus,
  checkBankingStatus,
  getAccountScore,
  getCompanyID,
  getDashboardData,
  getLinkToAccountingData,
  getBankingFinancialServices,
  getBankingIncome,
  getRegularOutgoings,
  getEventFeed,
  bankingInsightsDownloadFile,
  API_URL,
  CODAT_BASE_URL,
} from "../../request";
import { getUserDetails } from "../login/loginpage";
import { ToastMessage } from "../ToastMessage";
import Loaderspinner from "../loader";
import BusinessCreditScore from "./merachant-health/business-credit-score";
import NotFound from "../NotFound";
import { useDispatch } from "react-redux/es";
import { TRIGGER_LEAD_DETAILS } from "../../redux/actions/actionTypes";
import {
  dateSuffix,
  formatNumberInput,
  monthArray,
  weekDayArray,
} from "../Constants";
import moment from "moment/moment";
import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "../../redux/hooks/hooks";

export const setCurrentTabIndex = (index) => {
  localStorage.setItem("activeTabIndex", index);
};

export const getCurrentTabIndex = () => {
  if (!localStorage.getItem("activeTabIndex")) {
    localStorage.setItem("activeTabIndex", 0);
  }
  return Number(localStorage.getItem("activeTabIndex"));
};

function MerchantHealth() {
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const currentTabIndex = getCurrentTabIndex();
  const [tabIndex, setTabIndex] = useState(currentTabIndex);
  const [open, setOpen] = React.useState(false);
  const [accoutingUrl, setAccoutingUrl] = useState("");
  const userDetails = getUserDetails();
  const [accountingStatus, setAccoutingStatus] = useState(false);
  const [dasboardData, setDashboardData] = useState();
  const [bankingUrl, setBankingUrl] = useState("");

  const [bankingStatus, setBankingStatus] = useState(false);

  const [loadingServices, setLoadingServices] = useState(false);
  const dispatch = useDispatch();
  const [loadingBanking, setLoadingBanking] = useState(true);
  const [loadingAccouting, setLoadingAccouting] = useState(true);
  const [financialServicesSummary, setFinancialServicesSummary] = useState([]);
  const [incomeAnalysisSummary, setIncomeAnalysisSummary] = useState([]);
  const [regularOutgoingsSummary, setRegularOutgoingsSummary] = useState([]);

  const [regularOutgoingTotalIn, setRegularOutgoingTotalIn] = useState(0);
  const [regularOutgoingTotalOut, setRegularOutgoingTotalOut] = useState(0);
  const [regularOutgoingMonthlyAvgIn, setRegularOutgoingMonthlyAvgIn] =
    useState(0);
  const [regularOutgoingMonthlyAvgOut, setRegularOutgoingMonthlyAvgOut] =
    useState(0);
  const [eventFeedSummary, setEventFeedSummary] = useState([]);

  const [eventCount, setEventCount] = useState(0);
  //Financial Services Aggregate
  const [financialServicesTotalIn, setFinancialServicesTotalIn] = useState(0);
  const [financialServicesTotalOut, setFinancialServicesTotalOut] = useState(0);
  const [financialServicesMonthlyAvgIn, setFinancialServicesMonthlyAvgIn] =
    useState(0);
  const [financialServicesMonthlyAvgOut, setFinancialServicesMonthlyAvgOut] =
    useState(0);

  //Income Analysis Aggregate
  const [incomeAnalysisTotalIn, setIncomeAnalysisTotalIn] = useState(0);
  const [incomeAnalysisTotalOut, setIncomeAnalysisTotalOut] = useState(0);
  const [incomeAnalysisMonthlyAvgIn, setIncomeAnalysisMonthlyAvgIn] =
    useState(0);
  const [incomeAnalysisMonthlyAvgOut, setIncomeAnalysisMonthlyAvgOut] =
    useState(0);
  const [downloadInProgress, setDownloadProgress] = useState(false);
  const lead_accountScore = userDetails["lead_id"];
  const handleOpen = () => {
    setOpen(!open);
  };



  useEffect(() => {
    setLoadingServices(true);
    if (userDetails && userDetails.lead_id && bankingStatus) {
      let promise1 = getBankingFinancialServices(lead_accountScore)
        .then((data) => {
          let summaries = data.response.data.summaries;
          setFinancialServicesSummary(summaries);
          let totalIn = 0;
          let totalOut = 0;
          let monthlyAvgIn = 0;
          let monthlyAvgOut = 0;

          summaries.map((summary) => {
            totalIn = Number(totalIn) + Number(summary.creditSummary.total);
            totalOut = Number(totalOut) + Number(summary.debitSummary.total);
            monthlyAvgIn =
              Number(monthlyAvgIn) +
              Number(summary.creditSummary.monthlyAverage);
            monthlyAvgOut =
              Number(monthlyAvgOut) +
              Number(summary.debitSummary.monthlyAverage);
          });

          setFinancialServicesTotalIn(totalIn);
          setFinancialServicesTotalOut(totalOut);
          setFinancialServicesMonthlyAvgIn(monthlyAvgIn);
          setFinancialServicesMonthlyAvgOut(monthlyAvgOut);
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.resolve();
        });

      let promise2 = getBankingIncome(lead_accountScore)
        .then((data) => {
          let summaries = data.response.data.summaries;
          setIncomeAnalysisSummary(data.response.data.summaries);
          let totalIn = 0;
          let totalOut = 0;
          let monthlyAvgIn = 0;
          let monthlyAvgOut = 0;

          summaries.map((summary) => {
            totalIn = Number(totalIn) + Number(summary.creditSummary.total);
            totalOut = Number(totalOut) + Number(summary.debitSummary.total);
            monthlyAvgIn =
              Number(monthlyAvgIn) +
              Number(summary.creditSummary.monthlyAverage);
            monthlyAvgOut =
              Number(monthlyAvgOut) +
              Number(summary.debitSummary.monthlyAverage);
          });

          setIncomeAnalysisTotalIn(totalIn);
          setIncomeAnalysisTotalOut(totalOut);
          setIncomeAnalysisMonthlyAvgIn(monthlyAvgIn);
          setIncomeAnalysisMonthlyAvgOut(monthlyAvgOut);
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.resolve();
        });

      let promise3 = getRegularOutgoings(lead_accountScore)
        .then((data) => {
          setRegularOutgoingsSummary(data.response.data.summaries);
          let summaries = data.response.data.summaries;

          let totalIn = 0;
          let totalOut = 0;
          let monthlyAvgIn = 0;
          let monthlyAvgOut = 0;

          summaries.map((summary) => {
            totalIn = Number(totalIn) + Number(summary.creditSummary.total);
            totalOut = Number(totalOut) + Number(summary.debitSummary.total);
            monthlyAvgIn =
              Number(monthlyAvgIn) +
              Number(summary.creditSummary.monthlyAverage);
            monthlyAvgOut =
              Number(monthlyAvgOut) +
              Number(summary.debitSummary.monthlyAverage);
          });

          setRegularOutgoingTotalIn(totalIn);
          setRegularOutgoingTotalOut(totalOut);
          setRegularOutgoingMonthlyAvgIn(monthlyAvgIn);
          setRegularOutgoingMonthlyAvgOut(monthlyAvgOut);
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.resolve();
        });

      let promise4 = getEventFeed(lead_accountScore)
        .then((data) => {
          let summaries = data.response.months;
          let arr = [];
          data.response.months.forEach((element) => {
            element.data.events.forEach((ele) => {
              arr.push(ele);
            });
          });
          setEventFeedSummary(arr);
          let evCount = 0;
          summaries.map((month) => {
            month.data.events.map((ev) => {
              evCount += 1;
            });
          });
          setEventCount(evCount);
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.resolve();
        });
      let combinedPromise = Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
      ]);
      combinedPromise.then((res) => {
        setLoadingServices(false);
      });
    }
  }, [bankingStatus]);

  const downloadFile = async (fileType) => {
    let response;
    setDownloadProgress(true);
    switch (fileType) {
      case "PDF_90": {
        response = await bankingInsightsDownloadFile(
          "90days",
          lead_accountScore
        )
          .then((data) => {
            let url = JSON.parse(data.response).Url;
            if (url) {
              window.open(url, "_blank");
            } else {
              alert("There is no data");
            }
            setDownloadProgress(false);
          })
          .catch((err) => {
            setDownloadProgress(false);
            alert(err);
          });
        break;
      }
      case "PDF_UW": {
        response = await bankingInsightsDownloadFile(
          "underwriters",
          lead_accountScore
        )
          .then((data) => {
            let url = JSON.parse(data.response).Url;
            if (url) {
              window.open(`${API_URL}${url}`, "_blank");
            } else {
              alert("There is no data");
            }
            setDownloadProgress(false);
          })
          .catch((err) => {
            setDownloadProgress(false);
            alert(err);
          });
        break;
      }
      case "PDF_RAW": {
        response = await bankingInsightsDownloadFile(
          "rawdata",
          lead_accountScore
        )
          .then((data) => {
            let url = JSON.parse(data.response).Url;
            if (url) {
              window.open(`${API_URL}${url}`, "_blank");
            } else {
              alert("There is no data");
            }
            setDownloadProgress(false);
          })
          .catch((err) => {
            setDownloadProgress(false);
            alert(err);
          });
        break;
      }

      case "PDF_FULL": {
        response = await bankingInsightsDownloadFile(
          "fulldata",
          lead_accountScore
        )
          .then((data) => {
            let url = JSON.parse(data.response).Url;
            if (url) {
              window.open(url, "_blank");
            } else {
              alert("There is no data");
            }
            setDownloadProgress(false);
          })
          .catch((err) => {
            setDownloadProgress(false);
            alert(err);
          });
        break;
      }

      case "CSV_ALL": {
        response = await bankingInsightsDownloadFile(
          "csvdata",
          lead_accountScore
        )
          .then((data) => {
            let url = JSON.parse(data.response).Url;
            if (url) {
              window.open(`${API_URL}${url}`, "_blank");
            } else {
              alert("There is no data");
            }
            setDownloadProgress(false);
          })
          .catch((err) => {
            setDownloadProgress(false);
            alert(err);
          });
        break;
      }
    }
  };

  const getData = () => {
    if (userDetails && userDetails.lead_id) {
      setLoadingBanking(true);
      getDashboardData(userDetails.lead_id,currentCountry).then((resp) => {
        setDashboardData(resp.records[0]);
        setLoadingBanking(false);
        dispatch({
          type: TRIGGER_LEAD_DETAILS,
          leadDetails: resp.records[0],
        });
      });
    }
  };

  const checkAccountingStatusClick = () => {
    setLoadingAccouting(true);

    checkAccountingStatus(userDetails["lead_id"])
      .then((resp) => {
        if (resp["message"] === "Status Updated to Linked") {
          setAccoutingStatus(true);
          setAccoutingUrl(resp.data.redirect);
          setLoadingAccouting(false);
        } else if (
          resp.status == "PendingAuth" ||
          resp["message"] === "Status Confirmed as Pending"
        ) {
          setAccoutingStatus(false);
          setLoadingAccouting(false);
        }
      })
      .catch((err) => {

        setAccoutingStatus(false);
        setLoadingAccouting(false);
        if (!accoutingUrl) {
          setLoadingAccouting(true);
          getLinkToAccouting();
        }
      });
  };

  const getLinkToAccouting = (isClicked) => {
    let payload = {
      lm_id: userDetails["lead_id"],
      name: dasboardData["lf_business_name"],
      platformType: "0",
    };

    getCompanyID(payload.lm_id).then((resp) => {
      if (resp["data"] && resp["data"]["codat_client_id"]) {
        setLoadingAccouting(false);
        setAccoutingUrl(`${CODAT_BASE_URL}${resp.data.codat_client_id}`);

        if (isClicked) {
          window.open(
            `${CODAT_BASE_URL}${resp.data.codat_client_id}`,
            "_blank"
          );
        }
      }

      if (!resp.data) {
        setLoadingAccouting(false);
        if (isClicked) {
          getLinkToAccountingData(payload)
            .then((resp) => {
              if (resp.success == "false" && resp.code == 500) {
              } else {
                setAccoutingUrl(resp.data.redirect);
                if (isClicked) {
                  window.open(resp.data.redirect, "_blank");
                }
                setLoadingAccouting(false);
              }
            })
            .catch((err) => {
              ToastMessage("Something went wrong!", "error");
              setLoadingAccouting(false);
            });
        }
      }
    });
  };

  const checkBankingStatusClick = () => {
    // response: "CompletedAddition";
    checkBankingStatus(userDetails["lead_id"])
      .then((resp) => {
        if (
          resp["response"] === "Completed" ||
          resp["response"] === "CompletedAddition" ||
          resp["response"] === "OpenBankingCancelled"
        ) {
          setBankingStatus(true);
          setLoadingBanking(false);
        }else{
          setLoadingBanking(false);
        }
      })
      .catch((err) => {
        setBankingStatus(false);
        setLoadingBanking(false);
      });
  };

  const getLinkToBanking = (isClicked) => {
    setLoadingBanking(true);

    let payload = {
      lm_um_id: dasboardData["lm_id"],
      lf_customer_name: dasboardData["lf_customer_name"],
      lf_customer_first_name: dasboardData["lf_customer_first_name"],
      lf_customer_last_name: dasboardData["lf_customer_last_name"],
      lf_customer_emailID: dasboardData["lf_customer_emailID"],
      lf_customer_DOB: dasboardData["lf_customer_DOB"],
      txtHomePostCode: dasboardData["ApptxtHomePostcodeuk"],
    };

    getAccountScore(dasboardData["lm_id"], payload).then((resp) => {
      if (resp.isSuccess == "1" && resp.url) {
        setLoadingBanking(false);

        setBankingUrl(resp.url);
        if (isClicked) {
          window.open(resp.url, "_blank");
        }
      } else {
        setLoadingBanking(false);
      }
    });
  };
  useEffect(() => {
    getData();
    return () => {
      setCurrentTabIndex(0);
      setAccoutingUrl("");
    };
  }, []);

  const applyDisabledClass = (value) => {
    if ((value && value == "0") || value == "0.00") {
      return "text-disabled";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (dasboardData) {
      if (tabIndex == 0 && currentCountry === "GB") {
        if (dasboardData["obv_account_score_status"] == "Start") {
          setBankingUrl(
            `https://connect.consents.online/decimalfactor?externalref=${dasboardData["obv_account_score_customer_ref_id"]}`
          );

          setBankingStatus(false);
        } else if (
          dasboardData["obv_account_score_status"] == "Completed" ||
          dasboardData["obv_account_score_status"] == "CompletedAddition" ||
          dasboardData["obv_account_score_status"] === "OpenBankingCancelled"
        ) {
          setBankingUrl(
            `https://connect.consents.online/decimalfactor?externalref=${dasboardData["obv_account_score_customer_ref_id"]}`
          );
          setBankingStatus(true);
        } else {
          setLoadingBanking(true);
          checkBankingStatusClick();
        }
      } else if (tabIndex == 1 || (currentCountry !== "GB" && tabIndex == 0)) {
        checkAccountingStatusClick();
      } else {
      }
    }
  }, [dasboardData, tabIndex]);

  const copyLinkToClipboard = (bankingUrlToCopy) => {
    navigator.clipboard.writeText(bankingUrlToCopy);
    ToastMessage("Url copied to clipboard!", "success");
  };

  return (
    <div className="dashboard-panel">
      <Header />
      <div className="dashboard-body bg-change-color">
        <div className="container-fluid merchant-body">
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <SiderBarMenu />

            <div className="right-panel-main">
              <h3>
                <i className="fa fa-id-card" aria-hidden="true"></i> Financial
                Health Insights{" "}
              </h3>
              <div className="dashboard-box position-relative card dashboard-card no-padding">
                <div className="review-application">
                  <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => {
                      setTabIndex(index);
                      setCurrentTabIndex(index);
                    }}
                  >
                    <div className="bankinglist">
                      <TabList>
                        {currentCountry == "GB" && <Tab>Banking Insights</Tab>}

                        <Tab
                          className={
                            currentCountry != "GB"
                              ? "react-tabs__tab tab-margin"
                              : "react-tabs__tab"
                          }
                        >
                          Accounting Insights
                        </Tab>
                        <Tab>Business Credit Score Insights</Tab>
                      </TabList>
                    </div>
                    {currentCountry == "GB" && (
                      <TabPanel>
                        <section>
                          {bankingStatus && downloadInProgress && (
                            <>
                              <Loaderspinner size="45px" />
                            </>
                          )}
                          {loadingBanking && (
                            <>
                              <Loaderspinner size="45px" />
                            </>
                          )}

                          {!bankingStatus && !loadingBanking && (
                            <button
                              className="btn btn-primary banking-btn"
                              onClick={() => {
                                getLinkToBanking(true);
                              }}
                            >
                              Link To Banking{" "}
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </button>
                          )}
                          {bankingUrl && bankingStatus && !loadingBanking && (
                            <button
                              className="btn btn-primary banking-btn"
                              onClick={() => {
                                getLinkToBanking(true);
                              }}
                            >
                              Link Additional Bank Accounts
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </button>
                          )}

                          <div className="banking-panel">
                            {!bankingUrl &&
                              !loadingBanking &&
                              !bankingStatus && (
                                <div className="banking-info-tooltip">
                                  Connect your bank account using Open Banking.
                                  Only the following required data will be
                                  requested:
                                  <div>
                                    <ul>
                                      <li>
                                        Incoming transactions for the last year
                                      </li>
                                      <li>
                                        {" "}
                                        Outgoing transactions for the last year
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            <div className="row">
                              {bankingUrl && !loadingBanking && (
                                <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 banking-col">
                                  {/* <div className="banking-url">
                                    <div className="form-group">
                                      <label>Banking URL</label>
                                      <input
                                        type="text"
                                        name="url"
                                        placeholder="https://www.domain.com/dummy-url-will-be-here"
                                        className="form-control"
                                        disabled
                                        value={bankingUrl}
                                      />
                                      <button
                                        className="copyicon-col btn btn-primary"
                                        onClick={() => {
                                          copyLinkToClipboard(bankingUrl);
                                        }}
                                      >
                                        <i
                                          className="fa fa-clone"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </div> */}
                                  <div className="banking-url">
                                    <div className="form-group">
                                      <label>Status</label>
                                      <input
                                        type="text"
                                        name="Status"
                                        placeholder="Unlinked"
                                        className="form-control"
                                        disabled
                                        value={
                                          bankingStatus ? "Linked" : "Unlinked"
                                        }
                                      />
                                      <button
                                        className="checkstatus-btn btn btn-primary"
                                        onClick={() =>
                                          checkBankingStatusClick()
                                        }
                                      >
                                        Check status
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
                            </div>

                            {bankingStatus && !loadingBanking && (
                              <div className="after-check-status">
                                <div className="download-panel">
                                  <Dropdown
                                    className="d-inline mx-2 banking-btn download-btn download-dropdown"
                                    autoClose="inside"
                                  >
                                    <Dropdown.Toggle id="dropdown-autoclose-inside">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Download{" "}
                                      {/* <i
                                      className="fa fa-chevron-down"
                                      aria-hidden="true"
                                    ></i> */}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {/* <i
                                      className="fa fa-caret-up down-arrow"
                                      aria-hidden="true"
                                    ></i> */}

                                      <Dropdown.Item
                                        href={void 0}
                                        onClick={() => downloadFile("PDF_90")}
                                      >
                                        <i
                                          className="fa fa-file-pdf-o mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        PDF last 90 days
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href={void 0}
                                        onClick={() => downloadFile("PDF_UW")}
                                      >
                                        <i
                                          className="fa fa-file-pdf-o mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        PDF underwriters
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href={void 0}
                                        onClick={() => downloadFile("PDF_RAW")}
                                      >
                                        <i
                                          className="fa fa-file-pdf-o mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        PDF raw transactions
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => downloadFile("PDF_FULL")}
                                      >
                                        <i
                                          className="fa fa-file-pdf-o mr-2"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        PDF full data range
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href={void 0}
                                        onClick={() => downloadFile("CSV_ALL")}
                                      >
                                        <i
                                          className="fa fa-file-excel-o mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        CSV all transactions
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>

                                {bankingStatus && downloadInProgress && (
                                  <>
                                    <Loaderspinner size="45px" />
                                  </>
                                )}
                                {bankingStatus && loadingServices && (
                                  <Loaderspinner size="45px" />
                                )}

                                {bankingStatus &&
                                  !loadingServices &&
                                  !loadingBanking && (
                                    <div>
                                      {/* financial services start */}
                                      <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 banking-col">
                                          <div className="financial-service">
                                            <h4>
                                              Financial Services
                                              <span>
                                                (
                                                {
                                                  financialServicesSummary.length
                                                }
                                                )
                                              </span>
                                            </h4>
                                            {(financialServicesSummary.length >
                                              0 && (
                                              <div>
                                                {" "}
                                                <div className="scroll-bar-2">
                                                  {financialServicesSummary.length >
                                                    0 &&
                                                    financialServicesSummary.map(
                                                      (service, index) => {
                                                        return index % 2 ==
                                                          0 ? (
                                                          <>
                                                            <div className="card-1">
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    service.subCategoryDescription
                                                                  }
                                                                </strong>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    service
                                                                      .creditSummary
                                                                      .transactionCount
                                                                  }{" "}
                                                                </strong>
                                                                credit{" "}
                                                                {service
                                                                  .creditSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {service.creditSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "on " +
                                                                      moment(
                                                                        new Date(
                                                                          service.creditSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    service
                                                                      .debitSummary
                                                                      .transactionCount
                                                                  }
                                                                </strong>{" "}
                                                                debit{" "}
                                                                {service
                                                                  .debitSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {service.debitSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "last on " +
                                                                      moment(
                                                                        new Date(
                                                                          service.debitSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <div className="box-id-1">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total in:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      service.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  {" "}
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      service.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                              <div className="box-id-2">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total out:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      service.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="card-1 card-2">
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    service.subCategoryDescription
                                                                  }
                                                                </strong>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    service
                                                                      .creditSummary
                                                                      .transactionCount
                                                                  }{" "}
                                                                </strong>
                                                                credit{" "}
                                                                {service
                                                                  .creditSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction "
                                                                  : "transactions "}
                                                                <span>
                                                                  {service.creditSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "on " +
                                                                      moment(
                                                                        new Date(
                                                                          service.creditSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    service
                                                                      .debitSummary
                                                                      .transactionCount
                                                                  }
                                                                </strong>{" "}
                                                                debit{" "}
                                                                {service
                                                                  .debitSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {service.debitSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "last on " +
                                                                      moment(
                                                                        new Date(
                                                                          service.debitSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <div className="box-id-1">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total in:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      service
                                                                        .creditSummary
                                                                        .total
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      service.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                              <div className="box-id-2">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total out:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      service.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      service.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>{" "}
                                                <div className="card-bottom">
                                                  <div className="box-id-1">
                                                    <p
                                                      className={applyDisabledClass(
                                                        financialServicesTotalIn
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        total in:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          financialServicesTotalIn
                                                        )}
                                                      >
                                                        +£
                                                        {formatNumberInput(
                                                          financialServicesTotalIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                    <p
                                                      className={applyDisabledClass(
                                                        financialServicesMonthlyAvgIn.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        {" "}
                                                        monthly av:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          financialServicesMonthlyAvgIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        +£
                                                        {formatNumberInput(
                                                          financialServicesMonthlyAvgIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                  </div>
                                                  <div className="box-id-2">
                                                    <p
                                                      className={applyDisabledClass(
                                                        financialServicesTotalOut
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        total out:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          financialServicesTotalOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        -£
                                                        {formatNumberInput(
                                                          financialServicesTotalOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                    <p
                                                      className={applyDisabledClass(
                                                        financialServicesMonthlyAvgOut.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        {" "}
                                                        monthly av:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          financialServicesMonthlyAvgOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        -£
                                                        {formatNumberInput(
                                                          financialServicesMonthlyAvgOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )) || (
                                              <div>
                                                <NotFound />
                                              </div>
                                            )}

                                            {/* </div> */}
                                          </div>
                                        </div>

                                        {/* income analysis start */}
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 banking-col">
                                          <div className=" financial-service income-panel">
                                            <h4>
                                              Income (
                                              {incomeAnalysisSummary.length})
                                            </h4>
                                            {(incomeAnalysisSummary.length >
                                              0 && (
                                              <div>
                                                <div className="scroll-bar-2">
                                                  {incomeAnalysisSummary.length >
                                                    0 &&
                                                    incomeAnalysisSummary.map(
                                                      (income, index) => {
                                                        return index % 2 ==
                                                          0 ? (
                                                          <>
                                                            <div className="card-1 white-bg">
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    income.vendorDescription
                                                                  }{" "}
                                                                  <div className="">
                                                                    {
                                                                      income.subCategoryDescription
                                                                    }
                                                                  </div>
                                                                </strong>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    income
                                                                      .creditSummary
                                                                      .transactionCount
                                                                  }{" "}
                                                                </strong>
                                                                credit{" "}
                                                                {income
                                                                  .creditSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {income.creditSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "on " +
                                                                      moment(
                                                                        new Date(
                                                                          income.creditSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    income
                                                                      .debitSummary
                                                                      .transactionCount
                                                                  }
                                                                </strong>{" "}
                                                                debit{" "}
                                                                {income
                                                                  .debitSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {income.debitSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "last on " +
                                                                      moment(
                                                                        new Date(
                                                                          income.debitSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              {/* <div className="d-flex align-items-center justify-content-between flex-wrap"> */}
                                                              <div className="box-id-1">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total in:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    {"+£" +
                                                                      formatNumberInput(
                                                                        income.creditSummary.total.toFixed(
                                                                          2
                                                                        )
                                                                      )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      income.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                              <div className="box-id-2">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total out:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                              {/* </div> */}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="card-1 card-2">
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    income.vendorDescription
                                                                  }{" "}
                                                                  <div className="">
                                                                    {
                                                                      income.subCategoryDescription
                                                                    }
                                                                  </div>
                                                                </strong>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    income
                                                                      .creditSummary
                                                                      .transactionCount
                                                                  }{" "}
                                                                </strong>
                                                                credit{" "}
                                                                {income
                                                                  .creditSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {income.creditSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "on " +
                                                                      moment(
                                                                        new Date(
                                                                          income.creditSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    income
                                                                      .debitSummary
                                                                      .transactionCount
                                                                  }
                                                                </strong>{" "}
                                                                debit{" "}
                                                                {income
                                                                  .debitSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {income.debitSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "last on " +
                                                                      moment(
                                                                        new Date(
                                                                          income.debitSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <div className="box-id-1">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income
                                                                        .creditSummary
                                                                        .total
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total in:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      income.creditSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    +£
                                                                    {formatNumberInput(
                                                                      income.creditSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                              <div className="box-id-2">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total out:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      income.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      income.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                                <div className="card-bottom ">
                                                  <div className="box-id-1">
                                                    <p
                                                      className={applyDisabledClass(
                                                        incomeAnalysisTotalIn.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        total in:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          incomeAnalysisTotalIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        +£
                                                        {formatNumberInput(
                                                          incomeAnalysisTotalIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                    <p
                                                      className={applyDisabledClass(
                                                        incomeAnalysisMonthlyAvgIn.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        {" "}
                                                        monthly av:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          incomeAnalysisMonthlyAvgIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        +£
                                                        {formatNumberInput(
                                                          incomeAnalysisMonthlyAvgIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                  </div>
                                                  <div className="box-id-2">
                                                    <p
                                                      className={applyDisabledClass(
                                                        incomeAnalysisTotalOut.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        total out:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          incomeAnalysisTotalOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        -£
                                                        {formatNumberInput(
                                                          incomeAnalysisTotalOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                    <p
                                                      className={applyDisabledClass(
                                                        incomeAnalysisMonthlyAvgOut.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        {" "}
                                                        monthly av:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          incomeAnalysisMonthlyAvgOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        -£
                                                        {formatNumberInput(
                                                          incomeAnalysisMonthlyAvgOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )) || (
                                              <div>
                                                <NotFound />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 banking-col">
                                          <div className="financial-service regular-outgoings">
                                            <h4>
                                              Regular Outgoings{" "}
                                              <span>
                                                (
                                                {regularOutgoingsSummary.length}
                                                )
                                              </span>
                                            </h4>
                                            {(regularOutgoingsSummary.length >
                                              0 && (
                                              <div>
                                                <div className="scroll-bar-2 ">
                                                  {regularOutgoingsSummary &&
                                                    regularOutgoingsSummary.map(
                                                      (regOutgoing, index) => {
                                                        let date = new Date(
                                                          regOutgoing.debitSummary.lastTransaction
                                                        );
                                                        return index % 2 ==
                                                          0 ? (
                                                          <>
                                                            <div className="card-1">
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    regOutgoing.vendorDescription
                                                                  }{" "}
                                                                </strong>
                                                                <span>
                                                                  {
                                                                    regOutgoing.subCategoryDescription
                                                                  }
                                                                </span>
                                                              </p>

                                                              <p>
                                                                <strong>
                                                                  {
                                                                    regOutgoing
                                                                      .debitSummary
                                                                      .transactionCount
                                                                  }
                                                                </strong>{" "}
                                                                debit{" "}
                                                                {regOutgoing
                                                                  .debitSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {regOutgoing.debitSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "last on " +
                                                                      moment(
                                                                        new Date(
                                                                          regOutgoing.debitSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <div className="calender-div float-left">
                                                                <div className="today">
                                                                  <div className="today-piece  top  day">
                                                                    {
                                                                      weekDayArray[
                                                                        date.getDay() -
                                                                          1
                                                                      ]
                                                                    }
                                                                  </div>
                                                                  <div className="today-piece  middle  month">
                                                                    {
                                                                      monthArray[
                                                                        date.getMonth()
                                                                      ]
                                                                    }
                                                                  </div>
                                                                  <div className="today-piece  middle  date">
                                                                    {date.getDate()}
                                                                    {
                                                                      dateSuffix[
                                                                        date
                                                                          .getDate()
                                                                          .toString()
                                                                      ]
                                                                    }
                                                                  </div>
                                                                  <div className="today-piece  bottom  year">
                                                                    {date.getFullYear()}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="box-id-2">
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      regOutgoing
                                                                        .debitSummary
                                                                        .total
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    total out:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      regOutgoing
                                                                        .debitSummary
                                                                        .total
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      regOutgoing.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      regOutgoing
                                                                        .debitSummary
                                                                        .monthlyAverage
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      regOutgoing.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      regOutgoing.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="card-1 card-2">
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    regOutgoing.vendorDescription
                                                                  }{" "}
                                                                </strong>
                                                                <span>
                                                                  {
                                                                    regOutgoing.subCategoryDescription
                                                                  }
                                                                </span>
                                                              </p>

                                                              <p>
                                                                <strong>
                                                                  {
                                                                    regOutgoing
                                                                      .debitSummary
                                                                      .transactionCount
                                                                  }
                                                                </strong>{" "}
                                                                debit{" "}
                                                                {regOutgoing
                                                                  .debitSummary
                                                                  .transactionCount <
                                                                2
                                                                  ? "transaction"
                                                                  : "transactions"}{" "}
                                                                <span>
                                                                  {regOutgoing.debitSummary.lastTransaction.substring(
                                                                    0,
                                                                    4
                                                                  ) >= "1997" &&
                                                                    "(" +
                                                                      "last on " +
                                                                      moment(
                                                                        new Date(
                                                                          regOutgoing.debitSummary.lastTransaction
                                                                        )
                                                                      ).format(
                                                                        "DD MMM YYYY"
                                                                      ) +
                                                                      ")"}
                                                                </span>
                                                              </p>
                                                              <div className="calender-div float-left">
                                                                <div className="today">
                                                                  <div className="today-piece  top  day">
                                                                    {
                                                                      weekDayArray[
                                                                        date.getDay() -
                                                                          1
                                                                      ]
                                                                    }
                                                                  </div>
                                                                  <div className="today-piece  middle  month">
                                                                    {
                                                                      monthArray[
                                                                        date.getMonth()
                                                                      ]
                                                                    }
                                                                  </div>
                                                                  <div className="today-piece  middle  date">
                                                                    {date.getDate()}
                                                                    {
                                                                      dateSuffix[
                                                                        date
                                                                          .getDate()
                                                                          .toString()
                                                                      ]
                                                                    }
                                                                  </div>
                                                                  <div className="today-piece  bottom  year">
                                                                    {date.getFullYear()}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="box-id-2">
                                                                {regOutgoing
                                                                  .debitSummary
                                                                  .total && (
                                                                  <p
                                                                    className={applyDisabledClass(
                                                                      regOutgoing.debitSummary.total.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    <div className="text-gray">
                                                                      total out:
                                                                    </div>
                                                                    <strong
                                                                      className={applyDisabledClass(
                                                                        regOutgoing.debitSummary.total.toFixed(
                                                                          2
                                                                        )
                                                                      )}
                                                                    >
                                                                      -£
                                                                      {formatNumberInput(
                                                                        regOutgoing.debitSummary.total.toFixed(
                                                                          2
                                                                        )
                                                                      )}
                                                                    </strong>
                                                                  </p>
                                                                )}

                                                                <p
                                                                  className={
                                                                    "amount-para " +
                                                                    applyDisabledClass(
                                                                      regOutgoing.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-gray">
                                                                    {" "}
                                                                    monthly av:
                                                                  </div>
                                                                  <strong
                                                                    className={applyDisabledClass(
                                                                      regOutgoing.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  >
                                                                    -£
                                                                    {formatNumberInput(
                                                                      regOutgoing.debitSummary.monthlyAverage.toFixed(
                                                                        2
                                                                      )
                                                                    )}
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                                <div className="card-bottom">
                                                  <div className="box-id-1">
                                                    <p
                                                      className={applyDisabledClass(
                                                        regularOutgoingTotalIn
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        total in:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          regularOutgoingTotalIn
                                                        )}
                                                      >
                                                        +£
                                                        {formatNumberInput(
                                                          regularOutgoingTotalIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                    <p
                                                      className={applyDisabledClass(
                                                        regularOutgoingMonthlyAvgIn.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        {" "}
                                                        monthly av:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          regularOutgoingMonthlyAvgIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        +£
                                                        {formatNumberInput(
                                                          regularOutgoingMonthlyAvgIn.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                  </div>
                                                  <div className="box-id-2">
                                                    <p
                                                      className={applyDisabledClass(
                                                        regularOutgoingTotalOut
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        total out:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          regularOutgoingTotalOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        -£
                                                        {formatNumberInput(
                                                          regularOutgoingTotalOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                    <p
                                                      className={applyDisabledClass(
                                                        regularOutgoingMonthlyAvgOut.toFixed(
                                                          2
                                                        )
                                                      )}
                                                    >
                                                      <div className="text-gray">
                                                        {" "}
                                                        monthly av:
                                                      </div>
                                                      <strong
                                                        className={applyDisabledClass(
                                                          regularOutgoingMonthlyAvgOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      >
                                                        -£
                                                        {formatNumberInput(
                                                          regularOutgoingMonthlyAvgOut.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </strong>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )) || (
                                              <div>
                                                <NotFound />
                                              </div>
                                            )}
                                          </div>
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 banking-col">
                                          <div className=" financial-service income-panel">
                                            <h4>Event Feed ({eventCount})</h4>
                                            <div className=" events-panel">
                                              {(eventFeedSummary.length > 0 && (
                                                <div>
                                                  {eventFeedSummary.length >
                                                    0 &&
                                                    eventFeedSummary.map(
                                                      (ev, index) => {
                                                        return (
                                                          <>
                                                            <div
                                                              className="card-1 "
                                                              style={{
                                                                backgroundColor:
                                                                  index % 2 == 0
                                                                    ? "#f3f3f3"
                                                                    : "#e8e8e8",
                                                              }}
                                                            >
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    ev.additionalInformation
                                                                  }
                                                                </strong>
                                                              </p>
                                                              <p>
                                                                {ev.amount &&
                                                                  ev.eventType ==
                                                                    "Significant transaction" && (
                                                                    <strong>
                                                                      £
                                                                      {formatNumberInput(
                                                                        ev.amount.toFixed(
                                                                          2
                                                                        )
                                                                      )}
                                                                    </strong>
                                                                  )}{" "}
                                                                {ev.eventType ==
                                                                  "Significant transaction" ||
                                                                ev.eventType ==
                                                                  "Change in Income Profile"
                                                                  ? "on"
                                                                  : "Last credit on"}{" "}
                                                                {/* Last credit on{" "} */}
                                                                <strong>
                                                                  {moment(
                                                                    new Date(
                                                                      ev.eventDate
                                                                    )
                                                                  ).format(
                                                                    "DD MMM YYYY"
                                                                  )}
                                                                </strong>
                                                                <span className="ml-2">
                                                                  (
                                                                  {moment().diff(
                                                                    new Date(
                                                                      ev.eventDate
                                                                    ),
                                                                    "days"
                                                                  ) +
                                                                    " days ago"}
                                                                  )
                                                                </span>
                                                              </p>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              )) || (
                                                <div>
                                                  <NotFound />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </section>
                      </TabPanel>
                    )}

                    <TabPanel>
                      <section>
                        {loadingAccouting && <Loaderspinner size="45px" />}
                        {!loadingAccouting && !accountingStatus && (
                          <>
                            <button
                              className="btn btn-primary accounting-btn"
                              onClick={() => {
                                getLinkToAccouting(true);
                              }}
                            >
                              Link To Accounting{" "}
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </>
                        )}

                        {!loadingAccouting &&
                          accountingStatus &&
                          accoutingUrl && (
                            <>
                              <button
                                className="btn btn-primary accounting-btn"
                                onClick={() => {
                                  getLinkToAccouting(true);
                                }}
                              >
                                Update Your Connections
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </>
                          )}

                        <div className="merchent-accounting-panel">
                          {!accoutingUrl &&
                            !loadingAccouting &&
                            !accountingStatus && (
                              <div className="banking-info-tooltip">
                                Connect your accounting software to seamlessly
                                view all your data on the portal and the help
                                increase your loan acceptance rate.
                                <div>
                                  Only the following required data will be
                                  requested:{" "}
                                </div>
                                <div>
                                  <ul style={{ width: "235px" }}>
                                    <li>Accounts receivable information</li>
                                    <li>Accounts payable information</li>
                                    <li>Financial summary information</li>
                                  </ul>
                                </div>
                              </div>
                            )}

                          {accoutingUrl && !loadingAccouting && (
                            <>
                              <div className="banking-url">
                                <div className="form-group">
                                  <label>Status</label>
                                  <input
                                    type="text"
                                    name="Status"
                                    placeholder=""
                                    className="form-control"
                                    disabled
                                    value={
                                      accountingStatus ? "Linked" : "Unlinked"
                                    }
                                  />
                                  <button
                                    className="checkstatus-btn btn btn-primary"
                                    onClick={() => checkAccountingStatusClick()}
                                  >
                                    Check status
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {accountingStatus && (
                            <div className="data-panel">
                              <Codat leadId={lead_accountScore} />
                            </div>
                          )}
                        </div>
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <BusinessCreditScore />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantHealth;
