import React from "react";
import Datatables from "./Datatables";
import Parser from "html-react-parser";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function BillPayments(props) {
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">{row.supplierRef}</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <div
              ref={ref}
              {...triggerHandler}
              className="ml-2 cursor-pointer ellipsis-text"
            >
              {row.supplierRef}{" "}
            </div>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Account Name",
      selector: (row) => row.accountRef,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">{row.note}</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <div
              ref={ref}
              {...triggerHandler}
              className="ml-2 cursor-pointer ellipsis-text"
            >
              {row.note}{" "}
            </div>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
    },
  ];

  const finalUrl = `${props.endUrl}/CODAT/Bill_Payment/${props.leadId}`;

  return (
    <Datatables title={props.title} apiUrl={finalUrl} apiColumn={columns} />
  );
}
