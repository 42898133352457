import clsx from "clsx";
import React from "react";
import { useAppSelector } from "../redux/hooks/hooks";

export default function CurrencyIcon() {
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  return (
    <div className="input-group-prepend">
      <span className="input-group-text" id="basic-addon1">
        {currentCountry === "CA" && <span className="country-name">CA</span>}
        <i
          className={clsx(
            "fa",
            {
              "fa-pound-sign": currentCountry === "GB",
            },
            {
              "fa-usd": currentCountry === "US" || currentCountry === "CA",
            }
          )}
        ></i>
      </span>
    </div>
  );
}
