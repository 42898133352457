import { Formik } from "formik";
import React from "react";
import clsx from "clsx";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Header from "../header/header";
import SiderBarMenu from "./component/sidebar";
import {
  businessEntityList,
  checkCompanyType,
  fieldNames,
  loadOptions,
  loadPurposeList,
  selectBusinessEntityList,
  validationSchema,
  validationSchemaUS,
} from "../requestaquote/components/application-information";
import { useState } from "react";
import { getUserDetails } from "../login/loginpage";
import {
  getBusinessStateList,
  getDashboardData,
  updateCustomerInfo,
} from "../../request";
import { useEffect } from "react";
import StickyBox from "react-sticky-box";
import { ToastMessage } from "../ToastMessage";
import Loaderspinner from "../loader";
import { useDispatch } from "react-redux/es";
import { TRIGGER_LEAD_DETAILS } from "../../redux/actions/actionTypes";
import { formatNumberInput, numberRegex, removeComma } from "../Constants";
import CurrencyIcon from "../CurrencyIcon";
import { useAppSelector } from "../../redux/hooks/hooks";

function ApplicationInformation() {
  const [dasboardData, setDashboardData] = useState();
  const userDetails = getUserDetails();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  const getData = () => {
    if (userDetails && userDetails.lead_id) {
      getDashboardData(userDetails.lead_id, currentCountry).then((resp) => {
        dispatch({
          type: TRIGGER_LEAD_DETAILS,
          leadDetails: resp.records[0],
        });
        // if (resp.records[0] && stateList && stateList.length) {
        //   initialValues["state"] =
        //     stateList[
        //       stateList.findIndex(
        //         (item) => resp.records[0]["lf_business_entity"] == item.value
        //       )
        //     ];
        // }
        setDashboardData(resp.records[0]);
        let data = initializeValues(resp.records[0], stateList);
        setInitState(data);
      });
    }
  };

  useEffect(() => {
    // getData();
    return () => {};
  }, []);

  const initialValues = {
    [fieldNames.AMOUNT]: dasboardData ? dasboardData["lf_amount_required"] : "",
    [fieldNames.REQUIREDFUND]: dasboardData ? dasboardData["lm_Funds"] : "",
    [fieldNames.LOANPURPOSE]: dasboardData
      ? loadPurposeList[
          loadPurposeList.findIndex(
            (item) => dasboardData["ApptxtLoanPurpose"] == item.value
          )
        ]
      : "",
    [fieldNames.BUSINESSENTITY]: dasboardData
      ? selectBusinessEntityList(currentCountry)[
          selectBusinessEntityList(currentCountry).findIndex(
            (item) => dasboardData["lf_business_activity"] == item.value
          )
        ]
      : "",
    [fieldNames.BUSINESSNAME]: dasboardData
      ? dasboardData["lf_business_name"]
      : "",
    [fieldNames.STATE]:
      stateList &&
      stateList[
        stateList.findIndex(
          (item) => dasboardData["lf_business_entity"] == item.value
        )
      ],
  };

  const [initState, setInitState] = useState({});


  const [stateListLoading, setStateListLoading] = useState(false);
  const [stateList, setStateList] = useState([]);


  const initializeValues = (data, stateList) => {
    let obj = { ...initialValues };
    obj[fieldNames.AMOUNT] = data ? data["lf_amount_required"] : "";
    obj[fieldNames.REQUIREDFUND] = data ? data["lm_Funds"] : "";
    obj[fieldNames.LOANPURPOSE] = data
      ? loadPurposeList[
          loadPurposeList.findIndex(
            (item) => data["ApptxtLoanPurpose"] == item.value
          )
        ]
      : "";
    obj[fieldNames.BUSINESSENTITY] = data
      ? selectBusinessEntityList(currentCountry)[
          selectBusinessEntityList(currentCountry).findIndex(
            (item) => data["lf_business_activity"] == item.value
          )
        ]
      : "";
    obj[fieldNames.BUSINESSNAME] = data ? data["lf_business_name"] : "";
    obj[fieldNames.STATE] =
      stateList &&
      stateList[
        stateList.findIndex((item) => data["lf_business_entity"] == item.value)
      ];

    return obj;
  };

  useEffect(() => {
    setLoading(true);
    const allPromise = Promise.all([
      getDashboardData(userDetails.lead_id, currentCountry),
      getBusinessStateList(currentCountry),
    ]);
    allPromise
      .then((resp) => {
        setLoading(false);
        setStateListLoading(false);

        //  getDashboardData(userDetails.lead_id, currentCountry).then(
        //    (resp) => {
        dispatch({
          type: TRIGGER_LEAD_DETAILS,
          leadDetails: resp[0].records[0],
        });
        setDashboardData(resp[0].records[0]);

        const array = [];
        Object.keys(resp[1].data.data).forEach((item, key) => {
          array.push({
            label: resp[1].data.data[key]["label"],
            value: resp[1].data.data[key]["label_id"],
          });
        });

        let data = initializeValues(resp[0].records[0], array);
        setInitState(data);
        setStateList(array);
      })
      .catch((err) => {
        setLoading(false);
        setStateList([]);
        setStateListLoading(false);
      });
    // console.log(
    //   "🚀 ~ file: application-information.js:116 ~ allPromise.then ~ resp:",
    //   resp
    // );
    // });
  }, []);
  return (
    <div className="dashboard-panel">
      <Header />
      <div className="dashboard-body bg-change-color">
        <div className="container-fluid  merchant-body">
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <StickyBox>
              <SiderBarMenu />
            </StickyBox>

            <div className="right-panel-main">
              <h3>
                <i className="fa fa-laptop" aria-hidden="true"></i> Application
                Information{" "}
              </h3>
              <div className="dashboard-box position-relative card dashboard-card">
                {loading ? (
                  <Loaderspinner size="45px" />
                ) : (
                  <Formik
                    initialValues={initState}
                    validationSchema={
                      currentCountry == "GB"
                        ? validationSchema
                        : validationSchemaUS
                    }
                    enableReinitialize={initialValues || currentCountry}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      let payload = { ...values };
                      payload["businessEntity"] =
                        payload["businessEntity"].value;
                      payload["loanPurpose"] = payload["loanPurpose"].value;
                      payload[fieldNames.AMOUNT] = removeComma(
                        payload[fieldNames.AMOUNT]
                      );
                      payload[fieldNames.REQUIREDFUND] = removeComma(
                        payload[fieldNames.REQUIREDFUND]
                      );

                      payload["lf_business_entity"] = payload["state"]
                        ? payload["state"].value
                        : "";
                      payload[fieldNames.STATE] = payload["state"]
                        ? payload["state"].value
                        : "";

                      updateCustomerInfo(
                        payload,
                        userDetails["lead_id"],
                        currentCountry
                      )
                        .then((resp) => {
                          setLoading(false);
                          if (resp.isSuccess == 1) {
                            ToastMessage("Data saved successfully!", "success");
                            resetForm({});
                            getData();
                          } else {
                            ToastMessage("Something went wrong!", "error");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          ToastMessage("Something went wrong!", "error");
                        });

                      setTimeout(() => {
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      isValid,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="review-application">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Amount Required</label>
                                <div className="input-group mb-3">
                                  <CurrencyIcon />
                                  <input
                                    type="text"
                                    placeholder="90,000"
                                    className={clsx(
                                      "form-control ",
                                      {
                                        "is-invalid":
                                          touched[fieldNames.AMOUNT] &&
                                          errors[fieldNames.AMOUNT],
                                      },
                                      {
                                        "is-valid":
                                          touched[fieldNames.AMOUNT] &&
                                          !errors[fieldNames.AMOUNT],
                                      }
                                    )}
                                    name={fieldNames.AMOUNT}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        if (
                                          numberRegex.test(
                                            removeComma(e.target.value)
                                          )
                                        ) {
                                          setFieldValue(
                                            fieldNames.AMOUNT,
                                            e.target.value
                                          );
                                        } else {
                                          setFieldValue(
                                            fieldNames.AMOUNT,
                                            values[fieldNames.AMOUNT]
                                          );
                                        }
                                      } else {
                                        setFieldValue(fieldNames.AMOUNT, "");
                                      }
                                    }}
                                    value={formatNumberInput(
                                      values[fieldNames.AMOUNT]
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Purpose of Loan</label>
                                <Select
                                  closeMenuOnSelect={true}
                                  onChange={(selectedOption) => {
                                    setFieldValue(
                                      fieldNames.LOANPURPOSE,
                                      selectedOption
                                    );
                                  }}
                                  options={loadPurposeList}
                                  name={fieldNames.LOANPURPOSE}
                                  placeholder="Enter purpose of loan"
                                  styles={{
                                    control: (styles, state) => {
                                      const borderColor =
                                        !state.hasValue &&
                                        touched[fieldNames.LOANPURPOSE] &&
                                        errors[fieldNames.LOANPURPOSE]
                                          ? "red"
                                          : "#ced4da";

                                      return { ...styles, borderColor };
                                    },
                                  }}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  value={values[fieldNames.LOANPURPOSE]}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Term of Funds Required (Months)</label>
                                <input
                                  type="text"
                                  placeholder="12"
                                  name={fieldNames.REQUIREDFUND}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      if (
                                        numberRegex.test(
                                          removeComma(e.target.value)
                                        )
                                      ) {
                                        setFieldValue(
                                          fieldNames.REQUIREDFUND,
                                          e.target.value
                                        );
                                      } else {
                                        setFieldValue(
                                          fieldNames.REQUIREDFUND,
                                          values[fieldNames.REQUIREDFUND]
                                        );
                                      }
                                    } else {
                                      setFieldValue(
                                        fieldNames.REQUIREDFUND,
                                        ""
                                      );
                                    }
                                  }}
                                  value={formatNumberInput(
                                    values[fieldNames.REQUIREDFUND]
                                  )}
                                  className={clsx(
                                    "form-control ",
                                    {
                                      "is-invalid":
                                        touched[fieldNames.REQUIREDFUND] &&
                                        errors[fieldNames.REQUIREDFUND],
                                    },
                                    {
                                      "is-valid":
                                        touched[fieldNames.REQUIREDFUND] &&
                                        !errors[fieldNames.REQUIREDFUND],
                                    }
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group business-entity">
                                <label>Business Entity</label>
                                <Select
                                  classNamePrefix={clsx(
                                    "",
                                    {
                                      "is-invalid":
                                        touched[fieldNames.BUSINESSENTITY] &&
                                        errors[fieldNames.BUSINESSENTITY],
                                    },
                                    {
                                      "is-valid":
                                        touched[fieldNames.BUSINESSENTITY] &&
                                        !errors[fieldNames.BUSINESSENTITY],
                                    }
                                  )}
                                  closeMenuOnSelect={true}
                                  onChange={(selectedOption) => {
                                    setFieldValue(
                                      fieldNames.BUSINESSENTITY,
                                      selectedOption
                                    );
                                  }}
                                  options={selectBusinessEntityList(
                                    currentCountry
                                  )}
                                  name={fieldNames.BUSINESSENTITY}
                                  placeholder="Select Business Entity"
                                  styles={{
                                    control: (styles, state) => {
                                      const borderColor =
                                        !state.hasValue &&
                                        touched[fieldNames.BUSINESSENTITY] &&
                                        errors[fieldNames.BUSINESSENTITY]
                                          ? "red"
                                          : "#ced4da";

                                      return { ...styles, borderColor };
                                    },
                                  }}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  value={values[fieldNames.BUSINESSENTITY]}
                                />
                              </div>
                            </div>
                            {currentCountry !== "GB" && (
                              <div className="col-md-4">
                                <div className="form-group business-entity">
                                  <label>
                                    {currentCountry == "GB"
                                      ? "Business Region"
                                      : currentCountry == "US"
                                      ? "State"
                                      : "Province"}
                                  </label>
                                  <Select
                                    closeMenuOnSelect={true}
                                    onChange={(selectedOption) =>
                                      setFieldValue(
                                        fieldNames.STATE,
                                        selectedOption
                                      )
                                    }
                                    isLoading={stateListLoading}
                                    name={fieldNames.STATE}
                                    options={stateList}
                                    placeholder={
                                      currentCountry == "GB"
                                        ? "Business Region"
                                        : currentCountry == "US"
                                        ? "State"
                                        : "Province"
                                    }
                                    styles={{
                                      control: (styles, state) => {
                                        const borderColor =
                                          !state.hasValue &&
                                          touched[fieldNames.STATE] &&
                                          errors[fieldNames.STATE]
                                            ? "red"
                                            : "#ced4da";

                                        return { ...styles, borderColor };
                                      },
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    value={values[fieldNames.STATE]}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="col-md-4">
                              <div className="form-group business-entity">
                                {values[fieldNames.BUSINESSENTITY] && (
                                  <div className="form-group business-entity">
                                    <label>Business Name</label>

                                    {checkCompanyType(
                                      values[fieldNames.BUSINESSENTITY],
                                      currentCountry
                                    ) ? (
                                      <AsyncSelect
                                        closeMenuOnSelect={true}
                                        value={{
                                          label:
                                            values[fieldNames.BUSINESSNAME],
                                        }}
                                        name={fieldNames.BUSINESSNAME}
                                        loadOptions={loadOptions}
                                        onChange={(selectedOption) => {
                                          setFieldValue(
                                            fieldNames.BUSINESSNAME,
                                            selectedOption.value
                                          );
                                        }}
                                        components={{
                                          IndicatorSeparator: () => null,
                                          DropdownIndicator: () => null,
                                        }}
                                        placeholder="Select Business Name"
                                        styles={{
                                          control: (styles, state) => {
                                            const borderColor =
                                              !state.hasValue &&
                                              touched[
                                                fieldNames.BUSINESSNAME
                                              ] &&
                                              errors[fieldNames.BUSINESSNAME]
                                                ? "red"
                                                : "#ced4da";

                                            return { ...styles, borderColor };
                                          },
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        placeholder="Enter Business Name"
                                        name={fieldNames.BUSINESSNAME}
                                        onChange={handleChange}
                                        value={values[fieldNames.BUSINESSNAME]}
                                        className={clsx(
                                          "form-control ",
                                          {
                                            "is-invalid":
                                              touched[
                                                fieldNames.BUSINESSNAME
                                              ] &&
                                              errors[fieldNames.BUSINESSNAME],
                                          },
                                          {
                                            "is-valid":
                                              touched[
                                                fieldNames.BUSINESSNAME
                                              ] &&
                                              !errors[fieldNames.BUSINESSNAME],
                                          }
                                        )}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <button
                            className="btn btn-primary save-btn next-btn"
                            type="submit"
                            disabled={loading}
                          >
                            Save <i className="fa fa-file-image-o"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationInformation;
