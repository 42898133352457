import React from "react";
import Header from "../header/header";
import {
  businessEntityList,
  fieldNames,
} from "../requestaquote/components/application-information";
import SiderBarMenu from "./component/sidebar";
import Select from "react-select";
import clsx from "clsx";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import { FieldArray } from "formik";
import MaskedInput from "react-maskedinput";
import { useState } from "react";
import {
  getBusinessSectorList,
  getBusinessStateList,
  getDashboardData,
  updateCustomerInfo,
} from "../../request";
import { useEffect } from "react";
import { getUserDetails } from "../login/loginpage";
import {
  directorFieldNames,
  formatNumberInput,
  numberRegex,
  prefixPlusIcon,
  removeComma,
  residentialStatusList,
} from "../Constants";
import StickyBox from "react-sticky-box";
import { ToastMessage } from "../ToastMessage";
import Loaderspinner from "../loader";
import { useAppSelector } from "../../redux/hooks/hooks";
import { initialDirectorObject } from "../dashboard/component/review-business-information";
import { useRef } from "react";
import CurrencyIcon from "../CurrencyIcon";
import DirectorInfo from "./directorInfo";
import moment from "moment";
import { current } from "@reduxjs/toolkit";

const Accordion = ({ title, children, isPrimary }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title  ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
        style={{ justifyContent: "flex-start" }}
      >
        <div className="px-2"> {title}</div>
        {isPrimary && (
          <button
            className="btn btn-success btn-sm mr-2"
            style={{
              backgroundColor: "#198754",
              pointerEvents: "none",
            }}
            type="button"
          >
            Primary
          </button>
        )}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

function BusinessInformation() {
  const [directorList, setDirectorList] = useState([]);
  const [isTouched, setIsTouched] = useState(false);

  const [isAddedPrevAddress, setIsAddedPrevAddress] = useState(false);
  const { leadDetails } = useAppSelector((state) => state.leadDetailsReducer);
  const initialPreviousAddressObjUk = {
    [directorFieldNames.ADDRESS]: "",
    [directorFieldNames.COUNTY]: "",
    [directorFieldNames.POSTCODE]: "",
    [directorFieldNames.HOUSENUMBER]: "",
    [directorFieldNames.HOUSENAME]: "",
    [directorFieldNames.WHENTOMOVETOADDRESS]: "",
    [directorFieldNames.TOWN]: "",
    [directorFieldNames.PREVIOUSADDSHAREHOLDERID]: "",
  };

  const initialPreviousAddressObjUsCA = {
    [directorFieldNames.ADDRESSLINE1]: "",
    [directorFieldNames.ADDRESSLINE2]: "",
    [directorFieldNames.POSTCODE]: "",
    [directorFieldNames.STATE]: "",
    [directorFieldNames.CITY]: "",
    [directorFieldNames.WHENTOMOVETOADDRESS]: "",
  };
  const [dasboardData, setDashboardData] = useState();
  const { currentCountry } = useAppSelector((state) => state.countryReducer);

  const userDetails = getUserDetails();
  const [loading, setLoading] = useState(false);
  const [areTruthy, setAreTruthy] = useState(false);
  const [businessSectorList, selectBusinessSectorList] = useState([]);
  const [loadingBusinessSectorList, setLoadingBusinessSectorList] =
    useState(false);

  useEffect(() => {
    setLoadingBusinessSectorList(true);
    getBusinessSectorList(currentCountry)
      .then((resp) => {
        setLoadingBusinessSectorList(false);

        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });
        selectBusinessSectorList(array);
      })
      .catch((err) => {
        setLoadingBusinessSectorList(false);
      });
  }, [currentCountry]);
  const [initialPreviousAddressObj, setInitialPrevAddressObject] = useState(
    currentCountry == "GB"
      ? initialPreviousAddressObjUk
      : initialPreviousAddressObjUsCA
  );

  const PreviousArrayHelperRef = useRef();
  const directInfoSchemaUK = Yup.object().shape({
    [directorFieldNames.NATUREOFCONTROL]: Yup.string().nullable(true),
    [directorFieldNames.KINDOFSHAREHOLDER]: Yup.string().nullable(true),
    [directorFieldNames.SHAREHOLDERDOBFULLFORMAT]: Yup.string().nullable(true),
    [directorFieldNames.POSTALCODE]: Yup.string().nullable(true),
    [directorFieldNames.ADDRESS]: Yup.string().nullable(true),
    [directorFieldNames.HOUSE_NUMBER]: Yup.string().nullable(true),
    [directorFieldNames.HOUSE_NAME]: Yup.string().nullable(true),
    [directorFieldNames.STREET]: Yup.string().nullable(true),
    [directorFieldNames.COUNTY]: Yup.string().nullable(true),
    [directorFieldNames.TOWN]: Yup.string().nullable(true),
    [directorFieldNames.RESIDENTIALSTATUS]: Yup.string().nullable(true),
    [directorFieldNames.LIVINGSINCE]: Yup.string().nullable(true),
    [directorFieldNames.FIRSTNAME]: Yup.string().nullable(true),
    [directorFieldNames.LASTNAME]: Yup.string().nullable(true),
    [directorFieldNames.PHONENUMBER]: Yup.string().nullable(true),
    [directorFieldNames.EMAILID]: Yup.string().nullable(true),
    [directorFieldNames.ISPRIMARY]: Yup.string().nullable(true),
    [directorFieldNames.CHOOSEADDRESS]: Yup.string().nullable(true),
    [directorFieldNames.ADDRESSLINE1]: Yup.string().nullable(true),
    [directorFieldNames.ADDRESSLINE2]: Yup.string().nullable(true),
    [directorFieldNames.HIDDENSHAREHOLDERID]: Yup.number().nullable(true),
    [directorFieldNames.PREVIOUSADDRESS]: Yup.array()
      .of(
        Yup.object().shape({
          [directorFieldNames.ADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.COUNTY]: Yup.string().nullable(true),
          [directorFieldNames.POSTCODE]: Yup.string().nullable(true),
          [directorFieldNames.HOUSENUMBER]: Yup.string().nullable(true),
          [directorFieldNames.HOUSENAME]: Yup.string().nullable(true),
          [directorFieldNames.WHENTOMOVETOADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.TOWN]: Yup.string().nullable(true),
          id: Yup.string().nullable(true),
        })
      )
      .nullable(true),
  });

  const directInfoSchemaUSCA = Yup.object().shape({
    [directorFieldNames.NATUREOFCONTROL]: Yup.string().nullable(true),
    [directorFieldNames.KINDOFSHAREHOLDER]: Yup.string().nullable(true),
    [directorFieldNames.SHAREHOLDERDOBFULLFORMAT]: Yup.string().nullable(true),
    [directorFieldNames.POSTALCODE]: Yup.string().nullable(true),
    [directorFieldNames.STREET]: Yup.string().nullable(true),
    [directorFieldNames.CITY]: Yup.string().nullable(true),
    [directorFieldNames.STATE]: Yup.string().nullable(true),
    [directorFieldNames.RESIDENTIALSTATUS]: Yup.string().nullable(true),
    [directorFieldNames.LIVINGSINCE]: Yup.string().nullable(true),
    [directorFieldNames.FIRSTNAME]: Yup.string().nullable(true),
    [directorFieldNames.LASTNAME]: Yup.string().nullable(true),
    [directorFieldNames.PHONENUMBER]: Yup.string().nullable(true),
    [directorFieldNames.EMAILID]: Yup.string().nullable(true),
    [directorFieldNames.ISPRIMARY]: Yup.string().nullable(true),
    [directorFieldNames.CHOOSEADDRESS]: Yup.string().nullable(true),
    [directorFieldNames.ADDRESSLINE1]: Yup.string().nullable(true),
    [directorFieldNames.ADDRESSLINE2]: Yup.string().nullable(true),
    [directorFieldNames.HIDDENSHAREHOLDERID]: Yup.number().nullable(true),
    [directorFieldNames.PREVIOUSADDRESS]: Yup.array()
      .of(
        Yup.object().shape({
          [directorFieldNames.ADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.COUNTY]: Yup.string().nullable(true),
          [directorFieldNames.POSTCODE]: Yup.string().nullable(true),
          [directorFieldNames.HOUSENUMBER]: Yup.string().nullable(true),
          [directorFieldNames.HOUSENAME]: Yup.string().nullable(true),
          [directorFieldNames.WHENTOMOVETOADDRESS]: Yup.string().nullable(true),
          [directorFieldNames.TOWN]: Yup.string().nullable(true),
          id: Yup.string().nullable(true),
        })
      )
      .nullable(true),
  });

  const validationSchema = Yup.object().shape({
    [fieldNames.CARDPAYMENTAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSTARTDATE]: Yup.string().required(),
    [fieldNames.SUPPLIERDUEAMOUNT]: Yup.string().required(),
    [fieldNames.BUSINESSSECTOR]: Yup.string().required(),
    // [fieldNames.BUSINESSLEGALNUMBER]: Yup.string().required(),
    country: Yup.string(),
    [fieldNames.BUSINESSLEGALNUMBER]: Yup.string().when("country", {
      is: (value) => {
        return value !== "GB" ? false : true;
      },
      then: Yup.string()
        .required("Business Legal Number is required")
        .min(2, "Must be atlease 2 digit number"),
    }),
    [fieldNames.DIRECTORINFO]: Yup.array()
      .of(currentCountry == "GB" ? directInfoSchemaUK : directInfoSchemaUSCA)
      .nullable(true),
  });

  useEffect(() => {
    getData();

    return () => {};
  }, []);

  const getData = () => {
    if (userDetails && userDetails.lead_id) {
      getDashboardData(userDetails.lead_id, currentCountry).then((resp) => {
        setDashboardData(resp.records[0]);
        setDirectorList(resp.records[0]["ShareHolderList"]);
      });
    }
  };

  const [stateListLoading, setStateListLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    setStateListLoading(true);
    getBusinessStateList(currentCountry)
      .then((resp) => {
        setStateListLoading(false);
        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });

        setStateList(array);
      })
      .catch((err) => {
        setStateListLoading(false);
      });
  }, [currentCountry]);

  const patchDirectorData = (data) => {
    if (data) {
      let values;
      if (data["ShareHolderList"] && data["ShareHolderList"].length) {
        values = [...data["ShareHolderList"]];
      }
      if (values && values.length) {
        values = values.map((item, i) => {
          item["fullName"] = item["firstName"];
          item["lastName"] = item["lastName"];
          if (item["address"]) {
            item[directorFieldNames.POSTCODE] =
              item["address"][directorFieldNames.POSTCODE];
            item[directorFieldNames.STREET] = item["address"]["address_line_1"];
            item[directorFieldNames.COUNTY] = item["address"]["locality"];

            item[directorFieldNames.TOWN] = "";
          } else {
            item[directorFieldNames.COUNTY] = item["county"] || "";
            item[directorFieldNames.TOWN] = item["town"] || "";
          }
          item[directorFieldNames.RESIDENTIALSTATUS] =
            residentialStatusList[
              residentialStatusList.findIndex(
                (data) => item["residentialStatus"] == data.value
              )
            ];

          item[directorFieldNames.HOUSE_NAME] = item["houseName"] || "";
          item[directorFieldNames.HOUSE_NUMBER] = item["houseNumber"];

          if (item["living_since"]) {
            let splittedDate = item["living_since"].split("/");
            let date = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
            item[directorFieldNames.LIVINGSINCE] = date || "";
          } else {
            item[directorFieldNames.LIVINGSINCE] = null;
          }

          item[directorFieldNames.NATUREOFCONTROL] = item["natures_of_control"];
          item[directorFieldNames.EMAILID] = item[directorFieldNames.EMAILID];
          item[directorFieldNames.PHONENUMBER] = item["phonenumber"]
            ? item["phonenumber"]
            : "";
          item[directorFieldNames.KINDOFSHAREHOLDER] = item[
            directorFieldNames.KINDOFSHAREHOLDER
          ]
            ? item[directorFieldNames.KINDOFSHAREHOLDER]
            : "";

          if (values.length > 1) {
            item[directorFieldNames.ISPRIMARY] =
              item[directorFieldNames.ISPRIMARY] == 1 ? true : false;
          } else {
            item[directorFieldNames.ISPRIMARY] = true;
          }

          item[directorFieldNames.CHOOSEADDRESS] = "";
          item[directorFieldNames.HIDDENSHAREHOLDERID] = item["shareHolderID"];
          if (stateList.length) {
            item[directorFieldNames.STATE] =
              stateList[
                stateList.findIndex((item1) => item["state"] == item1.value)
              ];
          }

          if (item["DOB_year"] && item["DOB_month"] && item["DOB_day"]) {
            item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT] = item[
              directorFieldNames.SHAREHOLDERDOBFULLFORMAT
            ] = `${item["date_of_birth"]["year"]}-${
              item["date_of_birth"]["month"]
            }-${
              item["date_of_birth"]["day"] ? item["date_of_birth"]["day"] : 1
            }`;
          } else if (item["DOB_year"] && moment(item["DOB_year"]).isValid()) {
            item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT] = new Date(
              item["DOB_year"]
            );
          } else {
            item[directorFieldNames.SHAREHOLDERDOBFULLFORMAT] = null;
          }

          if (item["PreviousAddress"] && item["PreviousAddress"].length > 0) {
            item["PreviousAddress"].map((item1, index) => {
              let splittedDate = [];
              if (item1["living_since"]) {
                splittedDate = item1["living_since"].split("/");
              } else if (item1["when_move_to_address"]) {
                splittedDate = item1["when_move_to_address"].split("/");
              }
              item1[directorFieldNames.PREVIOUSADDSHAREHOLDERID] = i + 1;

              let date;
              if (splittedDate.length) {
                date = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
              }
              item1[directorFieldNames.WHENTOMOVETOADDRESS] = date || "";

              item1[directorFieldNames.ADDRESS] = item1["address_line_1"];
              item1[directorFieldNames.HOUSENUMBER] = item1["house_number"];
              item1[directorFieldNames.HOUSENAME] = item1["house_name"];
              item1[directorFieldNames.ADDRESSLINE1] =
                item1[directorFieldNames.ADDRESSLINE1];
              item1[directorFieldNames.ADDRESSLINE2] =
                item1[directorFieldNames.ADDRESSLINE2];
              item1[directorFieldNames.STATE] =
                stateList[
                  stateList.findIndex((item2) => item1["state"] == item2.value)
                ];
              item1[directorFieldNames.CITY] = item1[directorFieldNames.CITY];
              item1[directorFieldNames.POSTCODE] =
                item1[directorFieldNames.POSTCODE];

              delete item1["postal_code"];
              delete item1["when_move_to_address"];
              delete item1["house_name"];
              delete item1["house_number"];
              if (currentCountry === "GB") {
                delete item1["address_line_1"];
                delete item1["address_line_2"];
              }
              delete item1["id"];
            });
          }
          item[directorFieldNames.PREVIOUSADDRESS] = item["PreviousAddress"];
          delete item["address"];
          delete item["appointed_on"];
          delete item["links"];
          delete item["date_of_birth"];
          delete item["occupation"];
          delete item["nationality"];
          delete item["resigned_on"];
          delete item["officer_role"];
          delete item["country_of_residence"];
          return item;
        });
      }
      return values;
    }
  };

  const patchInitialData = (data1) => {
    let data = JSON.parse(JSON.stringify(data1));
    // moment(data["AppBusinessStartDate"]).format(
    //   currentCountry === "GB" ? "DD/MM/yyyy" : "MM/DD/yyyy"
    // );
    let initialValues = {
      [fieldNames.BUSINESSSECTOR]: data
        ? businessSectorList[
            businessSectorList.findIndex(
              (item) => data.lf_business_sector == item.value
            )
          ]
        : "",
      [fieldNames.BUSINESSSTARTDATE]: data
        ? new Date(data["AppBusinessStartDate"])
        : "",

      [fieldNames.CARDPAYMENTAMOUNT]: data
        ? data["lf_monthly_credit_card_volume"]
        : "",
      [fieldNames.SUPPLIERDUEAMOUNT]: data
        ? data["AppCurrentValueOverdueInvoices"]
        : "",
      [fieldNames.BUSINESSLEGALNUMBER]: data
        ? data["lmc_bi_business_number"]
        : "",
      [fieldNames.DIRECTORINFO]: patchDirectorData(data),
      country: currentCountry,
    };

    return initialValues;
  };

  const updateDirectorInfo = (payload, id, setAreTruthy, resetForm) => {
    let newPayload = { ...payload };
    if (currentCountry !== "GB") {
      newPayload["previousAddress"].map((item1) => {
        item1.state = item1["state"].value;
        item1[directorFieldNames.ADDRESS] = "";
        item1[directorFieldNames.COUNTY] = "";
        item1[directorFieldNames.HOUSENUMBER] = "";
        item1[directorFieldNames.HOUSENAME] = "";
        item1[directorFieldNames.TOWN] = "";
      });
    }
    updateCustomerInfo(newPayload, userDetails["lead_id"], currentCountry)
      .then((resp) => {
        setLoading(false);
        setIsTouched(false);
        if (resp.isSuccess == 1) {
          ToastMessage("Data saved successfully!", "success");
          resetForm({});
          getData();
        } else {
          ToastMessage("Something went wrong!", "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsTouched(false);
        ToastMessage("Something went wrong!", "error");
      });
  };

  const limitLivingSince = (i, livingSince, prevAddress) => {
    if (i == 0) {
      return livingSince;
    } else if (i == 1) {
      return prevAddress[0]["livingSince"];
    } else {
      return prevAddress[i - 1]["livingSince"];
    }
  };

  function diff_years(dt2, dt1) {
    dt2.setHours(0, 0, 0);

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;

    return (dt2.getTime() - dt1.getTime()) / 31536000000;
  }

  const generateDirectorListPayload = (data1) => {
    let data = [...data1];
    if (data != null) {
      let prevAddress = [];
      let data1 = data.map((item, index) => {
        let day = "";
        let month = "";
        let year = "";
     let countryCode = document
       .getElementsByClassName(`${index}-phone-input`)[0]
       .getElementsByClassName("flag-dropdown")[0]
       .getElementsByClassName("selected-flag")[0]
       .getAttribute("title")
       .split("+")[1];
        if (
          item["ShareHolderDOBFullFormat"] !== null
          // item["ShareHolderDOBFullFormat"].includes("-")
        ) {
          let date = moment(item["ShareHolderDOBFullFormat"]).format(
            "YYYY-MM-DD"
          );
          let splitDate = date.split("-");
          day = splitDate[2];
          month = splitDate[1];
          year = splitDate[0];
        }

        item["ShareHolderDOBFullFormat"] = moment(
          item["ShareHolderDOBFullFormat"]
        ).format("YYYY-MM-DD");

        if (item["previousAddress"] && item["previousAddress"].length) {
          item["previousAddress"].forEach((ele) => {
            let newEle = { ...ele };
            // newEle[directorFieldNames.HOUSENUMBER] = newEle[
            //   directorFieldNames.HOUSENUMBER
            // ]
            //   ? newEle[directorFieldNames.HOUSENUMBER]
            //   : "";
            // newEle[directorFieldNames.HOUSENAME] = newEle[
            //   directorFieldNames.HOUSENAME
            // ]
            //   ? newEle[directorFieldNames.HOUSENAME]
            //   : "";
            // newEle[directorFieldNames.STATE] = newEle[directorFieldNames.STATE]
            //   ? newEle[directorFieldNames.STATE].value
            //   : "";
            prevAddress.push(newEle);
          });
        }


        return {
          kindofShareHolder:
            removeComma(item[directorFieldNames.KINDOFSHAREHOLDER]) || "",
          HiddenShareHolderId:
            item[directorFieldNames.HIDDENSHAREHOLDERID] || "",
          natures_of_control: item[directorFieldNames.NATUREOFCONTROL] || "",
          fullName: item[directorFieldNames.FIRSTNAME],
          lastName: item[directorFieldNames.LASTNAME],
          DOB_day: day || "",
          DOB_month: month || "",
          DOB_year: year || "",
          ShareHolderDOBFullFormat: item["ShareHolderDOBFullFormat"],
          address_line_1: item["address_line_1"] || "",
          address_line_2: item["address_line_2"] || "",
          postal_code: item[directorFieldNames.POSTALCODE] || "",
          notified_on: "",
          phon_number:
            prefixPlusIcon(item[directorFieldNames.PHONENUMBER], countryCode) ||
            "",
          email: item[directorFieldNames.EMAILID],
          residentialStatus: item[directorFieldNames.RESIDENTIALSTATUS]
            ? item[directorFieldNames.RESIDENTIALSTATUS]["value"]
            : "",
          is_primary: item[directorFieldNames.ISPRIMARY] ? "1" : "0",
          house_number: item[directorFieldNames.HOUSE_NUMBER] || "",
          house_name: item[directorFieldNames.HOUSE_NAME] || "",
          county: item[directorFieldNames.COUNTY] || "",
          town: item[directorFieldNames.TOWN] || "",
          livingSince:
            moment(item[directorFieldNames.LIVINGSINCE]).format("YYYY-MM-DD") ||
            "",
          is_active: "1",
          street: item[directorFieldNames.STREET] || "",
          companyName: "undefined",
          city: item[directorFieldNames.CITY],
          state: item[directorFieldNames.STATE]
            ? item[directorFieldNames.STATE].value
            : "",
          [directorFieldNames.JOBTITLE]: item[directorFieldNames.JOBTITLE],
        };
      });
      return { data1, prevAddress };
    }
  };

  return (
    <div className="dashboard-panel">
      <Header />
      <div className="dashboard-body bg-change-color">
        <div className="container-fluid  merchant-body">
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <StickyBox>
              <SiderBarMenu />
            </StickyBox>

            <div className="right-panel-main">
              <h3>
                <i className="fas fa-user-tie" aria-hidden="true"></i> Business
                Information
              </h3>
              <div className="dashboard-box position-relative card dashboard-card">
                {!dasboardData ? (
                  <Loaderspinner size="45px" />
                ) : (
                  <Formik
                    initialValues={patchInitialData(dasboardData)}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    enableReinitialize={dasboardData}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setIsTouched(true);
                      let isTrue;
                      let payload = { ...values };
                      payload["businessStartDate"] = moment(
                        payload["businessStartDate"]
                      ).format("YYYY-MM-DD");
                      payload["cardPaymentAmount"] = removeComma(
                        payload["cardPaymentAmount"]
                      );
                      payload[fieldNames.SUPPLIERDUEAMOUNT] = removeComma(
                        payload[fieldNames.SUPPLIERDUEAMOUNT]
                      );
                      let prevAddress = [];
                      if (payload["directorInfo"]) {
                        prevAddress = generateDirectorListPayload(
                          payload["directorInfo"]
                        ).prevAddress;
                      }

                      if (
                        prevAddress.length &&
                        diff_years(
                          new Date(),
                          new Date(
                            prevAddress[prevAddress.length - 1]["livingSince"]
                          )
                        ) < 3
                      ) {
                        setIsAddedPrevAddress(true);
                        document.getElementById("hidden-btn").click();
                        setAreTruthy(false);
                        prevAddress.push(initialPreviousAddressObj);

                        prevAddress.forEach((ele) => {
                          let truthy;
                          if (currentCountry !== "GB") {
                            Object.keys(ele).map((item, i) => {
                              if (
                                ele["address_line_1"] == "" ||
                                ele["address_line_2"] == "" ||
                                ele["postcode"] == "" ||
                                ele["city"] == "" ||
                                ele["state"]["value"] == "" ||
                                ele["livingSince"] == ""
                              ) {
                                truthy = false;
                              } else {
                                truthy = true;
                              }
                            });
                          } else {
                            Object.keys(ele).map((item, i) => {
                              if (
                                ele["county"] == "" ||
                                ele["houseNumber1"] == "" ||
                                ele["postcode"] == "" ||
                                ele["livingSince"] == "" ||
                                ele["town"] == "" ||
                                ele["address"] == ""
                              ) {
                                truthy = false;
                              } else {
                                truthy = true;
                              }
                            });
                          }

                          isTrue = truthy;
                          setAreTruthy(truthy);
                        });
                      } else if (prevAddress.length) {
                        prevAddress.forEach((ele) => {
                          let truthy;
                          if (currentCountry !== "GB") {
                            Object.keys(ele).map((item, i) => {
                              if (
                                ele["address_line_1"] == "" ||
                                ele["address_line_2"] == "" ||
                                ele["postcode"] == "" ||
                                ele["city"] == "" ||
                                ele["state"]["value"] == "" ||
                                ele["livingSince"] == ""
                              ) {
                                truthy = false;
                                isTrue = false;
                              } else {
                                truthy = true;
                                isTrue = true;
                              }
                            });
                          } else {
                            Object.keys(ele).map((item, i) => {
                              if (
                                ele["county"] == "" ||
                                ele["houseNumber1"] == "" ||
                                ele["postcode"] == "" ||
                                ele["livingSince"] == "" ||
                                ele["town"] == "" ||
                                ele["address"] == ""
                              ) {
                                truthy = false;
                                isTrue = false;
                              } else {
                                truthy = true;
                                isTrue = true;
                              }
                            });
                          }
                          setAreTruthy(truthy);
                        });
                      } else {
                        setAreTruthy(true);
                        isTrue = true;
                      }

                      payload["businessSector"] =
                        payload["businessSector"].value;
                      if (payload["directorInfo"]) {
                        payload["ShareHolderArr"] = generateDirectorListPayload(
                          payload["directorInfo"]
                        ).data1;
                        payload[directorFieldNames.PREVIOUSADDRESS] =
                          generateDirectorListPayload(
                            payload["directorInfo"]
                          ).prevAddress;
                      }
                      if (isTrue) {
                        let newPayload = { ...payload };

                        delete newPayload["directorInfo"];

                        if (
                          newPayload["ShareHolderArr"] &&
                          newPayload["ShareHolderArr"].length
                        ) {
                          let index = newPayload["ShareHolderArr"].findIndex(
                            (item) => item["is_primary"] == 1
                          );
                          if (index === -1) {
                            ToastMessage(
                              "It is mandatrory to mark at least one director as primary!",
                              "error"
                            );
                          } else {
                            setLoading(true);
                            updateDirectorInfo(
                              newPayload,
                              userDetails["lead_id"],
                              setAreTruthy,
                              resetForm
                            );
                          }
                        } else {
                          setLoading(true);

                          updateDirectorInfo(
                            newPayload,
                            userDetails["lead_id"],
                            setAreTruthy,
                            resetForm
                          );
                        }
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="review-application">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Business Sector</label>
                                <Select
                                  closeMenuOnSelect={true}
                                  isLoading={loadingBusinessSectorList}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      fieldNames.BUSINESSSECTOR,
                                      selectedOption
                                    )
                                  }
                                  name={fieldNames.BUSINESSSECTOR}
                                  options={businessSectorList}
                                  placeholder="Enter business sector"
                                  styles={{
                                    control: (styles, state) => {
                                      const borderColor =
                                        !state.hasValue &&
                                        touched[fieldNames.BUSINESSSECTOR] &&
                                        errors[fieldNames.BUSINESSSECTOR]
                                          ? "red"
                                          : "#ced4da";

                                      return { ...styles, borderColor };
                                    },
                                  }}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  value={values[fieldNames.BUSINESSSECTOR]}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group business-date">
                                <label>Business Start Date</label>

                                <DatePicker
                                  required
                                  customInput={
                                    <MaskedInput
                                      mask="11/11/1111"
                                      placeholder={
                                        currentCountry === "GB"
                                          ? "DD/MM/YYYY"
                                          : "MM/DD/YYYY"
                                      }
                                    />
                                  }
                                  selected={
                                    values[fieldNames.BUSINESSSTARTDATE] &&
                                    moment(
                                      values[fieldNames.BUSINESSSTARTDATE]
                                    ).isValid()
                                      ? new Date(
                                          values[fieldNames.BUSINESSSTARTDATE]
                                        )
                                      : null
                                  }
                                  className={clsx(
                                    "form-control ",
                                    {
                                      "is-invalid":
                                        touched[fieldNames.BUSINESSSTARTDATE] &&
                                        errors[fieldNames.BUSINESSSTARTDATE],
                                    },
                                    {
                                      "is-valid":
                                        touched[fieldNames.BUSINESSSTARTDATE] &&
                                        !errors[fieldNames.BUSINESSSTARTDATE],
                                    }
                                  )}
                                  dateFormat={
                                    currentCountry === "GB"
                                      ? "dd/MM/yyyy"
                                      : "MM/dd/yyyy"
                                  }
                                  placeholderText={
                                    currentCountry === "GB"
                                      ? "DD/MM/YYYY"
                                      : "MM/DD/YYYY"
                                  }
                                  onChange={(value) => {
                                    if (value !== null) {
                                      let fieldValue = moment(value).isValid()
                                        ? new Date(value)
                                        : null;

                                      setFieldValue(
                                        fieldNames.BUSINESSSTARTDATE,
                                        fieldValue
                                      );
                                    }
                                  }}
                                />

                                {/* <input
                                  type="date"
                                  placeholder="Enter business start date"
                                  name={fieldNames.BUSINESSSTARTDATE}
                                  className={clsx(
                                    "form-control ",
                                    {
                                      "is-invalid":
                                        touched[fieldNames.BUSINESSSTARTDATE] &&
                                        errors[fieldNames.BUSINESSSTARTDATE],
                                    },
                                    {
                                      "is-valid":
                                        touched[fieldNames.BUSINESSSTARTDATE] &&
                                        !errors[fieldNames.BUSINESSSTARTDATE],
                                    }
                                  )}
                                  onChange={handleChange}
                                  value={values[fieldNames.BUSINESSSTARTDATE]}
                                /> */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Monthly Card Takings</label>
                                <div className="input-group mb-3">
                                  <CurrencyIcon />
                                  <input
                                    type="text"
                                    placeholder="90,000"
                                    name={fieldNames.CARDPAYMENTAMOUNT}
                                    className={clsx(
                                      "form-control ",
                                      {
                                        "is-invalid":
                                          touched[
                                            fieldNames.CARDPAYMENTAMOUNT
                                          ] &&
                                          errors[fieldNames.CARDPAYMENTAMOUNT],
                                      },
                                      {
                                        "is-valid":
                                          touched[
                                            fieldNames.CARDPAYMENTAMOUNT
                                          ] &&
                                          !errors[fieldNames.CARDPAYMENTAMOUNT],
                                      }
                                    )}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        if (
                                          numberRegex.test(
                                            removeComma(e.target.value)
                                          )
                                        ) {
                                          setFieldValue(
                                            fieldNames.CARDPAYMENTAMOUNT,
                                            e.target.value
                                          );
                                        } else {
                                          setFieldValue(
                                            fieldNames.CARDPAYMENTAMOUNT,
                                            values[fieldNames.CARDPAYMENTAMOUNT]
                                          );
                                        }
                                      } else {
                                        setFieldValue(
                                          fieldNames.CARDPAYMENTAMOUNT,
                                          ""
                                        );
                                      }
                                    }}
                                    value={formatNumberInput(
                                      values[fieldNames.CARDPAYMENTAMOUNT]
                                    )}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Business Invoiced</label>
                                <div className="input-group mb-3">
                                  <CurrencyIcon />
                                  <input
                                    type="text"
                                    placeholder="Business Invoiced"
                                    name={fieldNames.SUPPLIERDUEAMOUNT}
                                    className={clsx(
                                      "form-control ",
                                      {
                                        "is-invalid":
                                          touched[
                                            fieldNames.SUPPLIERDUEAMOUNT
                                          ] &&
                                          errors[fieldNames.SUPPLIERDUEAMOUNT],
                                      },
                                      {
                                        "is-valid":
                                          touched[
                                            fieldNames.SUPPLIERDUEAMOUNT
                                          ] &&
                                          !errors[fieldNames.SUPPLIERDUEAMOUNT],
                                      }
                                    )}
                                    min="0"
                                    onChange={handleChange}
                                    value={formatNumberInput(
                                      values[fieldNames.SUPPLIERDUEAMOUNT]
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            {currentCountry === "GB" && (
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Business Legal Number</label>
                                  <input
                                    type="text"
                                    placeholder="Business Legal Number"
                                    name={fieldNames.BUSINESSLEGALNUMBER}
                                    className={clsx(
                                      "form-control ",
                                      {
                                        "is-invalid":
                                          touched[
                                            fieldNames.BUSINESSLEGALNUMBER
                                          ] &&
                                          errors[
                                            fieldNames.BUSINESSLEGALNUMBER
                                          ],
                                      },
                                      {
                                        "is-valid":
                                          touched[
                                            fieldNames.BUSINESSLEGALNUMBER
                                          ] &&
                                          !errors[
                                            fieldNames.BUSINESSLEGALNUMBER
                                          ],
                                      }
                                    )}
                                    min="0"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        if (numberRegex.test(e.target.value)) {
                                          setFieldValue(
                                            fieldNames.BUSINESSLEGALNUMBER,
                                            e.target.value
                                          );
                                        } else {
                                          setFieldValue(
                                            fieldNames.BUSINESSLEGALNUMBER,
                                            values[
                                              fieldNames.BUSINESSLEGALNUMBER
                                            ]
                                          );
                                        }
                                      } else {
                                        setFieldValue(
                                          fieldNames.BUSINESSLEGALNUMBER,
                                          ""
                                        );
                                      }
                                    }}
                                    value={
                                      values[fieldNames.BUSINESSLEGALNUMBER]
                                    }
                                  />
                                  {errors[fieldNames.BUSINESSLEGALNUMBER] &&
                                  touched[fieldNames.BUSINESSLEGALNUMBER] ? (
                                    <div className="invalid-feedback">
                                      {errors[fieldNames.BUSINESSLEGALNUMBER]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                          {
                            <div className="row">
                              <div className="col-md-12">
                                <div className="director-panel">
                                  {values.directorInfo &&
                                    values.directorInfo.length > 0 && (
                                      <h4>
                                        Directors of{" "}
                                        {leadDetails &&
                                          leadDetails["lf_business_name"]}
                                      </h4>
                                    )}

                                  <FieldArray
                                    name={fieldNames.DIRECTORINFO}
                                    render={(arrayHelpers) => (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-primary bg-app-primary"
                                          onClick={() => {
                                            if (
                                              values.directorInfo &&
                                              values.directorInfo.length > 0
                                            ) {
                                              arrayHelpers.insert(
                                                values.directorInfo.length + 1,
                                                initialDirectorObject
                                              );
                                            } else {
                                              initialDirectorObject[
                                                directorFieldNames.ISPRIMARY
                                              ] = true;
                                              arrayHelpers.insert(
                                                0,
                                                initialDirectorObject
                                              );
                                            }
                                          }}
                                        >
                                          Add Director
                                        </button>
                                        {values.directorInfo &&
                                          values.directorInfo.length > 0 &&
                                          values.directorInfo.map(
                                            (item, index) => (
                                              <Accordion
                                                title={
                                                  item[
                                                    directorFieldNames.FIRSTNAME
                                                  ] +
                                                    " " +
                                                    item[
                                                      directorFieldNames
                                                        .LASTNAME
                                                    ] || ""
                                                }
                                                key={index}
                                                isPrimary={
                                                  item[
                                                    directorFieldNames.ISPRIMARY
                                                  ]
                                                }
                                                id={`accordian${index}`}
                                              >
                                                <DirectorInfo
                                                  initialPreviousAddressObj={
                                                    initialPreviousAddressObj
                                                  }
                                                  setIsAddedPrevAddress={
                                                    setIsAddedPrevAddress
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  values={values}
                                                  index={index}
                                                  item={item}
                                                  handleChange={handleChange}
                                                  isTouched={isTouched}
                                                  isAddedPrevAddress={
                                                    isAddedPrevAddress
                                                  }
                                                  errors={errors}
                                                  touched={touched}
                                                  generateDirectorListPayload={
                                                    generateDirectorListPayload
                                                  }
                                                  setAreTruthy={setAreTruthy}
                                                  stateList={stateList}
                                                  stateListLoading={
                                                    stateListLoading
                                                  }
                                                />
                                              </Accordion>
                                            )
                                          )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <button
                          className="btn btn-primary save-btn next-btn"
                          type="submit"
                          disabled={loading}
                        >
                          Save <i className="fa fa-file-image-o"></i>
                        </button>
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessInformation;
