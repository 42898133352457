import React, { useEffect, useState } from "react";
import Header from "../header/header";
import StickyBox from "react-sticky-box";
import SiderBarMenu from "../merchant-dashboard/component/sidebar";
import { useAppSelector } from "../../redux/hooks/hooks";
import DataTable from "react-data-table-component";
import { getInvitedUsers } from "../../request";
import { getUserDetails } from "../login/loginpage";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Loaderspinner from "../loader";
import { useNavigate } from "react-router";

export default function UserList() {
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const navigate = useNavigate();
  const userDetails = getUserDetails();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const data1 = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      className="btn btn-light option-btn"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
    </button>
  ));
  useEffect(() => {
    setLoading(true);
    getInvitedUsers(userDetails.lead_id)
      .then((resp) => {
        let list = [];
        for (const property in resp.data) {
          list.push(resp.data[property]);
        }
        setData(list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      name: "First Name",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row) => row.sender_email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Options",
      cell: (row) => {
        return (
          <>
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                Custom toggle
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div
                  className="dropdown-item"
                  onClick={() =>
                    navigate(`/edit-user/${row.id}`, { state: row })
                  }
                >
                  <i className="fa fa-pencil pr-2" aria-hidden="true"></i>
                  Edit User
                </div>
                <div className="dropdown-item">
                  <i class="fa fa-trash pr-2" aria-hidden="true"></i>
                  Remove User
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  return (
    <div className="dashboard-panel  user-table">
      <Header />
      <div className="dashboard-body bg-change-color">
        <div className="container-fluid  merchant-body">
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <StickyBox>
              <SiderBarMenu />
            </StickyBox>
            <div className="right-panel-main">
              <div className="dashboard-box position-relative card dashboard-card">
                <h3>
                  <i className="fa fa-users" aria-hidden="true"></i> Users
                </h3>
                <div>
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<Loaderspinner size="45px" />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
