import React from "react";
import Datatables from "./Datatables";
import Parser from 'html-react-parser';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export default function Transfers(props) {

    const columns = [
      {
        name: "Transfer",
        selector: (row) => Parser(row.description),
        sortable: true,
      },
      {
        name: "From",
        selector: (row) => (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">{row.from}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <div
                ref={ref}
                {...triggerHandler}
                className="ml-2 cursor-pointer ellipsis-text"
              >
                {row.from}{" "}
              </div>
            )}
          </OverlayTrigger>
        ),
        sortable: true,
      },
      {
        name: "To",
        selector: (row) => (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">{row.to}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <div
                ref={ref}
                {...triggerHandler}
                className="ml-2 cursor-pointer ellipsis-text"
              >
                {row.to}{" "}
              </div>
            )}
          </OverlayTrigger>
        ),
        sortable: true,
      },
      {
        name: "Transfer date",
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: "From Currency",
        selector: (row) => row.currency,
        sortable: true,
      },
      {
        name: "From Amount",
        selector: (row) => row.amount,
        sortable: true,
      },
    ];

        const finalUrl = `${props.endUrl}/CODAT/Transfers/${props.leadId}`;

      return (
        <Datatables title={props.title} apiUrl={finalUrl} apiColumn={columns} />
    );
}