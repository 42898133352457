import axios from "axios";
import { setStepNo } from "./component/requestaquote/components/request-leftpanel";

export const UK_LOGIN = "UKCustomerLogin";
export const US_LOGIN = "USCustomerLogin";
export const CA_LOGIN = "canadaCustomerLogin";

export const UK_CREATE_CUSTOMER = "CreateCustomerForUK";
export const US_CREATE_CUSTOMER = "CreateCustomerForUS";
export const CA_CREATE_CUSTOMER = "CreateCustomerForcanada";

export const UK_PASSWORD_CHANGE = "UKPasswordChange";
export const US_PASSWORD_CHANGE = "USPasswordChange";
export const CA_PASSWORD_CHANGE = "canadaPasswordChange";

export const UK_PASSWORD_RESET = "UKChangePassword";
export const US_PASSWORD_RESET = "USChangePassword";
export const CA_PASSWORD_RESET = "canadaChangePassword";

export const UK_FORGOT_PASSWORD = "UKForgetPassword";
export const US_FORGOT_PASSWORD = "USForgetPassword";
export const CA_FORGOT_PASSWORD = "CAForgetPassword";

export const UK_UPDATE_CUSTOMER = "UpdateCustomerForUK";
export const US_UPDATE_CUSTOMER = "UpdateCustomerForUS";
export const CA_UPDATE_CUSTOMER = "UpdateCustomerForcanada";

export const LEAD_DETAILS = "GetLeadMaster";
export const UK_USER_DETAILS = "getUkCustomerDetails";
export const US_USER_DETAILS = "getUsCustomerDetails";
export const CA_USER_DETAILS = "getcanadaCustomerDetails";

export const UK_VERIFY_CUSTOMER = "UKCustomerVerify";
export const US_VERIFY_CUSTOMER = "USCustomerVerify";
export const CA_VERIFY_CUSTOMER = "canadaCustomerVerify";

export const UK_INVITE_USER = "Ukinvitation";
export const US_INVITE_USER = "Usinvitation";
export const CA_INVITE_USER = "canadainvitation";

export const GET_INVITED_USERS = "get_invited";

export const API_URL = process.env.REACT_APP_STAGING_API_URL;
export const SEARCH_COMPANY_URL_UK = `${API_URL}api/SearchCompanies.php?SearchValue=`;
export const SEARCH_COMPANY_URL_US = `${API_URL}api/SearchCompaniesUS.php?SearchValue=`;
export const CREATE_ACCOUNT_URL = `${API_URL}api/CreateCustomerForUK`;
export const USER_LOGIN_URL = `${API_URL}api/UKCustomerLogin`;
export const DASHBOARD_DATA_URL = `${API_URL}api/GetLeadMaster/`;
export const VERIFY_ACCOUNT_URL = `${API_URL}api/UKCustomerVerify`;
export const FORGOT_PASSWORD_URL = `${API_URL}api/UKForgetPassword`;
export const DIRECTOR_LIST_API_UK = `${API_URL}api/SearchCompanies.php?companyNumber=`;
export const DIRECTOR_LIST_API_US = `${API_URL}api/SearchCompaniesUS.php?companyNumber=`;
export const LINK_ACCOUTING_URL = `${API_URL}api/CODAT/CreateCodatAccount`;
export const CHANGE_PASSWORD_URL = `${API_URL}api/UKPasswordChange`;
export const USER_DETAILS_URL = `${API_URL}api/getUkCustomerDetails/`;
export const UPDATE_USER_DETAILS_URL = `${API_URL}api/UpdateCustomerForUK/`;

export const RESET_PASSWORD_URL = `${API_URL}api/UKChangePassword`;

// app-uat.codat.io/
export const CODAT_BASE_URL = process.env.REACT_APP_PROD_CODAT_BASE_URL;

export const FINANCIAL_SERVICE_URL = `${API_URL}api/accountScore/5944/FinancialServices`;
export const INCOME_ANALYSIS_URL = `${API_URL}api/accountScore/5944/IncomeAnalysis`;
export const REGULAR_OUTGOINGS_URL = `${API_URL}api/accountScore/5944/RegularOutgoings`;
export const EVENTFEED_URL = `${API_URL}api/accountScore/5944/EventFeed`;
export const UPDATE_LOGIN_TIMES_URL = `${API_URL}api/UpdateCustomerLoginTimes/`;
export const UPLOAD_DOCUMENTS = `${API_URL}api/UKLeadattachments/`;
export const GET_DOCUMENTS = `${API_URL}api/getLeadMasterAttachmentsUK/`;
export const GET_ALL_DOCUMENTS = `${API_URL}api/GetLeadMasterAttachments/`;

export const GET_DOCUMENTS_BANK_STMT = `${API_URL}api/getLeadMasterAttachments/`;
export const DELETE_DOCUMENTS = `${API_URL}api/DeleteLeadMasterAttachment/`;
export const GET_BUSINESS_ACCOUNT_SCORE = `${API_URL}api/creditsafe/company/`;

export const UPLOAD_FILES = `${API_URL}api/LeadAttachmentsFile/`;

export const GET_BUSINESS_SECTOR_UK = `get_business_sectors/UK`;
export const GET_BUSINESS_SECTOR_US = `get_business_sectors/US`;

export const GET_STATE_UK = `get_business_state_province/UK`;
export const GET_STATE_US = `get_business_state_province/US`;
export const GET_STATE_CA = `get_business_state_province/CA`;
export const GET_CREDIT_SAFE_REPORT = `${API_URL}api/creditsafe/company`;

export function createAccount(payload, country) {
  return axios.post(
    `${API_URL}api/${
      country === "GB"
        ? UK_CREATE_CUSTOMER
        : country === "US"
        ? US_CREATE_CUSTOMER
        : CA_CREATE_CUSTOMER
    }`,
    payload
  );
}

export function login(email, password, country) {
  return axios.post(
    `${API_URL}api/${
      country === "GB" ? UK_LOGIN : country === "US" ? US_LOGIN : CA_LOGIN
    }`,
    {
      email,
      password,
      country,
    }
  );
}

export function changePassword(payload, country) {
  return axios.post(
    `${API_URL}api/${
      country === "GB"
        ? UK_PASSWORD_CHANGE
        : country === "US"
        ? US_PASSWORD_CHANGE
        : CA_PASSWORD_CHANGE
    }`,
    payload
  );
}

export function resetPassword(payload, country) {
  return axios.post(
    `${API_URL}api/${
      country === "GB"
        ? UK_PASSWORD_RESET
        : country === "US"
        ? US_PASSWORD_RESET
        : CA_PASSWORD_RESET
    }`,
    payload
  );
}

export async function getInvitedUsers(id) {
  const { data } = await axios.get(`${API_URL}api/${GET_INVITED_USERS}/${id}`);
  return data;
}

export async function getBusinessSectorList(country) {
  return axios.get(
    `${API_URL}api/${
      country === "GB" ? GET_BUSINESS_SECTOR_UK : GET_BUSINESS_SECTOR_US
    }`
  );
}

export async function getBusinessStateList(country) {
  return axios.get(
    `${API_URL}api/${
      country === "GB"
        ? GET_STATE_UK
        : country === "US"
        ? GET_STATE_US
        : GET_STATE_CA
    }`
  );
}

export async function getDashboardData(id, country) {
  const { data } = await axios.get(
    `${API_URL}api/${
      country === "GB"
        ? LEAD_DETAILS
        : country === "US"
        ? LEAD_DETAILS
        : LEAD_DETAILS
    }/${id}`
  );
  return data;
}

export function verifyAccount(token, country) {
  return axios.post(
    `${API_URL}api/${
      country.toUpperCase() === "UK"
        ? UK_VERIFY_CUSTOMER
        : country.toUpperCase() === "US"
        ? US_VERIFY_CUSTOMER
        : CA_VERIFY_CUSTOMER
    }`,
    {
      token: token,
    }
  );
}

export function forgotPassword(payload, country) {
  return axios.post(
    `${API_URL}api/${
      country === "GB"
        ? UK_FORGOT_PASSWORD
        : country === "US"
        ? US_FORGOT_PASSWORD
        : CA_FORGOT_PASSWORD
    }`,
    payload
  );
}

export function sendInvite(payload, country) {
  return axios.post(
    `${API_URL}api/${
      country === "GB"
        ? UK_INVITE_USER
        : country === "US"
        ? US_INVITE_USER
        : CA_INVITE_USER
    }`,
    payload
  );
}

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("dashboardStepNumber");
  localStorage.removeItem("personalInfo");
  localStorage.removeItem("applicationInfo");
  localStorage.removeItem("companyInfo");
  localStorage.removeItem("reviewPersonalInfo");
  localStorage.removeItem("reviewBusinessInfo");
  localStorage.removeItem("reviewAppInfo");
  localStorage.removeItem("businessInfo");
  localStorage.removeItem("directorData");
  localStorage.removeItem("merchantDirectorData");
  localStorage.removeItem("provideConcentData");
  localStorage.removeItem("activeTabIndex");
  localStorage.removeItem("uploadBankStatement");
  localStorage.removeItem("open");
  setStepNo(1);
};

export async function getDirectorList(id, country) {
  const { data } = await axios.get(
    `${country === "GB" ? DIRECTOR_LIST_API_UK : DIRECTOR_LIST_API_US}${id}`
  );
  return data;
}

export async function getLinkToAccountingData(payload) {
  const { data } = await axios.post(`${LINK_ACCOUTING_URL}`, payload);
  return data;
}

export async function getCompanyID(id) {
  const { data } = await axios.get(`${API_URL}api/CODAT/${id}/getCompanyId`);
  return data;
}

export async function checkAccountingStatus(id) {
  const { data } = await axios.get(`${API_URL}api/CODAT/${id}/checkStatus`);
  return data;
}

export async function getUserDetailsApi(id, country) {
  // const { data } = await axios.get(`${USER_DETAILS_URL}${id}`);
  const { data } = await axios.get(
    `${API_URL}api/${
      country === "GB"
        ? UK_USER_DETAILS
        : country === "US"
        ? US_USER_DETAILS
        : CA_USER_DETAILS
    }/${id}`
  );
  return data;
}

export async function updateCustomerInfo(payload, id, country) {
  const { data } = await axios.post(
    `${API_URL}api/${
      country === "GB"
        ? UK_UPDATE_CUSTOMER
        : country === "US"
        ? US_UPDATE_CUSTOMER
        : CA_UPDATE_CUSTOMER
    }/${id}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
}

export async function getBankingFinancialServices(id) {
  const { data } = await axios.get(
    `${API_URL}api/accountScore/${id}/FinancialServices`
  );
  return data;
}

export async function getBankingIncome(id) {
  const { data } = await axios.get(
    `${API_URL}api/accountScore/${id}/IncomeAnalysis`
  );
  return data;
}

export async function getRegularOutgoings(id) {
  const { data } = await axios.get(
    `${API_URL}api/accountScore/${id}/RegularOutgoings`
  );
  return data;
}

export async function getEventFeed(id) {
  const { data } = await axios.get(
    `${API_URL}api/accountScore/${id}/EventFeed`
  );

  return data;
}

export async function getAccountScore(id, payload) {
  const { data } = await axios.post(
    `${API_URL}api/leadUK/${id}/generateConsentForAccountScore`,
    payload
  );
  return data;
}

export async function checkBankingStatus(id) {
  const { data } = await axios.get(
    `${API_URL}api/leadUK/${id}/checkObvAccountScoreConsentStatus`
  );
  return data;
}

export async function bankingInsightsDownloadFile(fileType, id) {
  const { data } = await axios.get(
    `${API_URL}api/accountScore/downloadfile/${fileType}/${id}`
  );
  return data;
}

export async function updateLoginTimes(payload) {
  const { data } = await axios.post(UPDATE_LOGIN_TIMES_URL, payload);
  return data;
}

export async function uploadDocuments(payload, id) {
  const { data } = await axios.post(`${UPLOAD_DOCUMENTS}${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
}

export async function getDocuments(id) {
  const { data } = await axios.get(`${GET_DOCUMENTS}${id}`);
  return data;
}

export async function getAllDocuments(id) {
  const { data } = await axios.get(`${GET_ALL_DOCUMENTS}${id}`);
  return data;
}

export async function uploadFiles(id, payload) {
  const { data } = await axios.post(`${UPLOAD_FILES}${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
}

export async function getDocumentBankStatements(id) {
  const { data } = await axios.get(`${GET_DOCUMENTS_BANK_STMT}${id}`);
  return data;
}

export async function deleteDocuments(id) {
  const { data } = await axios.post(`${DELETE_DOCUMENTS}${id}`);
  return data;
}

export async function getBusinessAccountScore(id, country) {
  const { data } = await axios.get(
    `${GET_BUSINESS_ACCOUNT_SCORE}${id}/${
      country === "GB" ? "creditSafeReportUK" : "creditSafeReportUS"
    }`
  );
  return data;
}

export async function downloadBusinessAccountScore(id, country) {
  const { data } = await axios.get(
    `${GET_BUSINESS_ACCOUNT_SCORE}${id}/${country === "GB" ? "creditSafeReportUKPDF" : "creditSafeReportUSPDF"
    }`
  );
  return data;
}

export async function getCreditSafeReport(id) {
  const { data } = await axios.get(
    `${GET_CREDIT_SAFE_REPORT}/${id}/creditSafeTableReportapp/623129/IOhjOzv9gSKuTfVmzsXOfYouTOu8IfQfH14X1vn0Cis=`
  );
  return data;
}

//  {
//     headers: {
//       Teamid: "623129",
//       TeamKey: "IOhjOzv9gSKuTfVmzsXOfYouTOu8IfQfH14X1vn0Cis=",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Credentials": "true",
//       "Access-Control-Allow-Headers":
//         "Origin, Content-Type, Accept, Authorization, X-Request-With",
//     },
//   }
