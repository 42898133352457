import React from "react";
import Datatables from "./Datatables";
import Parser from "html-react-parser";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export default function DirectCost(props) {
  const columns = [
    {
      name: "Contact",
      selector: (row) => Parser(row.contactRef),
      sortable: true,
    },
    {
      name: "Reference",
      selector: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">{row.reference}</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <div
              ref={ref}
              {...triggerHandler}
              className="ml-2 cursor-pointer ellipsis-text"
            >
              {row.reference}{" "}
            </div>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      name: "Account",
      selector: (row) => <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">{row.paymentAllocations}</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <div
              ref={ref}
              {...triggerHandler}
              className="ml-2 cursor-pointer ellipsis-text"
            >
              {row.paymentAllocations}{" "}
            </div>
          )}
        </OverlayTrigger>,
      sortable: true,
    },
    {
      name: "Issue Date",
      selector: (row) => row.issueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
    },
  ];

  const finalUrl = `${props.endUrl}/CODAT/Direct_costs/${props.leadId}`;

  return (
    <Datatables title={props.title} apiUrl={finalUrl} apiColumn={columns} />
  );
}
