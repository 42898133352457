import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-link";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import axios from "axios";
import clsx from "clsx";
import * as Yup from "yup";
import { setStepNo } from "./request-leftpanel";
import { setCompanyInfo } from "./business-information";
import { NavLink } from "react-router-dom";
import {
  getBusinessStateList,
  SEARCH_COMPANY_URL,
  SEARCH_COMPANY_URL_UK,
  SEARCH_COMPANY_URL_US,
} from "../../../request";
import { formatNumberInput, numberRegex, removeComma } from "../../Constants";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks/hooks";
import { TRIGGER_CURRENT_COUNTRY } from "../../../redux/actions/actionTypes";
import ReactFlagsSelect from "react-flags-select";
import { RegionDropdown } from "react-country-region-selector";

import CurrencyIcon from "../../CurrencyIcon";
import useGeoLocation from "react-ipgeolocation";

export const fieldNames = {
  AMOUNT: "amount",
  LOANPURPOSE: "loanPurpose",
  REQUIREDFUND: "requiredFund",
  BUSINESSENTITY: "businessEntity",
  BUSINESSNAME: "businessName",
  BUSINESSSECTOR: "businessSector",
  BUSINESSSTARTDATE: "businessStartDate",
  ISPAYMENTPROCESSED: "isPaymentProcessed",
  CARDPAYMENTAMOUNT: "cardPaymentAmount",
  ISPAYMENTPENDING: "isPaymentPending",
  SUPPLIERDUEAMOUNT: "supplierDueAmount",
  FIRSTNAME: "firstName",
  LASTNAME: "lastName",
  EMAIL: "email",
  PHONE: "phone",
  PASSWORD: "password",
  CONFIRMPASSWORD: "confirmPassword",
  DIRECTORINFO: "directorInfo",
  NATUREOFCONTROL: "natures_of_control",
  TOTALSHARECOUNT: "totalShareCount",
  DATEOFBIRTH: "dateOfBirth",
  POSTCODE: "postal_code",
  ADDRESS: "address_line_1",
  HOUSENUMBER: "houseNumber",
  HOUSENAME: "houseName",
  STREET: "street",
  COUNTY: "county",
  TOWN: "town",
  RESIDENTIALSTATUS: "residentialStatus",
  LIVINGSINCE: "living_since",
  BUSINESSLEGALNUMBER: "businessLegalNumber",
  ISPRIMARY: "is_primary",
  PHONENUMBER: "phonenumber",
  EMAILID: "email_id",
  CHOOSEADDRESS: "choose_address",
  CURRENTPASSWORD: "currentpassword",
  STATE: "state",
};

export const loadPurposeList = [
  { value: "42001", label: "Cash Flow / Working Capital" },
  { value: "42002", label: "Expansion" },
  { value: "42003", label: "Stock" },
  { value: "42004", label: "Tax / VAT" },
  { value: "42005", label: "Hiring Staff" },
  { value: "42006", label: "Debt Refinance" },
  { value: "42007", label: "Invoice Finance" },
  { value: "42008", label: "Trade Internationally" },
  { value: "42009", label: "Starting a business" },
  { value: "42010", label: "Refurbishments" },
  { value: "42011", label: "Insolvency/restructuring" },
  { value: "42012", label: "Purchasing Assets - Vehicles - Construction" },
  { value: "42013", label: "Purchasing Assets - Vehicles - Haulage" },
  { value: "42014", label: "Purchasing Assets - Vehicles - Agricultural" },
  { value: "42015", label: "Purchasing Assets - Vehicles - Company Cars" },
  { value: "42016", label: "Purchasing Assets - Machinery - Bespoke" },
  { value: "42017", label: "Purchasing Assets - Machinery - General" },
  { value: "42018", label: "Purchasing Assets - Equipment - Construction" },
  { value: "42019", label: "Purchasing Assets - Equipment - Agricultural" },
  { value: "42020", label: "Purchasing Assets - Equipment - Printing" },
  { value: "42021", label: "Purchasing Assets - Equipment - Plant" },
  { value: "42022", label: "Purchasing Assets - Other - Detailed" },
  { value: "42023", label: "Property Refinance - Commercial" },
  { value: "42024", label: "Property Refinance - Residential Buy-to-let" },
  { value: "42025", label: "Property Development - Land without planning" },
  { value: "42026", label: "Property Development - Land with planning" },
  {
    value: "42027",
    label: "Property Development - Existing building(s) without planning",
  },
  {
    value: "42028",
    label: "Property Development - Existing building(s) with planning",
  },
  { value: "42029", label: "Property Bridging - Commercial - Owner Occupied" },
  { value: "42030", label: "Property Bridging - Commercial - Buy-to-let" },
  { value: "42031", label: "Property Bridging - Residential - Buy-to-let" },
  { value: "42032", label: "Property Bridging - Land for development" },
  {
    value: "42033",
    label: "Property Purchase - Commercial premises (current)",
  },
  { value: "42034", label: "Property Purchase - Commercial premises (new)" },
  { value: "42035", label: "Property Purchase - Buy-to-let (commercial)" },
  { value: "42036", label: "Property Purchase - Buy-to-let (residential)" },
  { value: "42037", label: "Other" },
  { value: "42038", label: "Management Buy-Out" },
];

export const businessEntityList = [
  { value: "Sole Trader", label: "Sole Trader" },
  { value: "Non-Ltd Partnership", label: "Non-Ltd Partnership" },
  { value: "Private Limited Company", label: "Private limited company" },
  { value: "Public Limited Company", label: "Public limited company" },
  { value: "Old Public Company", label: "Old public company" },
  { value: "Limited Partnership", label: "Limited partnership" },
  {
    value: "Private Limited Company use of 'Limited' exemption",
    label: "Private Limited Company use of 'Limited' exemption",
  },
  { value: "Northern Ireland Company", label: "Northern Ireland company" },
  {
    value: "Limited Liability Partnership",
    label: "Limited liability partnership ",
  },
  { value: "Unregistered Company", label: "Unregistered company" },
  { value: "UK Establishment Company", label: "UK establishment company" },
  {
    value: "Scottish Qualifying Partnership",
    label: "Scottish qualifying partnership",
  },
];

export const businessEntityListUs = [
  { value: "Limited Liability Company", label: "Limited Liability Company" },
  { value: "Partnership", label: "Partnership" },
  { value: "Corporation", label: "Corporation" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
];

export const businessEntityListCanada = [
  { value: "Corporation", label: "Corporation" },
  { value: "Partnership", label: "Partnership" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Cooperative", label: "Cooperative" },
];

export const validationSchema = Yup.object().shape({
  [fieldNames.AMOUNT]: Yup.string().required(),
  [fieldNames.REQUIREDFUND]: Yup.string().required(),
  [fieldNames.LOANPURPOSE]: Yup.string().required(),
  [fieldNames.BUSINESSENTITY]: Yup.string().required(),
  [fieldNames.BUSINESSNAME]: Yup.string().required(),
  [fieldNames.STATE]: Yup.string(),
});

export const validationSchemaUS = Yup.object().shape({
  [fieldNames.AMOUNT]: Yup.string().required(),
  [fieldNames.REQUIREDFUND]: Yup.string().required(),
  [fieldNames.LOANPURPOSE]: Yup.string().required(),
  [fieldNames.BUSINESSENTITY]: Yup.string().required(),
  [fieldNames.BUSINESSNAME]: Yup.string().required(),
  [fieldNames.STATE]: Yup.string().required(),
});

export const selectBusinessEntityList = (currentCountry) => {
  let entityList = [];
  switch (currentCountry) {
    case "US":
      entityList = businessEntityListUs;
      break;
    case "CA":
      entityList = businessEntityListCanada;
      break;
    case "GB":
      entityList = businessEntityList;
      break;
  }

  return entityList;
};

export const checkCompanyType = (type, country) => {
  if (country == "GB") {
    let listUk = [
      "Private Limited Company",
      "Public Limited Company",
      "Limited Partnership",
      "Private Limited Company use of 'Limited' exemption",
      "Limited Liability Partnership",
      "UK Establishment Company",
    ];

    // let listUS = ["Limited Liability Company", "Partnership", "Corporation"];
    // let listCA = ["Partnership", "Corporation"];
    // let list = [];
    // if (country === "GB") {
    //   list = listUk;
    // }
    let list = [
      "Private Limited Company",
      "Public Limited Company",
      "Limited Partnership",
      "Private Limited Company use of 'Limited' exemption",
      "Limited Liability Partnership",
      "UK Establishment Company",
    ];
    let index = list.findIndex((item) => {
      return item == type.value;
    });

    if (index === -1) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getApplicationInfo = () => {
  return JSON.parse(localStorage.getItem("applicationInfo"));
};

export const loadOptions = async (inputValue) => {
  const res = await axios.get(`${SEARCH_COMPANY_URL_UK}${inputValue}`);
  const data = res.data.items;

  let list = res.data.items.map((item) => {
    return { label: item.title, value: item.title, data: item };
  });

  return list;
};

function ApplicationInformation({ setStep, showSelectedState }) {
  const storedData = getApplicationInfo();
  const location = useGeoLocation();
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const validationSchema = Yup.object().shape({
    [fieldNames.AMOUNT]: Yup.string().required(),
    [fieldNames.REQUIREDFUND]: Yup.string().required(),
    [fieldNames.LOANPURPOSE]: Yup.string().required(),
    [fieldNames.BUSINESSENTITY]: Yup.string().required(),
    [fieldNames.BUSINESSNAME]: Yup.string().required(),
    country: Yup.string(),
    [fieldNames.STATE]: Yup.string().when("country", {
      is: (value) => {
        return value == "GB" ? false : true;
      },
      then: Yup.string().required(),
    }),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.country) {
      dispatch({
        type: TRIGGER_CURRENT_COUNTRY,
        currentCountry:
          location.country &&
          (location.country === "US" ||
            location.country === "CA" ||
            location.country === "GB")
            ? location.country
            : "GB",
      });
    }
  }, [location.country]);

  const loadOptions = async (inputValue) => {
    const res = await axios.get(
      currentCountry === "GB"
        ? `${SEARCH_COMPANY_URL_UK}${inputValue}`
        : `${SEARCH_COMPANY_URL_US}${inputValue}`
    );
    const data = res.data.documents;

    let list;
    if (currentCountry === "GB") {
      list = res.data.items.map((item) => {
        return { label: item.title, value: item.title, data: item };
      });
    } else {
      list = res.data.documents.map((item) => {
        return { label: item.name, value: item.name, data: item };
      });
    }

    return list;
  };
  const initialValues = {
    [fieldNames.AMOUNT]: storedData ? storedData[fieldNames.AMOUNT] : "",
    [fieldNames.REQUIREDFUND]: storedData
      ? storedData[fieldNames.REQUIREDFUND]
      : "",
    [fieldNames.LOANPURPOSE]: storedData
      ? storedData[fieldNames.LOANPURPOSE]
      : "",
    [fieldNames.BUSINESSENTITY]: storedData
      ? storedData[fieldNames.BUSINESSENTITY]
      : "",
    [fieldNames.BUSINESSNAME]: storedData
      ? storedData[fieldNames.BUSINESSNAME]
      : "",
    [fieldNames.STATE]: storedData ? storedData[fieldNames.STATE] : "",
    country: currentCountry,
  };

  const setApplicationInfo = (info) => {
    localStorage.setItem("applicationInfo", JSON.stringify(info));
  };

  const selectCountry = (val) => {
    dispatch({
      type: TRIGGER_CURRENT_COUNTRY,
      currentCountry: val,
    });
  };

  const [stateListLoading, setStateListLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    setStateListLoading(true);
    getBusinessStateList(currentCountry)
      .then((resp) => {
        setStateListLoading(false);
        const array = [];
        Object.keys(resp.data.data).forEach((item, key) => {
          array.push({
            label: resp.data.data[key]["label"],
            value: resp.data.data[key]["label_id"],
          });
        });

        setStateList(array);
      })
      .catch((err) => {
        setStateListLoading(false);
      });
  }, [currentCountry]);

  return (
    <div className="right-panel">
      <h2>Application Information</h2>
      <h5>Please enter the details below</h5>
      <Formik
        initialValues={initialValues || storedData || currentCountry}
        validationSchema={validationSchema}
        enableReinitialize={currentCountry}
        onSubmit={(values, { setSubmitting }) => {
          setStep(2);
          showSelectedState(2);
          setStepNo(2);
          setApplicationInfo(values);
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValid,

          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Business Location</label>
              <ReactFlagsSelect
                selected={currentCountry}
                id="country-select"
                onSelect={(val) => {
                  selectCountry(val);
                  setFieldValue(fieldNames.BUSINESSENTITY, "");
                  setFieldValue(fieldNames.STATE, "");
                  setApplicationInfo(values);
                }}
                countries={["US", "GB", "CA"]}
              />
            </div>
            {currentCountry && (
              <>
                <div className="form-group">
                  <label>Amount Required</label>

                  <div className="input-group mb-3">
                    <CurrencyIcon />
                    <input
                      type="text"
                      placeholder="Enter amount"
                      className={clsx(
                        "form-control ",
                        {
                          "is-invalid":
                            touched[fieldNames.AMOUNT] &&
                            errors[fieldNames.AMOUNT],
                        },
                        {
                          "is-valid":
                            touched[fieldNames.AMOUNT] &&
                            !errors[fieldNames.AMOUNT],
                        }
                      )}
                      name={fieldNames.AMOUNT}
                      onChange={(e) => {
                        if (e.target.value) {
                          if (numberRegex.test(removeComma(e.target.value))) {
                            setFieldValue(fieldNames.AMOUNT, e.target.value);
                          } else {
                            setFieldValue(
                              fieldNames.AMOUNT,
                              values[fieldNames.AMOUNT]
                            );
                          }
                        } else {
                          setFieldValue(fieldNames.AMOUNT, "");
                        }
                      }}
                      onBlur={(e) => {
                        setApplicationInfo(values);
                      }}
                      value={formatNumberInput(values[fieldNames.AMOUNT])}
                    />
                  </div>
                </div>
                <div className="form-group purpose-loan">
                  <label>Purpose of Loan</label>
                  <Select
                    closeMenuOnSelect={true}
                    onChange={(selectedOption) => {
                      setFieldValue(fieldNames.LOANPURPOSE, selectedOption);
                    }}
                    onBlur={(selectedOption) => {
                      setApplicationInfo(values);
                    }}
                    options={loadPurposeList}
                    name={fieldNames.LOANPURPOSE}
                    placeholder="Enter purpose of loan"
                    styles={{
                      control: (styles, state) => {
                        const borderColor =
                          !state.hasValue &&
                          touched[fieldNames.LOANPURPOSE] &&
                          errors[fieldNames.LOANPURPOSE]
                            ? "red"
                            : "#ced4da";

                        return { ...styles, borderColor };
                      },
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={values[fieldNames.LOANPURPOSE]}
                  />
                </div>
                <div className="form-group">
                  <label>Term of Funds Required (Months)</label>
                  <input
                    type="text"
                    placeholder="12"
                    min={0}
                    name={fieldNames.REQUIREDFUND}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (numberRegex.test(removeComma(e.target.value))) {
                          setFieldValue(
                            fieldNames.REQUIREDFUND,
                            e.target.value
                          );
                        } else {
                          setFieldValue(
                            fieldNames.REQUIREDFUND,
                            values[fieldNames.REQUIREDFUND]
                          );
                        }
                      } else {
                        setFieldValue(fieldNames.REQUIREDFUND, "");
                      }
                    }}
                    onBlur={(e) => {
                      setApplicationInfo(values);
                    }}
                    value={formatNumberInput(values[fieldNames.REQUIREDFUND])}
                    className={clsx(
                      "form-control ",
                      {
                        "is-invalid":
                          touched[fieldNames.REQUIREDFUND] &&
                          errors[fieldNames.REQUIREDFUND],
                      },
                      {
                        "is-valid":
                          touched[fieldNames.REQUIREDFUND] &&
                          !errors[fieldNames.REQUIREDFUND],
                      }
                    )}
                  />
                </div>
                <div className="form-group business-entity">
                  <label>Business Entity</label>
                  <Select
                    classNamePrefix={clsx(
                      "",
                      {
                        "is-invalid":
                          touched[fieldNames.BUSINESSENTITY] &&
                          errors[fieldNames.BUSINESSENTITY],
                      },
                      {
                        "is-valid":
                          touched[fieldNames.BUSINESSENTITY] &&
                          !errors[fieldNames.BUSINESSENTITY],
                      }
                    )}
                    closeMenuOnSelect={true}
                    onChange={(selectedOption) => {
                      setFieldValue(fieldNames.BUSINESSENTITY, selectedOption);
                      checkCompanyType(
                        values[fieldNames.BUSINESSENTITY],
                        currentCountry
                      );
                      localStorage.removeItem("companyInfo");
                    }}
                    onBlur={(selectedOption) => {
                      setApplicationInfo(values);
                    }}
                    options={selectBusinessEntityList(currentCountry)}
                    name={fieldNames.BUSINESSENTITY}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder="Select Business Entity"
                    styles={{
                      control: (styles, state) => {
                        const borderColor =
                          !state.hasValue &&
                          touched[fieldNames.BUSINESSENTITY] &&
                          errors[fieldNames.BUSINESSENTITY]
                            ? "red"
                            : "#ced4da";

                        return { ...styles, borderColor };
                      },
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={values[fieldNames.BUSINESSENTITY]}
                  />
                </div>
                {currentCountry !== "GB" && (
                  <div className="form-group business-entity">
                    <label>
                      {currentCountry == "GB"
                        ? "Business Region"
                        : currentCountry == "US"
                        ? "State"
                        : "Province"}
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      onBlur={() => {
                        setApplicationInfo(values);
                      }}
                      onChange={(selectedOption) =>
                        setFieldValue(fieldNames.STATE, selectedOption)
                      }
                      isLoading={stateListLoading}
                      name={fieldNames.STATE}
                      options={stateList}
                      placeholder="Enter business sector"
                      styles={{
                        control: (styles, state) => {
                          const borderColor =
                            !state.hasValue &&
                            touched[fieldNames.STATE] &&
                            errors[fieldNames.STATE]
                              ? "red"
                              : "#ced4da";

                          return { ...styles, borderColor };
                        },
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={values[fieldNames.STATE]}
                    />
                    {/* <RegionDropdown
                      country={currentCountry}
                      countryValueType="short"
                      classes="form-control"
                      style={{
                        border:
                          touched[fieldNames.STATE] && errors[fieldNames.STATE]
                            ? "1px solid red"
                            : "",
                      }}
                      value={values[fieldNames.STATE]}
                      onChange={(val) => setFieldValue(fieldNames.STATE, val)}
                    /> */}
                  </div>
                )}

                {values[fieldNames.BUSINESSENTITY] && (
                  <div className="form-group business-entity">
                    <label>
                      Business Name
                      {checkCompanyType(
                        values[fieldNames.BUSINESSENTITY],
                        currentCountry
                      )}
                    </label>

                    {checkCompanyType(
                      values[fieldNames.BUSINESSENTITY],
                      currentCountry
                    ) ? (
                      <AsyncSelect
                        closeMenuOnSelect={true}
                        value={{
                          label: values[fieldNames.BUSINESSNAME],
                          value: values[fieldNames.BUSINESSNAME],
                        }}
                        name={fieldNames.BUSINESSNAME}
                        loadOptions={loadOptions}
                        onChange={(selectedOption) => {
                          if (selectedOption && selectedOption.value) {
                            setFieldValue(
                              fieldNames.BUSINESSNAME,
                              selectedOption.value
                            );
                            setApplicationInfo(values);
                            setCompanyInfo(selectedOption.data);
                          }
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                        onBlur={(selectedOption) => {
                          setApplicationInfo(values);
                        }}
                        placeholder="Select Business Name"
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={{
                          control: (styles, state) => {
                            const borderColor =
                              !values[fieldNames.BUSINESSNAME] &&
                              touched[fieldNames.BUSINESSNAME] &&
                              errors[fieldNames.BUSINESSNAME]
                                ? "red"
                                : "#ced4da";

                            return { ...styles, borderColor };
                          },
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Enter Business Name"
                        name={fieldNames.BUSINESSNAME}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setApplicationInfo(values);
                        }}
                        value={values[fieldNames.BUSINESSNAME]}
                        className={clsx(
                          "form-control ",
                          {
                            "is-invalid":
                              touched[fieldNames.BUSINESSNAME] &&
                              errors[fieldNames.BUSINESSNAME],
                          },
                          {
                            "is-valid":
                              touched[fieldNames.BUSINESSNAME] &&
                              !errors[fieldNames.BUSINESSNAME],
                          }
                        )}
                      />
                    )}
                  </div>
                )}
              </>
            )}

            <button className="btn btn-primary next-btn">
              Next <i className="fa fa-chevron-right"></i>
            </button>
            <div className="divider"></div>
            <div className="form-group loginnow-btn">
              <p>
                Already have an Account?{" "}
                <NavLink to="/login">Login Now</NavLink>
              </p>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ApplicationInformation;
